import { Tooltip, Image } from "antd";
import "./index.scss";
import IconTooltip from "../../../images/tooltip.svg";
import { render } from "@testing-library/react";

export const getIdColumn = ({ title, tooltip, dataIndex }, idClicked) => {
  return {
    title: () => {
      return (
        <span className="idHeader">
          {title}
          {tooltip && (
            <Tooltip title={tooltip}>
              <Image
                className="idHeader__tooltip"
                src={IconTooltip}
                preview={false}
              />
            </Tooltip>
          )}
        </span>
      );
    },
    dataIndex: dataIndex,
    width: 130,
    render: (id) => {
      return (
        <a className="idValue" onClick={() => idClicked(id)}>
          {id}
        </a>
      );
    },
  };
};

export const getNameColumn = ({ title, tooltip, dataIndex }, nameClicked) => {
  return {
    title: title,
    dataIndex: dataIndex,
    render: (text, record) => {
      return (
        <a className="idValue" onClick={() => nameClicked(record)}>
          {text}
        </a>
      );
    },
  };
};

// Function to truncate text to 25 characters and add ellipsis if needed
const truncateText = (text) => {
  return (text != null && text.length) > 40
    ? text.substring(0, 40) + "..."
    : text;
};
//
export const companyColumns = [
  {
    title: "Company ID",
    dataIndex: "company_id",
    tooltip:
      "The Company ID represents an identification generated for all the records within an entity type (i.e Company) of the application. The Palisade system recognizes matching records from multiple sources or within the same source and assigns the same ID for such records.",
  },
  {
    title: "Source",
    dataIndex: "provider_name",
  },
  {
    title: "Company Name",
    dataIndex: "company_name",
    render: (text) => <a className="nameValue">{text}</a>,
  },
  {
    title: "Company Website",
    dataIndex: "company_website",
    render: (text) => <p>{truncateText(text)}</p>,
  },
  {
    title: "Source URL",
    dataIndex: "source_url",
    render: (text) => <p>{truncateText(text)}</p>,
  },
  {
    title: "Size",
    dataIndex: "size",
    width: 100,
  },
  // {
  //   title: "Country",
  //   dataIndex: "country",
  // },
  {
    title: "Accurate To Date",
    dataIndex: "accurate_to_date",
  },
];
export const peopleColumns = [
  {
    title: "People ID",
    dataIndex: "people_id",
    tooltip:
      "The People ID represents an identification generated for all the records within an entity type (i.e People) of the application. The Palisade system recognizes matching records from multiple sources or within the same source and assigns the same ID for such records.",
  },
  {
    title: "Source",
    dataIndex: "provider_name",
  },
  {
    title: "Name",
    dataIndex: "name",
    render: (text) => <a className="nameValue">{text}</a>,
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Phone Number",
    dataIndex: "phone_number",
  },
  {
    title: "Address",
    dataIndex: "address",
  },
  {
    title: "LinkedIn URL",
    dataIndex: "linkedin_url",
    render: (text) => <p>{truncateText(text)}</p>,
  },
  {
    title: "Accurate To Date",
    dataIndex: "accurate_to_date",
  },
];
export const placesColumns = [
  {
    title: "Place ID",
    dataIndex: "place_id",
    tooltip:
      "The Place ID represents an identification generated for all the records within an entity type (i.e Place) of the application. The Palisade system recognizes matching records from multiple sources or within the same source and assigns the same ID for such records.",
  },
  {
    title: "Source",
    dataIndex: "provider_name",
  },
  {
    title: "Address",
    dataIndex: "address",
    render: (text) => <a className="nameValue">{text}</a>,
  },
  {
    title: "State",
    dataIndex: "state",
  },
  {
    title: "City",
    dataIndex: "city",
  },
  {
    title: "Zip",
    dataIndex: "zip",
  },
  {
    title: "Accurate To Date",
    dataIndex: "accurate_to_date",
  },
];
export const eventsColumns = [
  {
    title: "People ID",
    dataIndex: "people_id",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Source",
    dataIndex: "provider_name",
    render: (text) => <a className="nameValue">{text}</a>,
  },
  // {
  //   title: "Campaign Name",
  //   dataIndex: "campaign_name",
  // },

  {
    title: "Event Type",
    dataIndex: "event_action",
  },
  {
    title: "Event Timestamp",
    dataIndex: "event_timestamp",
  },
];
