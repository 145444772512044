import { Image, Steps, Tooltip, Typography } from "antd";
import classNames from "classnames";
import FileUpload from "components/FileUpload";
import InprogressUploads from "components/InprogressUploads";
import { useState } from "react";
import BackIcon from "../../../../../images/chevron-left.svg";
import FileUploadHoverIcon from "../../../../../images/file-upload-hover.svg";
import FileUploadIdleIcon from "../../../../../images/file-upload-idle.svg";
import AddMetadata from "../AddMetadata";
import AddSource from "../AddSource";
import SourceName from "../AddSource/CreateNewSource/CreateSourceSteps/SourceName";
import SystemFields from "../AddSource/CreateNewSource/CreateSourceSteps/SystemFields";
import ColumnMapping from "../ColumnMapping";
import UnprocessedFiles from "../UnprocessedFiles";
import "./index.scss";

const { Step } = Steps;

/*
- File onboarding flow component used to render File Processing step
- This component also show in progress uploads at the bottom right corner
- This component is responsible for segregating files based on their processing status
*/
function ProcessingSteps() {
  const [currentStep, setCurrentStep] = useState(0);
  const [showUploadTooltip, setShowUploadTooltip] = useState(false);
  const [showCreateSourceSteps, setShowCreateSourceSteps] = useState(false);
  const [stepsDefinitions] = useState([
    {
      title: "",
      content: (
        <UnprocessedFiles
          onClickAddSource={() => {
            setCurrentStep(1);
          }}
        />
      ),
    },
    // {
    //   title: "Source Name",
    //   content: (
    //     <SourceName
    //       onContinue={() => {
    //         setCurrentStep(2);
    //       }}
    //     />
    //     // <AddSource
    //     //   onClickAddMetadata={() => {
    //     //     setShowCreateSourceSteps(false);
    //     //     setCurrentStep(2);
    //     //   }}
    //     //   onClickCreateSource={() => {
    //     //     setShowCreateSourceSteps(true);
    //     //   }}
    //     //   onCancelCreateSource={() => {
    //     //     setShowCreateSourceSteps(false);
    //     //   }}
    //     // />
    //   ),
    // },
    // {
    //   title: "Review AI Schema Mapping",
    //   content: (
    //     <SystemFields
    //       onContinue={() => {
    //         console.log("I am here");
    //         setCurrentStep(3);
    //       }}
    //     />

    //     // <AddMetadata
    //     //   onClickAddMapping={() => {
    //     //     setCurrentStep(3);
    //     //   }}
    //     // />
    //   ),
    // },
    // {
    //   title: "Add Metadata",
    //   content: (
    //     <ColumnMapping
    //       onSave={() => {
    //         setCurrentStep(0);
    //       }}
    //     />
    //   ),
    // },
  ]);
  const isProcessingFile = currentStep > 0;
  const handleBackClick = () => {
    // If on the second step, go back to the first step
    if (currentStep === 1) {
      setCurrentStep(0);
    } else {
      setCurrentStep((prevStep) => Math.max(prevStep - 1, 0)); // Go back one step
    }
  };

  return (
    <div className="processingSteps">
      <div
        className={classNames("processingSteps__header", {
          "processingSteps__header--hidden": showCreateSourceSteps,
        })}
      >
        {/* <div className="processingSteps__header__leftContent">
          {isProcessingFile && (
            <Image
              className="processingSteps__header__leftContent__backBtn"
              src={BackIcon}
              preview={false}
              onClick={handleBackClick}
            />
          )}
        </div> */}
        <div className="processingSteps__header__middleContent">
          <Typography.Text className="processingSteps__header__middleContent__h1Text">
            {"Upload File(s)"}
          </Typography.Text>
          {/* <Steps current={currentStep}>
            {stepsDefinitions.map((item) => {
              return (
                <Step
                  className="processingSteps__steps__step"
                  key={item.title}
                  title={item.title}
                />
              );
            })}
          </Steps> */}
        </div>
        <div className="processingSteps__header__rightContent">
          {!isProcessingFile && (
            <FileUpload>
              <Tooltip title="Upload csv file(s) from your computer.">
                <div
                  className="processingSteps__header__rightContent__uploadBtn"
                  onMouseOver={() => {
                    setShowUploadTooltip(true);
                  }}
                  onMouseOut={() => {
                    setShowUploadTooltip(false);
                  }}
                >
                  <Image
                    src={
                      showUploadTooltip
                        ? FileUploadHoverIcon
                        : FileUploadIdleIcon
                    }
                    preview={false}
                  />
                </div>
              </Tooltip>
            </FileUpload>
          )}
        </div>
      </div>

      <div
        className={classNames("processingSteps__content", {
          "processingSteps__content--fullWidth": showCreateSourceSteps,
        })}
      >
        {stepsDefinitions[currentStep].content}
      </div>
      <InprogressUploads />
    </div>
  );
}
export default ProcessingSteps;
