import { CloseOutlined } from "@ant-design/icons";
import { Drawer, Image, Typography } from "antd";
import AppLeftNav from "components/AppLeftNav";
import { useSelector } from "react-redux";
import {
  IconDatasets,
  IconDataSources,
  IconDestinations,
  IconHomeIdle,
} from "images";
import { useEffect, useState } from "react";
import { useCustomEventListener } from "react-custom-events";
import { useLocation, useNavigate } from "react-router-dom";
import BottomNavigation from "reactjs-bottom-navigation";
import "reactjs-bottom-navigation/dist/index.css";
import { APP_ROUTES } from "routes";
import "./index.scss";

const getSelectedKeys = (location) => {
  const pathName = location.pathname;
  switch (pathName) {
    case `${APP_ROUTES.DASHBOARD.path}/${APP_ROUTES.UPLOADS.path}`:
      return 0;
    case `${APP_ROUTES.DASHBOARD.path}/proposals`:
    case `${APP_ROUTES.DASHBOARD.path}/proposalDetails`:
      return 2;
    case `${APP_ROUTES.DASHBOARD.path}`:
    case `${APP_ROUTES.DASHBOARD.path}/playbooks`:
    case `${APP_ROUTES.DASHBOARD.path}/myPlaybooks`:
    case `${APP_ROUTES.DASHBOARD.path}/playbookCategories`:
      return 1;
    case `${APP_ROUTES.DASHBOARD.path}/datasets/datasets`:
    case `${APP_ROUTES.DASHBOARD.path}/datasets/companies`:
    case `${APP_ROUTES.DASHBOARD.path}/datasets/people`:
    case `${APP_ROUTES.DASHBOARD.path}/datasets/places`:
      return 0;
    case `${APP_ROUTES.DASHBOARD.path}/people`:
      return 3;
    case `${APP_ROUTES.DASHBOARD.path}/findMarketplaces`:
      return 1;
    case `${APP_ROUTES.DASHBOARD.path}/createMarketplace`:
      return 1;
    default:
      return 1;
      break;
  }
};
function BottomNav() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const authReducer = useSelector((state) => state.authReducer);
  const selectedWorkspace = authReducer.userSession.selectedWorkspaceId;
  const playbookText = selectedWorkspace !== 496 ? "Listing" : "Sources";
  const proposalText = selectedWorkspace !== 496 ? "Inquiries" : "Destinations";
  const [selectedKeys, setSelectedKeys] = useState(getSelectedKeys(location));

  useCustomEventListener("left_nav_close_clicked", (data) => {
    setOpen(data);
  });

  // useEffect(() => {
  //   setSelectedKeys(getSelectedKeys(location));
  // }, [location]);

  // items
  const bottomNavItems = [
    {
      title: (
        <Typography.Text className="bottomNav__navText">Home</Typography.Text>
      ),
      icon: <Image preview={false} src={IconHomeIdle}></Image>,
      activeIcon: <Image preview={false} src={IconHomeIdle}></Image>,
      onClick: () => {
        setOpen(true);
      },
    },
    {
      title: (
        <Typography.Text className="bottomNav__navText">
          {playbookText}
        </Typography.Text>
      ),
      icon: <Image preview={false} src={IconDataSources}></Image>,
      activeIcon: <Image preview={false} src={IconDataSources}></Image>,
      onClick: () => {
        navigate(`/dashboard/datasets/sources`);
      },
    },
    {
      title: (
        <Typography.Text className="bottomNav__navText">
          {proposalText}
        </Typography.Text>
      ),
      icon: <Image preview={false} src={IconDestinations}></Image>,
      activeIcon: <Image preview={false} src={IconDestinations}></Image>,
      onClick: () => {
        navigate(`/dashboard/datasets/destinations`);
      },
    },
    {
      title: (
        <Typography.Text className="bottomNav__navText">
          Datasets
        </Typography.Text>
      ),
      icon: <Image preview={false} src={IconDatasets}></Image>,
      activeIcon: <Image preview={false} src={IconDatasets}></Image>,
      onClick: () => {
        navigate(`/dashboard/datasets/datasets`);
      },
    },
  ];

  return (
    <div className="bottomNav">
      <Drawer
        placement="left"
        onClose={() => {
          console.log("drawer close action");
          setOpen(false);
        }}
        width={"300px"}
        open={open}
      >
        <AppLeftNav />
      </Drawer>
      <BottomNavigation
        noActiveBg={true}
        activeTextColor={"#56A5FF"}
        items={bottomNavItems}
        defaultSelected={selectedKeys}
        onItemClick={(item) => console.log(item)}
      ></BottomNavigation>
    </div>
  );
}

export default BottomNav;
