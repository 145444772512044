import "./index.scss";
import {
  DatePicker,
  Slider,
  Form,
  Empty,
  List,
  message,
  Tooltip,
  Divider,
  Image,
} from "antd";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SHOULD_SEARCH_ON_PAGE_LOAD } from "constants";
import { DEFAULT_DATE_FORMAT } from "constants";
import { isEmpty } from "lodash";
import Searchbar from "components/Datasets/Commons/Searchbar";
import SideFilters from "components/Datasets/Commons/SideFilters";
import moment from "moment";
import FileUploadHoverIcon from "../../../images/file-upload-hover.svg";
import FileUploadIdleIcon from "../../../images/file-upload-idle.svg";

import FilterDropdown, {
  FILTER_DROPDOWN_SUFFIX,
} from "components/Datasets/Commons/SideFilters/Fields/FilterDropdown";
import FilterDatePicker from "components/Datasets/Commons/SideFilters/Fields/FilterDatePicker";
import FilterRatingDropdown from "components/Datasets/Commons/SideFilters/Fields/FilterRatingDropdown";
import FilterFieldsContainer from "components/Datasets/Commons/SideFilters/Fields/FilterFieldsContainer";
import {
  buyerPlaybooksUsingFilters,
  getSellerPlaybookFilterValues,
  sellerPlaybooksUsingFilters,
} from "api/Playbooks";
import MySinglePlaybook from "./MySinglePlaybook";
import MyNewSinglePlaybook from "./MyNewSinglePlaybook";
import MyMobileNewSinglePlaybook from "./MyMobileNewSinglePlaybook";
import {
  setExternalInviteInfo,
  setExternalLinkSegmentParams,
  setExternalUserInfo,
  setUserSession,
} from "store/modules/auth/action";
import { getUserWorkspaceByProfileIds, joinWorkspace } from "api/Workspaces";
import { trackEventCall, trackPageCall } from "analytics";
import { ANALYTICS_EVENT_NAMES } from "constants";
import { isMobile } from "utils";

function MyPlaybooks({ onClickCreatePlaybook }) {
  const [playbooksList, setPlaybooksList] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchedFilters, setSearchedFilters] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const externalUserInfo = authReducer.externalUserInfo;
  const externalInviteInfo = authReducer.externalInviteInfo;
  const externalLinkSegmentParams = authReducer.externalLinkSegmentParams;

  useEffect(() => {
    if (externalLinkSegmentParams != null) {
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PLATFORM_EVENT,
        externalLinkSegmentParams
      );
      // dispatch(setExternalLinkSegmentParams(null));
    }
    if (externalUserInfo != null) {
      const pageCallData = {
        marketplaceId: externalUserInfo.marketplaceId,
      };
      const mergedJson = {
        ...pageCallData,
        ...externalLinkSegmentParams,
      };
      trackPageCall("MyPlaybooks List", mergedJson);
    } else {
      const pageCallData = {
        marketplaceId: userSession.selectedWorkspaceId,
      };
      const mergedJson = {
        ...pageCallData,
        ...externalLinkSegmentParams,
      };
      trackPageCall("MyPlaybooks List", mergedJson);
    }
    setLoading(true);
    getWorkspacesByProfiles();
  }, []);

  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";

  const [isBeginingSearch, setIsBeginingSearch] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? false : true
  );
  const [searchedText, setSearchedText] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? "" : null
  );
  const { sellerProfileWorkspaceId } = useSelector(
    (state) => state.authReducer
  ).userSession;
  const shouldFetchResults = useRef(SHOULD_SEARCH_ON_PAGE_LOAD ? true : false);
  const [showCreatePlaybookTooltip, setShowCreatePlaybookTooltip] =
    useState(false);

  const onApplyFilters = () => {
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    setSearchedFilters(form.getFieldsValue());
  };

  useEffect(() => {
    getFilters();
  }, [sellerProfileWorkspaceId]);

  useEffect(() => {
    if (shouldFetchResults.current) {
      getSearchResults();
    }
  }, [searchedText, searchedFilters, sellerProfileWorkspaceId]);
  const getFilters = () => {
    const payload = {
      // profile_workspace_id: 506,
      profile_workspace_id: sellerProfileWorkspaceId,
      profile_type: "seller",
    };

    console.log("filter values payload:", payload);
    getSellerPlaybookFilterValues(payload)
      .then((res) => {
        const filters = res.data.data;
        console.log("filters value:", filters);

        setFilterOptions({
          ...filters,
        });
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  const getSearchResults = () => {
    const filters = {};
    Object.keys(searchedFilters).forEach((filter) => {
      let filterValue = searchedFilters[filter];
      if (moment.isMoment(filterValue)) {
        filterValue = [moment(filterValue).format(DEFAULT_DATE_FORMAT)];
      } else if (["string", "number"].includes(typeof filterValue)) {
        filterValue = [filterValue];
      } else {
        filterValue = filterValue || [];
      }
      filters[filter] = filterValue.join(",");
    });

    let updatedBudgetLimits = filters["budget"]?.split(",");
    let min = 0;
    let max = 999999999;

    if (updatedBudgetLimits?.length > 1) {
      min = parseInt(updatedBudgetLimits[0]);
      max = parseInt(updatedBudgetLimits[1]);
    }

    const payload = {
      profile_workspace_id: sellerProfileWorkspaceId,
      profile_type: "seller",
      query: searchedText,
      min_budget: min,
      max_budget: max,
      tags: filters["tags"],
      created_before: filters["createdBefore"],
      reviews: filters["rating"],
    };

    console.log("Payload is:", payload);
    setLoading(true);
    sellerPlaybooksUsingFilters(payload)
      .then((res) => {
        console.log("result is:", res.data.data);
        setSearchResults(res.data.data);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });

    // this is just to populate the UI, delete this later
    // const dummyPayload = {
    //   profile_workspace_id: 1299,
    //   workspace_id: 496,
    //   playbook_category_id: 5,
    //   profile_type: "buyer",
    //   query: searchedText,
    //   min_budget: 0,
    //   max_budget: 999999,
    //   tags: "",
    //   owners: "",
    //   created_before: "",
    //   rating: "",
    //   action: "",
    // };

    // buyerPlaybooksUsingFilters(dummyPayload)
    //   .then((res) => {
    //     setSearchResults(res.data.data);
    //   })
    //   .catch((err) => {
    //     message.error(err.response.data.message);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });

    // ===================================================
  };

  const tipFormatter = (value) => `$${value}`;

  const onResetFields = (fields) => {
    form.resetFields(fields);
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    const fieldValues = form.getFieldsValue();
    fields.forEach((field) => {
      fieldValues[field] = "";
    });
    setSearchedFilters(fieldValues);
  };
  const filterFields = [
    // <FilterFieldsContainer
    //   label="Budget"
    //   onClear={() => {
    //     onResetFields(["budget"]);
    //   }}
    // >
    //   <Form.Item name="budget">
    //     <Slider
    //       range={{ draggableTrack: true }}
    //       min={0}
    //       max={parseInt(filterOptions.maxBudget)}
    //       tipFormatter={tipFormatter}
    //       defaultValue={[0, parseInt(filterOptions.maxBudget)]}
    //       onChange={onApplyFilters}
    //     />
    //   </Form.Item>
    // </FilterFieldsContainer>,
    <FilterFieldsContainer
      label="Tags"
      onClear={() => {
        onResetFields(["tags"]);
      }}
    >
      <FilterDropdown
        name="tags"
        placeholder="Enter tags here…"
        options={filterOptions.tags}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="Created before"
      onClear={() => {
        onResetFields(["createdBefore"]);
      }}
    >
      <FilterDatePicker name="createdBefore" onChange={onApplyFilters} />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="Rating"
      onClear={() => {
        onResetFields(["rating"]);
      }}
    >
      <FilterRatingDropdown
        name="rating"
        placeholder="Enter rating here…"
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
  ];

  useEffect(() => {
    const list = [];
    for (var i = 0; i < 10; i++) {
      list.push({
        key: `${i}`,
        playbook_title: `Playbook ${parseInt(Math.random() * 100)}`,
      });
    }
    setPlaybooksList(list);
  }, []);

  const deletePlaybook = (index) => {
    const updatedPlaybooksList = [...playbooksList];
    updatedPlaybooksList.splice(index, 1);
    setPlaybooksList(updatedPlaybooksList);
  };

  const getWorkspacesByProfiles = () => {
    if (externalInviteInfo != null || externalUserInfo != null) {
      const payload = {
        profileids:
          userSession.buyerProfileId + "," + userSession.sellerProfileId,
      };
      getUserWorkspaceByProfileIds(payload)
        .then((res) => {
          let externalWorkspaceId = 1;
          if (externalInviteInfo != null) {
            externalWorkspaceId = externalInviteInfo.marketplaceId;
          } else if (externalUserInfo != null) {
            externalWorkspaceId = externalUserInfo.marketplaceId;
          }

          let workspaceIdsList = [];
          res?.data?.data?.forEach((el) => {
            if (el.workspace_id != null) {
              workspaceIdsList.push(el.workspace_id);
            }
          });
          if (workspaceIdsList.includes(parseInt(externalWorkspaceId))) {
            userSession.selectedWorkspaceId = externalWorkspaceId;
            res?.data?.data.forEach((el) => {
              if (
                el.workspace_id != null &&
                el.workspace_id === parseInt(externalWorkspaceId)
              ) {
                userSession.workspaceCategoryId = el.workspace_category_id;
              }
            });
            dispatch(setUserSession(userSession));
            if (externalInviteInfo != null) {
              dispatch(setExternalInviteInfo(null));
            }
            if (externalUserInfo != null) {
              const playbookId = externalUserInfo.playbookId;
              userSession?.joinedWorkspacesList?.forEach((ws) => {
                if (
                  parseInt(ws?.id) === parseInt(externalUserInfo.marketplaceId)
                ) {
                  ws?.workspace_profiles?.forEach((account) => {
                    if (account.account_role === "buyer") {
                      userSession.buyerProfileWorkspaceId = account.id;
                    }
                    if (account.account_role === "seller") {
                      userSession.sellerProfileWorkspaceId = account.id;
                    }
                  });
                }
              });
              dispatch(setUserSession(userSession));
              // return <Navigate to={`/dashboard/playbookDetails/${playbookId}`} />;
              if (
                externalUserInfo?.playbookId !== null &&
                externalUserInfo?.playbookId !== undefined
              ) {
                navigate(`/dashboard/playbookDetails/${playbookId}`);
              } else {
                dispatch(setExternalUserInfo(null));
              }
            }
            setLoading(false);
          } else {
            callJoinWorkspaceAPI();
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("getWorkspacesByProfiles Error");
          message.error(err?.response?.data?.message);
        });
    }
    // else if (externalUserInfo != null) {
    //   // console.log("Website routing");
    //   callJoinWorkspaceAPI();
    //   // const playbookId = externalUserInfo.playbookId;
    //   // dispatch(setExternalUserInfo(null));
    //   // navigate(`/dashboard/playbookDetails/${playbookId}`);
    // }
  };

  const callJoinWorkspaceAPI = () => {
    let externalWorkspaceId = "1";
    if (externalInviteInfo != null) {
      externalWorkspaceId = externalInviteInfo.marketplaceId;
    } else if (externalUserInfo != null) {
      externalWorkspaceId = externalUserInfo.marketplaceId;
    }
    if (externalWorkspaceId === 1) {
      return;
    }

    const payload = {
      workspace_id: externalWorkspaceId, // workspace to join
      buyer_profile_id: userSession.buyerProfileId, // current buyer profile id
      seller_profile_id: userSession.sellerProfileId, // current seller profile id
    };
    joinWorkspace(payload)
      .then((res) => {
        let updatedUserSession = { ...userSession };
        updatedUserSession.selectedWorkspaceId = parseInt(payload.workspace_id);

        updatedUserSession.buyerProfileWorkspaceId =
          res?.data?.data?.buyer_profile_workspace_id;
        updatedUserSession.sellerProfileWorkspaceId =
          res?.data?.data?.seller_profile_workspace_id;
        updatedUserSession.isDefaultWorkspace = false;

        updatedUserSession.workspaceCategoryId =
          res?.data?.data?.workspace_info?.workspace_category_id;

        dispatch(setUserSession(updatedUserSession));

        // const externalInviteLocal = externalInviteInfo;
        if (externalInviteInfo != null) {
          dispatch(setExternalInviteInfo(null));
        }

        //TODO: no need of this as we already setting our updated ids from server response
        if (
          externalUserInfo != null &&
          externalUserInfo.playbookId != null &&
          externalUserInfo.playbookId != undefined
        ) {
          const playbookId = externalUserInfo.playbookId;
          navigate(`/dashboard/playbookDetails/${playbookId}`);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const [isMobileView, setIsMobileView] = useState(isMobile());
  useEffect(() => {
    function handleResize() {
      setIsMobileView(isMobile());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="playbooks">
      <div className="playbooks__searchContainer">
        <Searchbar
          placeholder="Search playbooks"
          filtersVisible={showFilters}
          onFiltersVisibilityChange={setShowFilters}
          onSubmitSearchText={(searchedText) => {
            shouldFetchResults.current = true;
            setIsBeginingSearch(false);
            setSearchedText(searchedText);
          }}
          filtersApplied={
            !isEmpty(
              Object.values(searchedFilters).filter((value) => {
                return !isEmpty(value);
              })
            )
          }
        />

        <div className="playbooks__searchContainer__addIcon">
          <Link className="menu-list__link" to={"/dashboard/createb2bPlaybook"}>
            <Tooltip title="Create Playbook">
              <div
                className="playbooks__searchContainer__addIcon"
                onMouseOver={() => {
                  setShowCreatePlaybookTooltip(true);
                }}
                onMouseOut={() => {
                  setShowCreatePlaybookTooltip(false);
                }}
              >
                <Image
                  src={
                    showCreatePlaybookTooltip
                      ? FileUploadHoverIcon
                      : FileUploadIdleIcon
                  }
                  preview={false}
                />
              </div>
            </Tooltip>
          </Link>
        </div>
      </div>

      <div className="playbooks__filterAndResults">
        <>
          {showFilters && (
            <div className="playbooks__filterAndResults__filters">
              <Form form={form}>
                <SideFilters
                  filterFields={filterFields}
                  onClose={() => {
                    setShowFilters(false);
                  }}
                  onReset={() => {
                    form.resetFields();
                    shouldFetchResults.current = true;
                    setIsBeginingSearch(false);
                    setSearchedFilters({});
                  }}
                />
              </Form>
            </div>
          )}
          {!isBeginingSearch && (
            <div className="playbooks__filterAndResults__proposalsList">
              <div className="playbooks__filterAndResults__proposalsList__header">
                <p className="playbooks__filterAndResults__proposalsList__header__text">
                  PLAYBOOKS (
                  {searchResults?.length > 0 ? searchResults.length : 0})
                </p>
                <Divider className="playbooks__filterAndResults__proposalsList__header__divider" />
              </div>

              <List
                loading={loading}
                dataSource={searchResults}
                className="playbooks__filterAndResults__proposalsList__list"
                locale={{
                  emptyText: (
                    <Empty
                      description={
                        loading
                          ? "Loading"
                          : "No Data Found for Applied Criteria"
                      }
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  ),
                }}
                renderItem={(item, index) => {
                  return (
                    <div className="playbooks__datas">
                      {isMobileView === true ? (
                        <MyMobileNewSinglePlaybook
                          actionButtonText={"Actions"}
                          itemData={item}
                          setListLoader={(isLoading) => {
                            setLoading(isLoading);
                          }}
                          removeItem={() => {
                            setSearchResults(searchResults.splice(index + 1));
                          }}
                        />
                      ) : (
                        <MyNewSinglePlaybook
                          actionButtonText={"Actions"}
                          itemData={item}
                          setListLoader={(isLoading) => {
                            setLoading(isLoading);
                          }}
                          removeItem={() => {
                            setSearchResults(searchResults.splice(index + 1));
                          }}
                        />
                      )}
                    </div>
                  );
                }}
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default MyPlaybooks;
