import { Empty, Table } from "antd";
import "./index.scss";
import RecordDetailsPopup from "./RecordDetailsPopup";
import { useState } from "react";
import { getNameColumn } from "components/DataTable/Columns";

function DataTable({
  entityTypeId,
  loading,
  columns = [],
  dataSource = [],
  detailsKey = "",
}) {
  const [detailsRecord, setDetailsRecord] = useState(null);
  let nameClicked = (record) => {
    setDetailsRecord(record);
  };
  if (detailsKey) {
    columns[2] = getNameColumn(columns[2], nameClicked);
  }

  return (
    <>
      <Table
        className="dataTable"
        rowClassName={(_, index) =>
          index % 2 === 0 ? "dataTable__evenRow" : "dataTable__oddRow"
        }
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        sticky
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              description={
                loading ? "Loading" : "No Data Found for Applied Criteria"
              }
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       setDetailsRecord(record);
        //     },
        //   };
        // }}
      ></Table>
      {detailsRecord && (
        <RecordDetailsPopup
          entityTypeId={entityTypeId}
          title={detailsRecord[detailsKey]}
          record={detailsRecord}
          onClose={() => {
            setDetailsRecord(null);
          }}
        />
      )}
    </>
  );
}

export default DataTable;
