import { Button, Form, Image, Input, message, Space, Typography } from "antd";
import { UserOnBoardingFooter } from "components/UserOnBoardingFooter";
import { Close } from "images";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { forgotPassword } from "../../api/Auth";
import AppLogo from "../../components/AppLogo";
import "./index.scss";

function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleForgotPassword = (values) => {
    setLoading(true);
    const { username } = values;
    sendForgotPasswordCode(username);
  };

  const sendForgotPasswordCode = (username) => {
    setShowErrorMessage(false);
    setUserEmail(username);
    const email = userEmail === "" ? username : userEmail;
    forgotPassword(email)
      .then((res) => {
        message.success("Code sent to your email for password reset");
        navigate(APP_ROUTES.RESET_PASSWORD.path, {
          state: {
            email: email,
          },
        });
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setShowErrorMessage(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resendPasswordCode = () => {
    sendForgotPasswordCode(userEmail);
  };

  const onErrorCloseClick = () => {
    setShowErrorMessage(false);
  };

  return (
    <>
      <div className="forgotPassword__container">
        <div className="forgotPassword__header">
          <div>
            <Typography.Text className="forgotPassword__textDonthaveAccount">
              Don't have an account?
            </Typography.Text>
            <Link to={APP_ROUTES.SIGNUP.path}>
              <Button type="primary" className="forgotPassword__button">
                Sign Up
              </Button>
            </Link>
          </div>
        </div>
        <div className="forgotPassword">
          <Space
            direction="vertical"
            size={0}
            className="forgotPassword__signinFields"
          >
            <div className="forgotPassword__headerLogo">
              <AppLogo className="forgotPassword__logo" />
            </div>

            <Typography.Text className="forgotPassword__headerTitle">
              Forgot Password?
            </Typography.Text>
            {showErrorMessage && (
              <div className="signinPage__errorMessageContainer">
                <Image
                  className="signinPage__errorContainerCrossImage"
                  src={Close}
                  onClick={onErrorCloseClick}
                  preview={false}
                ></Image>
                <Typography.Text
                  className="signinPage__textErrorMessage"
                  type="danger"
                >
                  {errorMessage}
                </Typography.Text>
              </div>
            )}
            <Form
              layout="vertical"
              autoComplete="off"
              onFinish={handleForgotPassword}
            >
              <Form.Item
                name="username"
                label="Enter Email"
                required
                rules={[
                  {
                    required: true,
                    message: "Please enter an email address",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
                hasFeedback
              >
                <Input
                  autoComplete={false}
                  placeholder="abc@xyz.com"
                  className="forgotPassword__inputField"
                />
              </Form.Item>
              <Button
                loading={loading}
                type="primary"
                className="forgotPassword__continueButton"
                htmlType="submit"
              >
                Send password reset code
              </Button>

              {/* <div className="forgotPassword__sendAgainDiv">
                <Typography.Text className="forgotPassword__textCodeNotReveived">
                  {" "}
                  Didn't receive code?{" "}
                </Typography.Text>
                <Typography.Text
                  onClick={resendPasswordCode}
                  underline
                  className="forgotPassword__textSendAgain"
                >
                  Send Again
                </Typography.Text>
              </div> */}
            </Form>
          </Space>
        </div>

        <UserOnBoardingFooter />
      </div>
    </>
  );
}
export default ForgotPassword;
