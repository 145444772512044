import { Image, Menu, Spin, Tooltip, message } from "antd";
import { IconListExpanded, IconDropDownExpand } from "images";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { APP_ROUTES } from "routes";
import "./index.scss";
import { useSelector } from "react-redux";
import PeopleNav from "./PeopleNav";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import { isMobile } from "utils";
import { getUserWorkspaceByProfileIds } from "api/Workspaces";

const MenuItemIcon = ({ src }) => {
  return <Image className="menu-item-icon" preview={false} src={src} />;
};

export const MenuList = () => {
  const { SubMenu } = Menu;
  const [selectedKeys, setSelectedKeys] = useState(["31"]);
  const location = useLocation();
  const authReducer = useSelector((state) => state.authReducer);
  const leftNavOptions = authReducer?.leftNavOptions;
  const userSession = authReducer.userSession;
  const isUserSeller = userSession?.accountRole !== "buyer";
  const [isMobileView, setIsMobileView] = useState(isMobile());

  // dynamic left nav
  const [apiLeftNavOptions, setApiLeftNavOptions] = useState([]);
  const leftNavOptionsData =
    leftNavOptions !== "undefined" && leftNavOptions !== null
      ? leftNavOptions[0]
      : apiLeftNavOptions;

  const [loading, setLoading] = useState(true);
  const [submenuOpenStates, setSubmenuOpenStates] = useState([true, true]);

  const [recallAPI, setRecallAPI] = useState(true);

  useCustomEventListener("selected-workspace-id-changed", () => {
    setRecallAPI(!recallAPI);
  });

  useEffect(() => {
    callGetUserWorkspaceByProfileIdsAPI();
  }, [recallAPI && userSession?.selectedWorkspaceId > 1]);

  const handleLeftNavClick = (submenu) => {
    setSelectedKeys([submenu.key]);

    if (
      isMobileView &&
      (submenu.text === "Uploads" || submenu.text === "Create")
    ) {
      // Show a message for mobile view when clicking on the link
      message.info("Seller mode required for this functionality");
    } else if (submenu.text == "Create" && !isUserSeller) {
      message.info("Seller mode required for this functionality");
    }
  };

  const callGetUserWorkspaceByProfileIdsAPI = async () => {
    const payload = {
      profileids:
        userSession.buyerProfileId + "," + userSession.sellerProfileId,
    };

    await getUserWorkspaceByProfileIds(payload)
      .then((res) => {
        let marketplacesList = res?.data?.data;
        let myWorkspaceId = userSession.selectedWorkspaceId;
        if (myWorkspaceId === 0) {
          myWorkspaceId = 496;
        }

        const foundWorkspace = marketplacesList.find(
          (workspace) => workspace.workspace_id === myWorkspaceId
        );

        if (foundWorkspace) {
          setApiLeftNavOptions(foundWorkspace);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      })
      .finally(() => {});
  };

  useEffect(() => {
    const getSelectedKeys = () => {
      let pathName = "";
      pathName = location.pathname;
      switch (true) {
        case pathName.startsWith(
          `${APP_ROUTES.DASHBOARD.path}/datasets/sources`
        ):
        case pathName.startsWith(
          `${APP_ROUTES.DASHBOARD.path}/datasets/connector/`
        ):
        case pathName.startsWith(
          `${APP_ROUTES.DASHBOARD.path}/datasets/settings/`
        ):
        case pathName.startsWith(`${APP_ROUTES.DASHBOARD.path}/sources/setup`):
          return ["31"];
        case pathName.startsWith(
          `${APP_ROUTES.DASHBOARD.path}/datasets/datasets`
        ):
        case pathName.startsWith(
          `${APP_ROUTES.DASHBOARD.path}/datasets/companies`
        ):
        case pathName.startsWith(
          `${APP_ROUTES.DASHBOARD.path}/datasets/people`
        ):
        case pathName.startsWith(
          `${APP_ROUTES.DASHBOARD.path}/datasets/places`
        ):
        case pathName.startsWith(
          `${APP_ROUTES.DASHBOARD.path}/datasets/events`
        ):
          return ["33"];
        case pathName.startsWith(
          `${APP_ROUTES.DASHBOARD.path}/datasets/destinations`
        ):
        case pathName.startsWith(
          `${APP_ROUTES.DASHBOARD.path}/datasets/destination`
        ):
        case pathName.startsWith(
          `${APP_ROUTES.DASHBOARD.path}/destination/setup`
        ):
          return ["32"];
        default:
          return [];
      }
    };
    setSelectedKeys(getSelectedKeys());
  }, [location]);

  const toggleSubmenuOpenState = (index) => {
    const newOpenStates = [...submenuOpenStates];
    newOpenStates[index] = !newOpenStates[index];
    setSubmenuOpenStates(newOpenStates);
  };

  const initialDefaultOpenKeys = apiLeftNavOptions?.left_nav_options
    ? apiLeftNavOptions?.left_nav_options.map((option) => option.key)
    : [];

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          background: "white",
        }}
      >
        <Spin size="default" />
      </div>
    );
  } else {
    return (
      <div className="menu-list">
        <Menu
          style={{
            background: "white",
          }}
          mode="inline"
          selectedKeys={selectedKeys}
          defaultOpenKeys={initialDefaultOpenKeys}
          onClick={() => {
            if (isMobileView) {
              emitCustomEvent("left_nav_close_clicked", false);
            }
          }}
        >
          {leftNavOptionsData?.left_nav_options?.map((singleOption, index) => {
            if (!singleOption.isVisible) {
              return null;
            }

            const renderSubmenus = (submenus) =>
              submenus.map((submenu) => {
                if (!submenu.isVisible) {
                  return null;
                }

                if (submenu.nested_submenus) {
                  return (
                    <SubMenu
                      key={submenu.key}
                      icon={
                        <MenuItemIcon
                          src={
                            submenuOpenStates[index]
                              ? IconListExpanded
                              : IconDropDownExpand
                          }
                        />
                      }
                      title={submenu.name}
                    >
                      {renderSubmenus(submenu.nested_submenus)}
                    </SubMenu>
                  );
                }

                return (
                  <Menu.Item
                    key={submenu.key}
                    icon={
                      <MenuItemIcon
                        src={`https://palisade-user-profile-data.s3.us-west-2.amazonaws.com/left-nav-icons/${submenu.icon}`}
                      />
                    }
                    onClick={() => handleLeftNavClick(submenu)}
                  >
                    <Link
                      className={`${
                        selectedKeys.includes(submenu.key)
                          ? "selected-link"
                          : "menu-list__link"
                      }`}
                      to={
                        isMobileView &&
                        (submenu.text === "Uploads" ||
                          submenu.text === "Create")
                          ? "#"
                          : submenu.text === "Create" && !isUserSeller
                          ? "#"
                          : submenu.text === "Datasets" && isUserSeller
                          ? "datasets/datasets"
                          : submenu.text === "Playbooks" && isUserSeller
                          ? "myplaybooks"
                          : submenu.redirect
                      }
                      disabled={!isUserSeller && submenu.text === "Create"}
                      title={
                        !isUserSeller && submenu.text === "Create"
                          ? "Seller mode required"
                          : ""
                      }
                      onClick={() => handleLeftNavClick(submenu)}
                    >
                      {submenu.name}
                    </Link>
                  </Menu.Item>
                );
              });

            return (
              <SubMenu
                key={singleOption.key}
                icon={
                  <MenuItemIcon
                    src={`https://palisade-user-profile-data.s3.us-west-2.amazonaws.com/left-nav-icons/${singleOption.icon}`}
                  />
                }
                onTitleClick={() => toggleSubmenuOpenState(index)}
                title={
                  <div className="submenu-title">
                    <span>{singleOption.name}</span>
                    <span className="chevron-icon">
                      <img
                        src={
                          submenuOpenStates[index]
                            ? IconListExpanded
                            : IconDropDownExpand
                        }
                        alt=""
                      />
                    </span>
                  </div>
                }
              >
                {renderSubmenus(singleOption.submenus)}
              </SubMenu>
            );
          })}
        </Menu>
      </div>
    );
  }
};
