import { Image, List, message, Modal } from "antd";
import { getRecordDetails } from "api/search";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import FilterCrossIcon from "../../../images/filter-cross.svg";
import "./index.scss";

function RecordDetailsPopup({
  entityTypeId,
  title = "",
  record = {},
  onClose,
}) {
  const [datasource, setDatasource] = useState({});
  const [loading, setLoading] = useState(false);
  // console.log("record", record);
  useEffect(() => {
    const payload = {
      entityTypeId,
      userId: record.user_id,
      accountId: record.account_id,
      tempId: record.temp_id,
    };
    setLoading(true);
    getRecordDetails(payload)
      .then((res) => {
        setDatasource(res?.data?.data[0] || {});
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Modal
      title={null}
      footer={null}
      closable={false}
      className="recordDetailsPopup"
      visible
      destroyOnClose
    >
      <div className="recordDetailsPopup__container">
        <div className="recordDetailsPopup__container__header">
          <span className="recordDetailsPopup__container__header__title">
            {title}
          </span>
          <Image
            className="recordDetailsPopup__container__header__close"
            src={FilterCrossIcon}
            preview={false}
            onClick={onClose}
          />
        </div>
        <List
          className="recordDetailsPopup__container__list"
          dataSource={Object.keys(datasource)}
          loading={loading}
          renderItem={(item, index) => {
            return (
              <div
                key={index}
                className={classNames(
                  "recordDetailsPopup__container__list__row",
                  {
                    "recordDetailsPopup__container__list__row--dark":
                      index % 2 === 1,
                  }
                )}
              >
                <span className="recordDetailsPopup__container__list__row__key">
                  {item}
                </span>
                <span className="recordDetailsPopup__container__list__row__value">
                  {datasource[item]}
                </span>
              </div>
            );
          }}
        />
      </div>
    </Modal>
  );
}
export default RecordDetailsPopup;
