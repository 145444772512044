import { trackEventCall, trackIdentifyCall, trackPageCall } from "analytics";
import {
  Button,
  Divider,
  Form,
  Image,
  Input,
  Space,
  Spin,
  Typography,
} from "antd";
import { getAllMarketplaces } from "api/Workspaces";
import { UserOnBoardingFooter } from "components/UserOnBoardingFooter";
import { GOOGLE_CLIENT_ID } from "environments";
import { Close, IconGoogle } from "images";
import { useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import {
  setDomainAndUserInfo,
  setIsSocial,
  setUserSession,
} from "store/modules/auth/action";
import { changeFavivon, parseUserResponse } from "utils";
import { signInWithUsernamePassword } from "../../api/Auth";
import { checkIfEmailExists, signin } from "../../api/userManagement";
import AppLogo from "../../components/AppLogo";
import { ANALYTICS_EVENT_NAMES } from "../../constants";
import { setUserToken, setAccessToken } from "../../store/modules/auth/action";
import "./index.scss";

function Signin() {
  const [loading, setLoading] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    trackPageCall("Sign In");
  }, []);

  useEffect(() => {
    getMarketPlacesList();
    changeFavivon();
  }, []);

  const googleOnSuccess = (res) => {
    setSocialLoading(true);
    setShowErrorMessage(false);
    const state = {
      email: res.profileObj.email,
      firstName: res.profileObj.givenName ? res.profileObj.givenName : "",
      lastName: res.profileObj.familyName ? res.profileObj.familyName : "",
      password: "",
      isSocial: true,
      google_token: res.profileObj.googleId,
      imageUri: res.profileObj.imageUrl,
      tokenId: res.tokenId,
      isSocialSignin: true,
    };

    const payloadForCheckEmailStatus = {
      email: res.profileObj.email,
    };
    checkIfEmailExists(payloadForCheckEmailStatus)
      .then((response) => {
        if (
          response?.data?.data?.no_user_found === true &&
          response?.data?.message === "Email does not exist"
        ) {
          navigate(APP_ROUTES.SIGNUP_ADD_PHONE.path, {
            state: {
              email: res.profileObj.email,
              firstName: res.profileObj.givenName
                ? res.profileObj.givenName
                : "",
              lastName: res.profileObj.familyName
                ? res.profileObj.familyName
                : "",
              password: "",
              isSocial: true,
              imageUri: res.profileObj.imageUrl,
              google_token: res.profileObj.googleId,
              tokenId: res.tokenId,
              isSocialSignin: true,
            },
          });
        } else if (response?.data?.error === false) {
          const payloadForSigninUrl = {
            idtoken: state.tokenId,
            isSocial: state.isSocial,
            email: payloadForCheckEmailStatus.email,
          };
          signInSocialUser(payloadForSigninUrl);
        }
      })
      .catch((err) => {
        // navigate(APP_ROUTES.SIGNUP_ADD_PHONE.path, {
        //   state: {
        //     email: res.profileObj.email,
        //     firstName: res.profileObj.givenName ? res.profileObj.givenName : "",
        //     lastName: res.profileObj.familyName
        //       ? res.profileObj.familyName
        //       : "",
        //     password: "",
        //     isSocial: true,
        //     imageUri: res.profileObj.imageUrl,
        //     google_token: res.profileObj.googleId,
        //     tokenId: res.tokenId,
        //     isSocialSignin: true,
        //   },
        // });
        setSocialLoading(false);
      });
  };

  const googleOnFailure = (res) => {
    console.log("Google login failed", res);
  };

  const handleSignin = (values) => {
    setShowErrorMessage(false);
    const { username, password } = values;
    setLoading(true);
    const payloadForSigninUrl = {
      email: username,
      password: password,
      isSocial: false,
    };
    signInUser(payloadForSigninUrl);
  };

  const signInSocialUser = (payloadForSigninUrl) => {
    const socialState = {
      isSocialSignin: true,
    };
    dispatch(setIsSocial(socialState));
    const state = {
      idToken: payloadForSigninUrl.idtoken,
    };
    dispatch(setUserToken(state));
    signin(payloadForSigninUrl)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setAccessToken(response?.data?.data?.response?.token));
          dispatch(setUserToken(response?.data?.data?.response?.token));
          const eventData = {
            via: "Google",
            email: payloadForSigninUrl.email,
          };
          trackEventCall(ANALYTICS_EVENT_NAMES.SIGNIN, eventData);
          const state = parseUserResponse(response, true);

          const segmentIdentityData = {
            email: state?.email,
            firstname: state?.firstName,
            lastname: state?.lastName,
            phonenumber: state?.phoneNumber,
          };

          trackIdentifyCall(state?.userId, segmentIdentityData);
          // window.OpenReplay.setUserID(state?.email);
          dispatch(setUserSession(state));
        } else {
          setErrorMessage(response.data.message);
          setShowErrorMessage(true);
          setLoading(false);
          setSocialLoading(false);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          // message.error(error.message);
          setErrorMessage(error.message);
        }
        setShowErrorMessage(true);
        setLoading(false);
        setSocialLoading(false);
      });
  };

  const signInUser = (payloadForSigninUrl) => {
    signInWithUsernamePassword(
      payloadForSigninUrl.email,
      payloadForSigninUrl.password
    )
      .then((res) => {
        const state = {
          idToken: res.signInUserSession.idToken.jwtToken,
        };
        const socialState = {
          isSocialSignin: false,
        };
        dispatch(setIsSocial(socialState));
        dispatch(setUserToken(state));
        signin(payloadForSigninUrl)
          .then((response) => {
            if (response.status === 200) {
              dispatch(setAccessToken(response?.data?.data?.response?.token));
              dispatch(setUserToken(response?.data?.data?.response?.token));
              const eventData = {
                email: payloadForSigninUrl.email,
              };
              trackEventCall(ANALYTICS_EVENT_NAMES.SIGNIN, eventData);
              const state = parseUserResponse(response, false);

              const segmentIdentityData = {
                email: state?.email,
                firstname: state?.firstName,
                lastname: state?.lastName,
                phonenumber: state?.phoneNumber,
              };

              trackIdentifyCall(state?.userId, segmentIdentityData);
              // window.OpenReplay.setUserID(state?.email);
              dispatch(setUserSession(state));
            } else {
              setLoading(false);
              setErrorMessage(response.data.message);
              setShowErrorMessage(true);
            }
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              setErrorMessage(error.response.data.message);
            } else {
              // message.error(error.message);
              setErrorMessage(error.message);
            }
            setShowErrorMessage(true);
            setLoading(false);
          });
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setShowErrorMessage(true);
        setLoading(false);
        // message.error(err.message);
      });
  };
  const onErrorCloseClick = () => {
    setShowErrorMessage(false);
  };

  const getMarketPlacesList = () => {
    getAllMarketplaces()
      .then((res) => {
        // console.log("marketplacesList", res);
        let marketplacesList = res?.data?.data?.response;
        if (marketplacesList !== null && marketplacesList != undefined) {
          let currentDomain = window.location.host;
          // console.log("currentDomain", currentDomain);
          let currentMarketPlaceId = 1;
          marketplacesList.every((mp) => {
            // console.log("mp name", mp.name);
            if (
              mp.name === "PrivHomes" &&
              currentDomain === "app.privhomes.com"
              // currentDomain === "localhost:3000"
            ) {
              document.title = mp.name;
              currentMarketPlaceId = mp.id;
              return false;
            } else if (
              mp.name === "Fidelity American Homes" &&
              currentDomain === "app.fidelityamericanhomes.com"
            ) {
              document.title = mp.name;
              currentMarketPlaceId = mp.id;
              return false;
            } else if (
              mp.name === "Palisade Knowledge" &&
              currentDomain === "app.palisade.ai"
            ) {
              document.title = mp.name;
              currentMarketPlaceId = mp.id;
              return false;
            } else if (
              mp.name === "Progressive American Properties" &&
              currentDomain === "app.progressiveamericanproperties.com"
            ) {
              document.title = mp.name;
              currentMarketPlaceId = mp.id;
              return false;
            } else if (
              mp.name === "Prudential Homes" &&
              currentDomain === "app.prudentialhomesofamerica.com"
            ) {
              document.title = mp.name;
              currentMarketPlaceId = mp.id;
              return false;
            } else if (
              mp.name === "My AI Home Value Estimator" &&
              currentDomain === "app.myaihomevalueestimator.com"
              // currentDomain === "localhost:3000"
            ) {
              document.title = mp.name;
              currentMarketPlaceId = mp.id;
              return false;
            } else if (
              mp.name === "Home Offer Calculator" &&
              currentDomain === "app.homeoffercalculator.com"
            ) {
              document.title = mp.name;
              currentMarketPlaceId = mp.id;
              return false;
            } else if (
              mp.name === "American Best Agents" &&
              currentDomain === "app.americanbestagents.com"
            ) {
              document.title = mp.name;
              currentMarketPlaceId = mp.id;
              return false;
            } else {
              currentMarketPlaceId = 496;
              document.title = "Palisade Knowledge";
              return true;
            }
          });
          // console.log("currentMarketPlaceId", currentMarketPlaceId);
          dispatch(
            setDomainAndUserInfo({
              marketplaceId: currentMarketPlaceId,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   const link = document.querySelector('link[rel="icon"]');
  //   let fav_icon = "favicon_palisade.ico";
  //   let currentDomain = window.location.host;
  //   if (currentDomain === "app.privhomes.com") {
  //     fav_icon = "favicon_privhomes.ico";
  //   } else if (currentDomain === "app.fidelityamericanhomes.com") {
  //     fav_icon = "favicon_palisade.ico";
  //   } else if (currentDomain === "app.palisade.ai") {
  //     fav_icon = "favicon_palisade.ico";
  //   } else if (currentDomain === "app.progressiveamericanproperties.com") {
  //     fav_icon = "favicon_palisade.ico";
  //   } else if (currentDomain === "app.prudentialhomesofamerica.com") {
  //     fav_icon = "favicon_palisade.ico";
  //   } else if (currentDomain === "app.myaihomevalueestimator.com") {
  //     fav_icon = "favicon_homevalueestimator.ico";
  //   } else if (currentDomain === "app.homeoffercalculator.com") {
  //     fav_icon = "favicon_homeoffercalculator.ico";
  //   } else if (currentDomain === "app.americanbestagents.com") {
  //     fav_icon = "favicon_americanbestagents.ico";
  //   } else if (currentDomain === "localhost:3000") {
  //     // For development purpose
  //     fav_icon = "favicon_palisade.ico";
  //   }

  //   if (link) {
  //     link.setAttribute("href", fav_icon);
  //   }
  // };

  return (
    <>
      <div className="signinPage__container">
        <div className="signinPage__header">
          <div>
            <Typography.Text className="signinPage__textDonthaveAccount">
              Don't have an account?
            </Typography.Text>
            <Link to={APP_ROUTES.SIGNUP.path}>
              <Button type="primary" className="signinPage__button">
                Sign Up
              </Button>
            </Link>
          </div>
        </div>
        <div className="signinPage">
          <Space
            direction="vertical"
            size={0}
            className="signinPage__signinFields"
          >
            <div className="signinPage__headerLogo">
              <AppLogo className="signinPage__logo" />
            </div>

            <Typography.Text className="signinPage__headerTitle">
              The World's First Marketplace For
              <br />
              Big Data & AI Projects
            </Typography.Text>
            {showErrorMessage && (
              <div className="signinPage__errorMessageContainer">
                <Image
                  className="signinPage__errorContainerCrossImage"
                  src={Close}
                  onClick={onErrorCloseClick}
                  preview={false}
                ></Image>
                <Typography.Text
                  className="signinPage__textErrorMessage"
                  type="danger"
                >
                  {errorMessage}
                </Typography.Text>
              </div>
            )}
            <Form layout="vertical" autoComplete="off" onFinish={handleSignin}>
              <Form.Item
                name="username"
                label="Enter Email"
                required
                rules={[
                  {
                    required: true,
                    message: "Please enter an email address",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
                hasFeedback
              >
                <Input
                  disabled={loading}
                  autoComplete="new-password"
                  placeholder="abc@xyz.com"
                  className="signinPage__inputField"
                  type={"email"}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Enter Password"
                required
                rules={[
                  {
                    required: true,
                    message: "Please enter a password.",
                  },
                  {
                    min: 8,
                    message: "Min 8 characters required.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  disabled={loading}
                  placeholder="Password"
                  className="signinPage__inputField"
                  autoComplete="new-password"
                  type={"password"}
                />
              </Form.Item>

              <Link to={APP_ROUTES.FORGOT_PASSWORD.path}>
                <Button className="signinPage__linkButton" type="link">
                  Forgot Password?
                </Button>
              </Link>

              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="signinPage__continueButton"
              >
                Sign In
              </Button>

              <Divider className="signinPage__divider">Or</Divider>
            </Form>
            <Spin spinning={socialLoading}>
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                render={(renderProps) => (
                  <div
                    className="signinPage__googleButtonDiv"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <div className="signinPage__googleIconBg">
                      <Image src={IconGoogle} preview={false}></Image>
                    </div>
                    <div className="signinPage__continueWithGoogleDiv">
                      <Typography.Text className="signinPage__textContinueWithGoogle">
                        Continue With Google
                      </Typography.Text>
                    </div>
                  </div>
                )}
                buttonText="Login"
                onSuccess={googleOnSuccess}
                onFailure={googleOnFailure}
                redirectUri={window.location.href}
              />
            </Spin>
          </Space>
        </div>

        <UserOnBoardingFooter />
      </div>
    </>
  );
}
export default Signin;
