import React, { useState } from "react";
import { Card, Button, Input } from "antd";
import { useNavigate } from "react-router-dom";
import "./index.scss";

function DesktopDatasetDetails({ handleCreateDataSet, handleDatasetDetails }) {
  const navigate = useNavigate();
  const [datasetName, setDatasetName] = useState('');
  const [description, setDescription] = useState('');

  const createDatasetClickListener = () => {
    if (datasetName && description) {
      handleCreateDataSet(datasetName, description);
      handleDatasetDetails(true);
    }
  };

  // Determine if the button should be disabled
  const isButtonDisabled = !datasetName || !description;

  return (
    <Card className="desktopSaveDatasetDetails">
      <div className="desktopSaveDatasetDetails-header">
        <p className="desktopSaveDatasetDetails-mainHeading">Dataset Name</p>
      </div>
      <div>
        <Input
          placeholder="Enter the name of your dataset"
          className="desktopSaveDatasetDetails-textInput"
          value={datasetName}
          onChange={(e) => setDatasetName(e.target.value)}
        />
      </div>
      <div className="desktopSaveDatasetDetails-header">
        <p className="desktopSaveDatasetDetails-mainHeading">Description</p>
      </div>
      <div>
        <Input.TextArea
          placeholder="Enter the description of your dataset"
          className="desktopSaveDatasetDetails-descriptionInput"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div>
        <Button
          className={`desktopSaveDatasetDetails__button ${isButtonDisabled ? 'disabled' : ''}`}
          onClick={createDatasetClickListener}
          disabled={isButtonDisabled}
        >
          Create
        </Button>
      </div>
    </Card>
  );
}

export default DesktopDatasetDetails;
