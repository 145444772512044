//Constants used in different parts of application

export const FON_ALLOWED_FILE_TYPES = ".csv";
export const CREATE_PLAYBOOK_ALLOWED_FILE_TYPES = "image/*";
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const SHOULD_SEARCH_ON_PAGE_LOAD = true;

//Entity Types supported in File Onboarding flow
export const ENTITY_TYPES = {
  COMPANY: 1,
  PEOPLE: 2,
  PLACES: 3,
  EVENTS: 4,
};

//Entity Types Names supported in File Onboarding flow
export const ENTITY_NAMES = {
  [ENTITY_TYPES.COMPANY]: "company",
  [ENTITY_TYPES.PEOPLE]: "people",
  [ENTITY_TYPES.PLACES]: "place",
  [ENTITY_TYPES.EVENTS]: "event",
};

//Sub Entities of Event Entity type supported in File Onboarding
export const ENTITY_EVENT_ID = {
  EMAIL: 1,
};

export const PLAYBOOK_ACTIONS = {
  REQUEST_PROPOSAL: "Request Proposal",
  REVIEWING: "Reviewing",
  HIGH_PRIORITY: "High Priority",
  MEDIUM_PRIORITY: "Medium Priority",
  LOW_PRIORITY: "Low Priority",
  NOT_INTRESTED: "Not Interested",
};

//Columns of different Entity types supported in File Onboarding flow
export const ENTITY_TYPE_COLUMNS = {
  [ENTITY_TYPES.COMPANY]: [
    { column_name: "company_name", required: true },
    { column_name: "company_website", required: true },
    { column_name: "source_url", required: true },
  ],
  [ENTITY_TYPES.PEOPLE]: [
    { column_name: "first_name", required: true },
    { column_name: "last_name", required: true },
    { column_name: "name", required: true },
    {
      column_name: "phone_number",
      required: true,
      tooltip: "Format: +(Country code)(Phone number)",
    },
    {
      column_name: "email",
      required: true,
      tooltip: "Should be a correct email address",
    },
    {
      column_name: "address",
      required: true,
      tooltip: "Format: Address line 1, can contain Unit as well",
    },
    {
      column_name: "city",
      required: true,
      tooltip: "Format: City Name or City Code",
    },
    {
      column_name: "state",
      required: true,
      tooltip: "Format: State Name or State Code",
    },
    {
      column_name: "zip",
      required: true,
      tooltip: "Format: Zip4 or Zip5",
    },
  ],
  [ENTITY_TYPES.PLACES]: [
    { column_name: "address", required: true },
    { column_name: "state", required: true },
    { column_name: "city", required: true },
    { column_name: "zip", required: true },
  ],
  [ENTITY_TYPES.EVENTS]: [
    { column_name: "email", required: true },
    { column_name: "timestamp", required: true },
  ],
};
//Type of fields like system field or source field used in file onboarding flow
export const FON_FIELD_IDENTIFIER = {
  SYSTEM: "system",
  SOURCE: "source",
};
export const DEEPLINK_IDENTIFIERS = {
  REQUEST_PROPOSAL: "requestProposal",
  PLAYBOOK: "playbook",
  JOIN_MARKETPLACE: "joinMarketplace",
  PROPOSAL_CHAT: "proposalChat",
  SELLER_LISTING: "seller-listing",
};

export const ANALYTICS_EVENT_NAMES = {
  // User Onboarding enents
  ONBOARDING_START: "platform_onboarding_start",
  ONBOARDING_EMAIL_ENTERED: "platform_onboarding_emailentered",
  ONBOARDING_EMAIL_VERIFIED: "platform_onboarding_emailverified",
  ONBOARDING_NAME_EMAIL_COMPLETED: "platform_onboarding_namepasswordcompleted",
  // ONBOARDING_PHONE_ENTERED: "platform_onboarding_phoneentered",
  ONBOARDING_PHONE_VERIFIED: "platform_onboarding_phoneverified",
  ONBOARDING_COMPLETE: "platform_onboarding_complete",
  ONBOARDING_SIGN_IN: "platform_onboarding_signin",

  //
  ONBOARDING_EVENT: "onboarding_event",
  ONBOARDING_NAME_EMAIL_ENTERED: "onboarding_nameemailentered",
  ONBOARDING_PHONE_ENTERED: "onboarding_phoneentered",
  ONBOARDING_PROFILE_CREATED: "onboarding_profilecreated",
  SIGNIN: "signin",
  PLATFORM_EVENT: "platform_event",
  PROPOSAL_REQUESTED: "proposal_requested",
  PROPOSAL_REQUEST_QUALIFIED: "proposal_request_qualified",
  PROPOSAL_CREATED: "proposal_created",
  PROPOSAL_SENT: "proposal_sent",
  PROPOSAL_REVIEWED: "proposal_reviewed",
  MEETING_SCHEDULED: "meeting_scheduled",
  REVENUE_GENERATED: "revenue_generated",
  CREATE_MARKETPLACE: "create_marketplace",
  SCHEDULE_MEETING_CLICKED: "schedule_meeting_clicked",
  CHAT_CREATED: "chat_created",
  LEAD_SUBMITTED_TO_PARTNER: "lead_submitted_to_partner",
  COMMUNICATION_INITIATED: "communication_initiated",
  LOGOUT: "logout",
};
export const SEGMENT_APP_NAME = "Palisade App";
