import { Image, message, Spin, Tooltip, Upload } from "antd";
import { removeParticipantFromConversation } from "api/TwilioChat";
import axios from "axios";
import { Buffer } from "buffer";
import { TWILIO_PASSWORD, TWILIO_USER_NAME } from "environments";
import { IconPhoneDisabled, IconPhoneEnabled } from "images";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { randomize } from "utils";
import AttachmentIcon from "../../../images/attachment.svg";
import SendIcon from "../../../images/send.svg";
import EmojiButton from "../EmojiButton";
import "./index.scss";
const username = TWILIO_USER_NAME;
const password = TWILIO_PASSWORD;

const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
const arr = [
  "+14153670390",
  "+14153670730",
  "+12056795242",
  "+18448256830",
  "+14157693779",
  "+12342353278",
  "+18776690251",
  "+14153670590",
  "+14156196266",
  "+16572166732",
  "+19497760931",
  "+19497761046",
  "+19497761115",
  "+19497761208",
  "+19497761239",
  "+19497760809",
  "+19497761118",
  "+19497761003",
  "+19497761165",
  "+19497761204",
  "+19497761126",
  "+19497760975",
  "+19497761193",
  "+19497760937",
  "+19497761053",
];

const ChatInput = ({
  onSend,
  showAttachment = false,
  activeConversation,
  appUsers,
  rightNavChat = false,
}) => {
  const [text, setText] = useState("");
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const [loading, setLoading] = useState(false);
  const [isSMSEnabled, setIsSMSEnabled] = useState(false);
  const [currentUserPSID, setCurrentUserPSID] = useState("");

  const addNewLine = () => setText(text + "\n");

  useEffect(() => {
    // console.log("active conversationSid", activeConversation);
    activeConversation?.getParticipants()?.then((participants) => {
      participants?.forEach((p) => {
        // console.log("Chat Participant", p);
        if (
          p?.state?.type === "sms" &&
          p?.state?.attributes?.id === userSession.userId
        ) {
          setIsSMSEnabled(true);
          setCurrentUserPSID(p?.state?.sid);
          return;
        }
      });
    });

    //set up the listener for the typing started Conversation event
    activeConversation?.on("typingStarted", typingIndicator);
    // //set  the listener for the typing ended Conversation event
    activeConversation?.on("typingEnded", typingIndicator);

    return () => {
      setText("");
      setLoading(false);
      setIsSMSEnabled(false);
      setCurrentUserPSID("");
      //set up the listener for the typing started Conversation event
      activeConversation?.off("typingStarted", typingIndicator);
      // //set  the listener for the typing ended Conversation event
      activeConversation?.off("typingEnded", typingIndicator);
    };
  }, [activeConversation]);

  const typingIndicator = (participant) => {
    console.log("typing indicator", participant);
  };

  const addSMSParticipants = () => {
    const randomizeData = randomize(arr);
    let twilioPhoneNumber = randomizeData.randomItem();
    // console.log("Chatinput twilioPhoneNumber", twilioPhoneNumber);

    const resultUser = {
      id: userSession.userId,
      phone_number: userSession.phoneNumber,
      first_name: userSession.firstName,
      last_name: userSession.lastName,
      image_uri: userSession.imageUri,
    };
    axios
      .post(
        `https://conversations.twilio.com/v1/Conversations/${activeConversation?.sid}/Participants`,
        new URLSearchParams({
          "MessagingBinding.Address": userSession.phoneNumber,
          "MessagingBinding.ProxyAddress": twilioPhoneNumber,
          Attributes: JSON.stringify(resultUser),
        }),
        {
          headers: {
            Authorization: `Basic ${token}`,
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        // console.log("Chatinput AddSMSParticipant success", res);
        message.success("SMS service enabled for this chat");
        setIsSMSEnabled(true);
        setCurrentUserPSID(res?.data?.sid);
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Chatinput AddSMSParticipant error", error);
        if (error?.response?.data?.code === 50416) {
          // Twilio error code 50416 means A binding for this participant and proxy address already exists in some Conversation
          addSMSParticipants();
        } else if (error?.response?.data?.message) {
          // addSMSParticipants(sid, resultUsersArray);
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      });
    // });
  };

  const removeSMSParticipiant = () => {
    setLoading(true);
    const payloadForRemoveSMSParticipiant = {
      conversationId: activeConversation?.sid,
      psid: currentUserPSID,
    };
    removeParticipantFromConversation(payloadForRemoveSMSParticipiant)
      .then((res) => {
        // console.log(res);
        message.success(res.data.message);
        setCurrentUserPSID("");
        setIsSMSEnabled(false);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          message.error(err.response.data.message);
        } else {
          message.error(err.message);
        }
        // setIsSMSEnabled(true);
        setLoading(false);
      });
  };

  const onChange = async ({ file }) => {
    console.log(file);
    const base63File = await toBase64(file);
    console.log("base63File", base63File);
    uploadMediaFile(base63File);
    //=======================
    // const fr = new FileReader();
    // fr.readAsArrayBuffer(file);
    // fr.onload = async function () {
    //   // you can keep blob or save blob to another position
    //   const blob = new Blob([fr.result], { type: file.type });
    //   console.log("blob", blob);
    // const base63File = await toBase64(file);
    // console.log("base63File", base63File);
    // Send a media message
    // const sendMediaOptions = {
    //   contentType: file.type,
    //   filename: file.name,
    //   media: base63File,
    // };

    // const formData = new FormData();
    // formData.append("file", base63File);
    // formData.append("name", file.name);
    // formData.append("Content-Type", file.type);
    //   const response = await activeConversation.sendMessage("test message", {
    //     contentType: file.type,
    //     media: {
    //       url: blob,
    //       filename: file.name,
    //     },
    //   });
    // const response = await activeConversation.sendMessage(formData);
    // console.log(response);
    // };
  };

  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
      reader.onerror = (error) => reject(error);
    });

  const uploadMediaFile = (file) => {
    console.log(activeConversation?.sid);
    const username = TWILIO_USER_NAME;
    const password = TWILIO_PASSWORD;
    const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );

    //=======================
    const payload = {
      mediaSid: "ME17206fe5cbb92d23f94ec00d5c0d5c93",
      Body: "Test media message",
      Author: userSession.userId,
    };
    const formData = new FormData();
    formData.append("mediaSid", "ME2dd3d1310b480539c744c9381d2faad8");

    axios
      .post(
        `https://conversations.twilio.com/v1/Conversations/${activeConversation?.sid}/Messages`,
        payload,
        {
          headers: {
            Authorization: `Basic ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("media message response", res);
      })
      .catch((err) => {
        console.log("media message error", err);
        if (err?.response?.data?.message) {
          message.error(err.response.data.message);
        } else {
          message.error(err.message);
        }
      });
  };

  // console.log("isSMSEnabled before return", isSMSEnabled);

  const setTypingIndicator = () => {
    console.log("activeConversation", activeConversation);
    activeConversation?.getParticipants()?.then((participants) => {
      participants?.forEach((p) => {
        // console.log("Chat Participant", p);
        if (
          p?.state?.type === "chat" &&
          p?.state?.identity === userSession.userId
        ) {
          // p?.state?.isTyping(true);
          return;
        }
      });
    });
  };

  return (
    <div className="chatInput">
      <div className="chatInput__container">
        <div className="chatInput__wrapper">
          <textarea
            value={text}
            placeholder="Message..."
            className={
              rightNavChat === true
                ? "chatInput__textareaRightNavChat"
                : "chatInput__textarea"
            }
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (e.altKey || e.ctrlKey) {
                  addNewLine();
                } else {
                  e.preventDefault();
                  onSend(text.trim());
                  setText("");
                }
              } else {
                // console.log("typing...");
                activeConversation?.typing();
              }
            }}
          />

          <div className="chatInput__toolsContainer">
            <Spin spinning={loading}>
              <div
                className={
                  rightNavChat === true
                    ? "chatInput__toolRightNavChat"
                    : "chatInput__tool"
                }
                // onMouseOver={() => {
                //   setShowPhoneHoverState(false);
                // }}
                // onMouseOut={() => {
                //   setShowPhoneHoverState(true);
                // }}
              >
                <Tooltip
                  placement="topRight"
                  title={
                    isSMSEnabled
                      ? "By disabling SMS option, you will not receive the chat on your mobile number"
                      : "By enabling SMS option, you will receive the chat on your mobile number"
                  }
                >
                  <Image
                    preview={false}
                    src={isSMSEnabled ? IconPhoneEnabled : IconPhoneDisabled}
                    onClick={
                      isSMSEnabled ? removeSMSParticipiant : addSMSParticipants
                    }
                  ></Image>
                </Tooltip>
              </div>
            </Spin>

            {showAttachment ? (
              <div
                className={
                  rightNavChat === true
                    ? "chatInput__toolRightNavChat"
                    : "chatInput__tool"
                }
              >
                <Upload
                  accept="image/*"
                  showUploadList={false}
                  customRequest={onChange}
                >
                  <Image preview={false} src={AttachmentIcon} />
                </Upload>
              </div>
            ) : null}

            <div
              className={
                rightNavChat === true
                  ? "chatInput__toolRightNavChat"
                  : "chatInput__tool"
              }
            >
              <EmojiButton onSelect={(emoji) => setText(text + " " + emoji)} />
            </div>
            <div
              className={
                rightNavChat === true
                  ? "chatInput__toolRightNavChat"
                  : "chatInput__tool"
              }
              onClick={() => {
                onSend(text.trim());
                setText("");
              }}
            >
              <img src={SendIcon} height={19} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatInput;
