import { useNavigate } from "react-router-dom";
import { Divider, Table, Button, Avatar, Space, Spin, Image } from "antd";
import { useEffect, useState } from "react";
import "./index.scss";
import { HistoryOutlined, DownOutlined } from "@ant-design/icons";
import { IconSalesforce } from "images";

function ListofDatasets({ datasetsListData }) {
  const navigate = useNavigate();
  const destinations = ["facebook", "search"];
  const [selectionType, setSelectionType] = useState("checkbox");
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    const calculateHeight = () => {
      const headerHeight =
        document.querySelector(".listOfDatasets__maindiv")?.offsetHeight || 0;
      const availableHeight = window.innerHeight - headerHeight - 380;
      setTableHeight(availableHeight);
    };

    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  const columns = [
    {
      title: "NAME & STATUS",
      dataIndex: "name",
      key: "name", // Make sure key is unique per row
      render: (text, record) => (
        <div className="listOfDatasets__detailsColumn">
          <div className={`status-circle ${record.status.toLowerCase()}`}></div>
          <div className="listOfDatasets__detailsContent">
            <div className="name">{record.dataset_name}</div>
            <div className="listOfDatasets__createdDate">
              Created By: {record.user_name}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "DESTINATION",
      dataIndex: "destination",
      key: "destination", // Make sure key is unique per row
      render: (text, record) => (
        <Space>
          {/* {destinations.map((dest) => (
            <span key={dest} className={`icon-${dest}`}></span>
          ))} */}
          <Image
            src={IconSalesforce}
            width={30}
            height={30}
            style={{ marginLeft: "30px" }}
            preview={false}
          />
        </Space>
      ),
    },
  ];

  let updatedListOfDatasets = datasetsListData.map((item, index) => ({
    ...item,
    key: index,
  }));

  const onNewDatasetClicked = () => {
    navigate(`/dashboard/datasets/datasets/steps`);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.dataset_name,
    }),
  };

  return (
    <div>
      <div className="listOfDatasets__maindiv">
        <div className="listOfDatasets__leftdiv">
          <h1
            style={{
              fontFamily: "Lato, sans-serif",
              fontWeight: "bold",
              fontSize: "16px",
              marginTop: "8px",
            }}
          >
            List of Datasets
          </h1>
          <h3
            style={{
              fontFamily: "Lato, sans-serif",
              fontWeight: "normal",
              fontSize: "14px",
              marginTop: "8px",
            }}
          >
            These are the datasets that you have created
          </h3>
          <Button icon={<HistoryOutlined />} className="listOfDatasets__allBtn">
            All
            <DownOutlined />
          </Button>
        </div>
        <div className="listOfDatasets__rightdiv">
          <Button
            className="listOfDatasets__createNewBtn"
            onClick={onNewDatasetClicked}
          >
            New Dataset
          </Button>
        </div>
      </div>

      <Table
        className="table"
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={updatedListOfDatasets}
        pagination={false}
        scroll={{ y: tableHeight }}
      />
    </div>
  );
}

export default ListofDatasets;
