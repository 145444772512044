import { Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setNewFileUploaded,
  updateFileStatus,
} from "store/modules/fileOnboard/action";
import { getRemainingTimeToUpload, getSelectedWorkspaceProfileId } from "utils";
import { getPresignedUrl, uploadFile } from "../../api/FileOnboard";
import { FON_ALLOWED_FILE_TYPES } from "../../constants";
import "./index.scss";

function FileUpload({ children }) {
  const dispatch = useDispatch();
  const userSession = useSelector((state) => state.authReducer).userSession;

  const handleFileUpload = ({ file }) => {
    const payloadForGetPresignedUrl = {
      profileworkspaceId: getSelectedWorkspaceProfileId(userSession),
      accountId: userSession.accountId,
      userId: userSession.userId,
      fileName: file.name,
    };

    const createStatusPayload = ({ ...rest }) => {
      const payload = {
        uid: file.uid,
        name: file.name,
        progress: rest?.progress || (rest?.uploadSuccess ? 1 : 0),
        timeRemaining: rest?.timeRemaining || 0,
        uploadError: rest?.uploadError || false,
        uploadSuccess: rest?.uploadSuccess || false,
      };
      return payload;
    };
    dispatch(updateFileStatus(createStatusPayload({})));

    getPresignedUrl(payloadForGetPresignedUrl)
      .then((res) => {
        const signedUrl = res.data.data.signedUrl;

        const startTime = new Date();
        uploadFile(
          { url: signedUrl, file },
          {
            onUploadProgress: (progressEvent) => {
              const progress = progressEvent.loaded / progressEvent.total;
              const timeRemaining = getRemainingTimeToUpload(
                startTime,
                progressEvent
              );
              dispatch(
                updateFileStatus(
                  createStatusPayload({
                    progress,
                    timeRemaining,
                  })
                )
              );
            },
          }
        )
          .then(() => {
            dispatch(
              updateFileStatus(
                createStatusPayload({
                  uploadSuccess: true,
                })
              )
            );
            dispatch(setNewFileUploaded(new Date().getTime()));
          })
          .catch(() => {
            dispatch(
              updateFileStatus(
                createStatusPayload({
                  uploadError: true,
                })
              )
            );
          });
      })
      .catch(() => {
        dispatch(
          updateFileStatus(
            createStatusPayload({
              uploadError: true,
            })
          )
        );
      });
  };
  return (
    <Upload
      customRequest={handleFileUpload}
      showUploadList={false}
      multiple
      accept={FON_ALLOWED_FILE_TYPES}
    >
      {children}
    </Upload>
  );
}
export default FileUpload;
