import "./index.scss";
import { Card, Button, Input, message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  clearDatasetState,
  setFormData,
  setStepCompletion,
} from "store/modules/datasets/action";
import { createDatasetAPI } from "api/Datasets";
import { getSelectedWorkspaceProfileId } from "utils";

function SaveDatasetDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [datasetName, setDatasetName] = useState("");
  const [datasetDesc, setDatasetDescription] = useState("");
  const steps = useSelector((state) => state.stepsReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;

  const handleInputChange = (subStep, subStepKey, value) => {
    setLoader(true);
    dispatch(setStepCompletion(7, true));
    dispatch(setFormData(subStep, subStepKey, value));
    dispatch(setStepCompletion(subStep, true));
    dispatch(clearDatasetState());
    handleCreateDataSet();
    // navigate(-2);
  };

  const handleCreateDataSet = () => {
    const sourceOnBoarded = steps && steps.steps[1.1].data.sourcesOnBoarded; // value selected in step 1
    const transformSourceData = steps && steps.steps[5.1].data.selectSource; //value selected in step 5.1
    const transformQueryData = steps && steps.steps[5.2].data.pasteQuery; //value selected in step 5.1
    const tempId = steps && steps.steps[3.4].data.list.tempId; // value in response from create list API
    const listId = steps && steps.steps[3.4].data.list.listId; // value in response from create list API
    const selectdestiantionSourceData =
      steps && steps.steps[7.1].data.selectSource; //value selected in step 7.1
    const profileWSId = getSelectedWorkspaceProfileId(userSession);
    const connName =
      steps && steps.steps[7.2].data.subDestinations.connectionName;
    const connId = steps && steps.steps[7.2].data.subDestinations.connectionId;

    const payload = {
      listId: listId, // list from create list API response
      query: transformQueryData, // step 5, query
      sourcesIncluded: sourceOnBoarded, // step 1, array of entities
      profileWorkspaceId: profileWSId.toString(),
      description: datasetDesc,
      entityTypeInIdentity: transformSourceData, // step 5, source id
      tempId: tempId, // have this value in create list API
      name: datasetName, // user input
      user_name: userSession.firstName + " " + userSession.lastName,
      destinations: "17",
      connectionName: connName,
      connectionId: connId,
    };

    console.log("create dataset api payload", payload);

    createDatasetAPI(payload)
      .then((res) => {
        // dispatch(clearDatasetState(null));
        console.log("create dataset api response", res?.data?.data);
        message.success("Dataset created successfully");
        setLoader(false);
        navigate(`/dashboard/datasets/datasets`);
      })
      .catch((err) => {
        setLoader(false);
        console.error("Error fetching data:", err);
      });
  };

  const handleNameChange = (event) => {
    setDatasetName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDatasetDescription(event.target.value);
  };

  return (
    <Card className="saveDatasetDetails">
      <div className="saveDatasetDetails-header">
        <p className="saveDatasetDetails-mainHeading">Dataset Name*</p>
      </div>
      <div>
        <Input
          placeholder="Name of Dataset"
          className="saveDatasetDetails-textInput"
          value={datasetName}
          onChange={handleNameChange}
        />
      </div>
      <div className="saveDatasetDetails-header">
        <p className="saveDatasetDetails-mainHeading">Description</p>
      </div>
      <div>
        <Input.TextArea
          placeholder="Description of your dataset"
          className="saveDatasetDetails-descriptionInput"
          value={datasetDesc}
          onChange={handleDescriptionChange}
          rules={[{ required: true }]}
        />
      </div>
      <div>
        <Button
          className="saveDatasetDetails__button"
          disabled={!datasetDesc}
          onClick={() => {
            handleInputChange("7.3", "saveDatasetDetails", {
              datasetName,
              datasetDesc,
            });
          }}
        >
          {loader ? <Spin /> : "Create"}
        </Button>
      </div>
    </Card>
  );
}
export default SaveDatasetDetails;
