import React, { useEffect, useState } from "react";
import { Image, Input, Table, Tag } from "antd";
import {
  EditOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  CheckOutlined,
  UserOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import "./index.scss";
import { useParams } from "react-router-dom";
import {
  IconCSVcompanies,
  IconCSVFile,
  IconCSVPeople,
  IconCSVPlaces,
  IconRows,
} from "images";
import { getUploadedCSVOverview } from "api/FileOnboard";

function CsvOverview() {
  // const { sourceId, sourceName, connectionName, connectorId, active } =
  //   useParams();
  const [csvSummary, setCsvSummary] = useState([]);
  const [editingRowKey, setEditingRowKey] = useState(null);
  const [editedText, setEditedText] = useState({});

  const handleEditClick = (record) => {
    setEditingRowKey(record.csv_source_id);
    setEditedText({
      ...editedText,
      [record.csv_source_id]: record.display_name,
    });
  };

  const handleSaveClick = (record) => {
    // Save the edited text and exit edit mode
    console.log("Edited value:", editedText[record.csv_source_id]);
    setEditingRowKey(null);
  };

  const handleInputChange = (e, record) => {
    console.log();
    setEditedText({ ...editedText, [record.csv_source_id]: e.target.value });
  };

  useEffect(() => {
    getUploadedCSVOverview()
      .then((res) => {
        // console.log("Overview API Response", res);
        const arr = Array.from({ length: 1 }, () => ({
          ...res?.data?.data[0],
        }));
        setCsvSummary(arr);
      })
      .catch((err) => {
        console.log("Overview API Error", err);
      });
  }, []);
  const uploadData = [
    {
      key: "1",
      name: "Companies_in_CA.csv",
      status: "Onboarding process complete",
    },
    {
      key: "2",
      name: "CA_Companies.csv",
      status: "Validation error",
    },
    {
      key: "3",
      name: "my_companies.csv",
      status: "AI Schema mapping failed",
    },
    {
      key: "4",
      name: "companies_la_ca.csv",
      status: "Onboarding process complete",
    },
  ];

  const summaryData = [
    {
      key: "1",
      aiName: "LA_companies",
      estimatedTime: "~ 20 minutes",
      rowsIdentified: "1293",
      date: "01/09/14",
      entities: ["People", "Places", "Companies"],
    },
  ];

  const identityData = [
    {
      key: "1",
      type: "People",
      count: "1254",
      fileName: "people_in_ca.csv",
      source: "people_ca",
    },
    {
      key: "2",
      type: "Places",
      count: "29",
      fileName: "places_ca.csv",
      source: "places_ca",
    },
    {
      key: "3",
      type: "Companies",
      count: "117",
      fileName: "ca_companies.csv",
      source: "companies_ca",
    },
  ];

  const createUploadColumns = (title, showStatus = true) => {
    const columns = [
      {
        title: title,
        dataIndex: "name",
        key: "name",
        render: (text) => (
          <div className="upload-summary__icon-row">
            <Image
              src={IconCSVFile}
              className="upload-summary__icon"
              preview={false}
            />
            <span>{text}</span>
          </div>
        ),
      },
    ];

    if (showStatus) {
      columns.push({
        title: "STATUS",
        dataIndex: "status",
        key: "status",
        render: (text) => <span>{text}</span>,
      });
    }

    return columns;
  };

  const summaryColumns = [
    {
      title: "SOURCE NAME",
      dataIndex: "display_name",
      key: "display_name",
      width: 120,
      render: (text, record) => (
        <div className="upload-summary__ai-name">
          {editingRowKey === record.csv_source_id ? (
            <>
              <Input
                value={editedText[record.csv_source_id]}
                onChange={(e) => handleInputChange(e, record)}
                style={{ width: "70%" }}
              />
              <CheckOutlined
                onClick={() => handleSaveClick(record)}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              />
            </>
          ) : (
            <>
              {text}
              <EditOutlined
                onClick={() => handleEditClick(record)}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              />
            </>
          )}
        </div>
      ),
    },
    {
      title: "ROWS",
      dataIndex: "rowsIdentified",
      key: "rowsIdentified",
      render: (text, record) => (
        <div className="upload-summary__icon-row">
          <Image
            src={IconRows}
            className="upload-summary__icon_rowsCount"
            preview={false}
          />
          <span>{record.csv_source_id}</span>
        </div>
      ),
    },
    {
      title: (
        <div className="upload-summary__title">
          <ExclamationCircleOutlined />
          <span> AGE OF DATA</span>
        </div>
      ),
      dataIndex: "ageOfData",
      key: "ageOfData",
      render: (text) => (
        <div className="upload-summary__time">
          <ExclamationCircleOutlined /> {"01/09/14"}
        </div>
      ),
    },
    {
      title: "AGE DETERMINED BY",
      dataIndex: "ageOfData",
      key: "ageOfData",
      render: (text) => (
        <div className="upload-summary__time">
          {"There's a timestamp in column C"}
        </div>
      ),
    },
  ];

  const identityColumns = [
    {
      title: "TOTAL IDENTITIES GENERATED",
      dataIndex: "type",
      key: "type",
      render: (text, record) => {
        let icon;

        // Switch case to handle icons based on the type of identity
        switch (text) {
          case "People":
            icon = (
              <Image
                src={IconCSVPeople}
                className="upload-summary__icon"
                preview={false}
              />
            );
            break;
          case "Places":
            icon = (
              <Image
                src={IconCSVPlaces}
                className="upload-summary__icon"
                preview={false}
              />
            );
            break;
          case "Companies":
            icon = (
              <Image
                src={IconCSVcompanies}
                className="upload-summary__icon"
                preview={false}
              />
            );
            break;
          default:
            icon = null;
        }

        return (
          <div className="upload-summary__icon-row">
            {icon}
            <span>
              {text} ({record.count})
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <div className="upload-summary">
      <Table
        className="upload-summary__table upload-summary__table--summary"
        columns={summaryColumns}
        dataSource={csvSummary}
        pagination={false}
      />

      <Table
        className="upload-summary__table upload-summary__table--identities"
        columns={identityColumns}
        dataSource={identityData}
        pagination={false}
        scroll={{ y: 400 }}
      />

      <Table
        className="upload-summary__table"
        columns={createUploadColumns("UPLOADS")}
        dataSource={uploadData}
        pagination={false}
      />
      <Table
        className="upload-summary__table"
        columns={createUploadColumns("DUPLICATE FILES", false)}
        dataSource={uploadData}
        pagination={false}
      />
    </div>
  );
}
export default CsvOverview;
