import { CloseOutlined, SettingOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import CustomAvatar from "commons/CustomAvatar";
import { useEffect, useState } from "react";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile } from "utils";
import "./index.scss";

export const AppHomeButton = () => {
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const [userProfileUrl, setUserProfileUrl] = useState(null);
  const [userFullName, setUserFullName] = useState(
    `${userSession.firstName} ${userSession.lastName}`
  );
  useCustomEventListener("profile-img-changed", (data) => {
    setUserProfileUrl(data);
  });
  useCustomEventListener("first-name-changed", (data) => {
    setUserFullName(data);
  });
  useEffect(() => {
    setUserProfileUrl(userSession?.imageUri);
  }, []);

  const leftNavCloseClicked = () => {
    emitCustomEvent("left_nav_close_clicked", false);
  };

  return (
    <>
      {isMobileView === true ? (
        <div className="appHomeButtonMobile">
          <CustomAvatar
            className="appAvatarMobile"
            imageUri={userProfileUrl}
            name={userFullName}
          />
          <div className="appHomeHeadContainerMobile">
            <div className="appHomeMainHeadMobile">
              <Tooltip title={userFullName}>
                {userSession.firstName + " " + userSession.lastName}
              </Tooltip>
            </div>
            <div className="appHomeSubHeadMobile">
              <Tooltip title={userSession.email}>{userSession.email}</Tooltip>
            </div>
          </div>
          <CloseOutlined
            className="appCloseLeftNavIcon"
            onClick={leftNavCloseClicked}
          />
        </div>
      ) : (
        <div className="appHomeButton">
          <CustomAvatar
            className="appAvatar"
            imageUri={userProfileUrl}
            name={userFullName}
          />
          <div className="appHomeHeadContainer">
            <div className="appHomeMainHead">
              <Tooltip title={userFullName}>
                {userSession.firstName}
              </Tooltip>
            </div>
            <div className="appHomeSubHead">
              <Tooltip title={userSession.email}>{userSession.email}</Tooltip>
            </div>
          </div>
          <Link to={"settings"}>
            <SettingOutlined className="appSettingIcon" />
          </Link>
        </div>
      )}
    </>
  );
};
