import "./index.scss";
import { Table } from "antd";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TickOn, TickOff } from "images";
import getFirstColumn from "./FirstColumn";
import GetSecondPlusColumn from "./SecondPlusColumn";

function PackageDetails({ packageDetails, requestBtnClicked, sellerId }) {
  const [columnsData, setColumnsData] = useState([]);
  const [packageSelection, setPackageSelection] = useState(0);

  const userSession = useSelector((state) => state.authReducer).userSession;

  let basic_index = 0;
  let standard_index = 0;
  let premiun_index = 0;

  for (var j = 0; j < 3; j++) {
    switch (packageDetails[j].name) {
      case "basic":
        basic_index = j;
        break;
      case "standard":
        standard_index = j;
        break;
      case "premium":
        premiun_index = j;
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    let list = [];

    let loopCount = 0;
    loopCount = packageDetails?.[0]?.options.length;

    for (var i = 0; i <= loopCount; i++) {
      if (
        i === packageDetails?.[0]?.options.length &&
        sellerId !== userSession?.userId
      ) {
        list.push({
          key: `${i}`,
          serviceName: "",
          b_isSelected: "buttonRow",
          s_isSelected: "buttonRow",
          p_isSelected: "buttonRow",
        });
      } else {
        list.push({
          key: `${i}`,
          serviceName: packageDetails[0]?.options[i]?.name,
          b_isSelected: packageDetails[basic_index]?.options[i]?.active,
          s_isSelected: packageDetails[standard_index]?.options[i]?.active,
          p_isSelected: packageDetails[premiun_index]?.options[i]?.active,
        });
      }
    }

    setColumnsData(list);
    if (packageSelection === undefined || packageSelection === null) {
      setPackageSelection(parseInt(packageDetails?.[basic_index]?.id));
    } else {
      setPackageSelection(parseInt(packageSelection));
    }
  }, []);

  const onPackageChanged = (packageid) => {
    setPackageSelection(packageid);
    requestBtnClicked(packageid);
  };

  const columns = [
    {
      title: "Package",
      heading:
        "Here are different package(s) available against selected playbook.",
      dataIndex: "serviceName",
    },
    {
      title: "Basic",
      dataIndex: "b_isSelected",
      align: "center",
    },
    {
      title: "Standard",
      dataIndex: "s_isSelected",
      align: "center",
    },
    {
      title: "Premium",
      dataIndex: "p_isSelected",
      align: "center",
    },
  ];

  columns[0] = getFirstColumn(
    "Package",
    "Here are different package(s) available against selected playbook.",
    "serviceName"
  );
  columns[1] = GetSecondPlusColumn(
    parseInt(packageDetails?.[basic_index]?.id),
    "Basic",
    parseInt(packageDetails?.[basic_index]?.price),
    packageDetails?.[basic_index]?.description,
    "b_isSelected",
    packageSelection,
    onPackageChanged
  );
  columns[2] = GetSecondPlusColumn(
    parseInt(packageDetails?.[standard_index]?.id),
    "Standard",
    parseInt(packageDetails?.[standard_index]?.price),
    packageDetails?.[standard_index]?.description,
    "s_isSelected",
    packageSelection,
    onPackageChanged
  );
  columns[3] = GetSecondPlusColumn(
    parseInt(packageDetails?.[premiun_index]?.id),
    "Premium",
    parseInt(packageDetails?.[premiun_index]?.price),
    packageDetails?.[premiun_index]?.description,
    "p_isSelected",
    packageSelection,
    onPackageChanged
  );

  return (
    <div>
      <Table
        className="packageDetails"
        rowClassName={(_, index) =>
          index % 2 === 0 ? "packageDetails__evenRow" : "packageDetails__oddRow"
        }
        columns={columns}
        dataSource={columnsData}
        bordered
        pagination={false}
      />
    </div>
  );
}

export default PackageDetails;
