import { Divider, Image, List } from "antd";
import React, { useState } from "react";
import "./index.scss";
import { Companies, Events, PeopleUploads, Places } from "images";
import { useSelector } from "react-redux";

function SelectEntityType({ entities, onItemSelection }) {
  const steps = useSelector((state) => state.stepsReducer);
  const sourceOnBoarded = steps && steps.steps[1].data.entityType; // value selected in step 1
  const [selectedItem, setSelectedItem] = useState(sourceOnBoarded);

  const entityTypes = [
    { icon: Companies, label: "Company" },
    { icon: Events, label: "Event" },
    { icon: PeopleUploads, label: "People" },
    { icon: Places, label: "Place" },
  ];

  const handleItemClick = (label) => {
    setSelectedItem(label);
    onItemSelection(label);
  };
  const filteredEntities = entityTypes.filter(
    (entity) => entities[entity.label] === "1"
  );
  
  return (
    <div className="selectEntityType">
      <div className="selectEntityType__heading">
        <span className="selectEntityType__heading__label">
          CHOOSE THE ENTITY TYPE
        </span>
      </div>
      <Divider className="selectEntityType__divider" />
      <List
        className="selectEntityType__list"
        itemLayout="horizontal"
        dataSource={filteredEntities}
        renderItem={(item) => (
          <List.Item
            className={`selectEntityType__list__item ${selectedItem === item.label
              ? "selectEntityType__list__item__selected"
              : ""
              }`}
            onClick={() => handleItemClick(item.label)}
          >
            <List.Item.Meta
              avatar={<Image src={item.icon} alt={item.label} />}
              title={
                <span className="selectEntityType__list__item__title">
                  {item.label}
                </span>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
}
export default SelectEntityType;
