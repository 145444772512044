import { ExclamationCircleOutlined } from "@ant-design/icons";
import { trackEventCall } from "analytics";
import { Image, List, message, Modal, Typography } from "antd";
import { deleteUserAccount } from "api/userManagement";
import classnames from "classnames";
import { ANALYTICS_EVENT_NAMES } from "constants";
import {
  IconAmericanBestAgent,
  IconAppSmall,
  IconChangePassword,
  IconEditMenu,
  IconFedilityAmerican,
  IconHomeOfferCalculator,
  IconHomeValueEstimator,
  IconLogout,
  IconNotificationSettings,
  IconPalisade,
  IconPrivhomes,
  IconProgressiveAmerican,
  IconPrudentialHomes,
  IconSwitchRole,
  IconUserManagement,
  IconYourProfile,
} from "images";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { clearAuthState, setUserSession } from "store/modules/auth/action";
import { clearFileOnboardingState } from "store/modules/fileOnboard/action";
import { isMobile } from "utils";
import "./index.scss";

export default function SettingsRightNav({ onItemClick }) {
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const [selectedItem, setSelectedItem] = useState(isMobileView ? 0 : 1);
  const [listData, setListData] = useState([]);
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const [currentDomainIcon, setCurrentDomainIcon] = useState(IconPalisade);

  var data = [
    {
      id: 1,
      title: "Your Profile",
      description: "Details about your personal Information",
      icon: IconYourProfile,
      route: isMobileView ? "profileSettings" : "settings/",
    },
    {
      id: 2,
      title: "Workspace Members",
      description: "Add/Remove members",
      icon: IconUserManagement,
      route: isMobileView ? "userManagement" : "settings/userManagement",
    },

    {
      id: 3,
      title: "Notification Settings",
      description: "Customize your notifications",
      icon: IconNotificationSettings,
      route: isMobileView
        ? "notificationSettings"
        : "settings/notificationSettings",
    },

    {
      id: 4,
      title: "Switch Role",
      description: "You are currently a buyer",
      icon: IconSwitchRole,
      route: isMobileView ? "switchRole" : "settings/switchRole",
    },
  ];

  const changePasswordOption = {
    id: 5,
    title: "Change Password",
    description: "Change password of your profile",
    icon: IconChangePassword,
    route: isMobileView ? "changePassword" : "settings/changePassword",
  };

  const editNavBarOption = {
    id: 6,
    title: "Edit Menu",
    description: "Customise or reorder your main menu",
    icon: IconEditMenu,
    route: isMobileView ? "editNavbar" : "settings/editNavbar",
  };

  useEffect(() => {
    let currentDomain = window.location.host;
    if (currentDomain === "app.privhomes.com") {
      setCurrentDomainIcon(IconPrivhomes);
    } else if (currentDomain === "app.fidelityamericanhomes.com") {
      setCurrentDomainIcon(IconFedilityAmerican);
    } else if (currentDomain === "app.palisade.ai") {
      setCurrentDomainIcon(IconPalisade);
    } else if (currentDomain === "app.progressiveamericanproperties.com") {
      setCurrentDomainIcon(IconProgressiveAmerican);
    } else if (currentDomain === "app.prudentialhomesofamerica.com") {
      setCurrentDomainIcon(IconPrudentialHomes);
    } else if (currentDomain === "app.myaihomevalueestimator.com") {
      setCurrentDomainIcon(IconHomeValueEstimator);
    } else if (currentDomain === "app.homeoffercalculator.com") {
      setCurrentDomainIcon(IconHomeOfferCalculator);
    } else if (currentDomain === "app.americanbestagents.com") {
      setCurrentDomainIcon(IconAmericanBestAgent);
    } else if (currentDomain === "localhost:3000") {
      // For development purpose
      setCurrentDomainIcon(IconPalisade);
    }
  }, []);
  useEffect(() => {
    let updatedData = [...data];
    if (userSession.isSocial) {
      updatedData = [...data];
    } else {
      updatedData = [...data, changePasswordOption];
    }
    if (userSession.isMarketPlaceOwner) {
      updatedData = [...updatedData, editNavBarOption];
    }
    setListData(updatedData);
  }, [userSession]);

  const logoutUser = () => {
    const eventData = {
      email: userSession.email,
      userId: userSession.userId,
    };
    trackEventCall(ANALYTICS_EVENT_NAMES.LOGOUT, eventData);
    dispatch(clearFileOnboardingState(null));
    dispatch(clearAuthState(null));
  };

  const deleteAccount = () => {
    confirm({
      title: "Do you want to delete your account?",
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      content: "By deleting your account, all of your data will be wiped out.",
      onOk() {
        // apiCallForDeleteAccount();
      },
      onCancel() {},
    });
  };

  const apiCallForDeleteAccount = () => {
    const payload = {
      email: userSession.email,
      isSocial: userSession.isSocial,
    };

    deleteUserAccount(payload)
      .then((res) => {
        console.log("Account deleted", res);
        message.success("Account deleted successfully!");
        logoutUser();
      })
      .catch((err) => {
        console.log("Account delete error", err);
      });
  };

  const onSwitchChange = (isSeller) => {
    const updatesUserSession = { ...userSession };
    if (isSeller === true) {
      updatesUserSession.accountRole = "seller";
      dispatch(setUserSession(updatesUserSession));
    } else {
      updatesUserSession.accountRole = "buyer";
      dispatch(setUserSession(updatesUserSession));
    }
  };

  return (
    <div className="settingsRightNav">
      {isMobileView === true ? (
        <div className="settingsRightNav__rightNavParentContainerMobile">
          <div className="settingsRightNav__rightNavContainer">
            {listData.length > 0
              ? listData?.map((item, i) => {
                  return (
                    <Link to={item.route}>
                      <div className="settingsRightNav__listParentDivMobile">
                        <div
                          className="settingsRightNav__listItemMobile"
                          id={item.id}
                          key={item.id}
                          onClick={() => {
                            onItemClick(true);
                          }}
                        >
                          <Image
                            className="settingsRightNav__listItemIconMobile"
                            preview={false}
                            src={item.icon}
                          ></Image>
                          <div>
                            <List.Item.Meta
                              title={
                                <Typography.Text className="settingsRightNav__headerTitleMobile">
                                  {item.title}
                                </Typography.Text>
                              }
                              description={
                                <Typography.Text className="settingsRightNav__descriptionMobile">
                                  {userSession.accountRole === "seller" &&
                                  item.title === "Switch Role"
                                    ? "You are currently a seller"
                                    : item.description}
                                </Typography.Text>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })
              : null}

            <div
              className="settingsRightNav__listItemMobile"
              onClick={logoutUser}
            >
              <Image
                className="settingsRightNav__listItemIconMobile"
                preview={false}
                src={IconLogout}
              ></Image>
              <div>
                <Typography.Text className="settingsRightNav__headerTitleMobile">
                  Logout
                </Typography.Text>
                <Typography.Text className="settingsRightNav__descriptionMobile">
                  Logout from the application
                </Typography.Text>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="settingsRightNav__rightNavParentContainer">
          <div className="settingsRightNav__rightNavContainer">
            <div className="settingsRightNav__headerIconDiv">
              <Image
                className="settingsRightNav__imgLogo"
                preview={false}
                src={currentDomainIcon}
              ></Image>
            </div>
            {/* <Divider className="settingsRightNav__divider"></Divider> */}
            {listData.length > 0
              ? listData?.map((item, i) => {
                  const isActive = selectedItem === item.id;
                  return (
                    <Link to={item.route}>
                      <div className="settingsRightNav__listParentDiv">
                        <div
                          className={classnames(
                            "settingsRightNav__listItem",
                            isActive && "settingsRightNav__listItemActive"
                          )}
                          id={item.id}
                          key={item.id}
                          onClick={() => setSelectedItem(item.id)}
                        >
                          <Image
                            className="settingsRightNav__listItemIcon"
                            preview={false}
                            src={item.icon}
                          ></Image>
                          <div>
                            <List.Item.Meta
                              title={
                                <Typography.Text
                                  className={classnames(
                                    "settingsRightNav__headerTitle",
                                    isActive &&
                                      "settingsRightNav__headerTitleActive"
                                  )}
                                >
                                  {item.title}
                                </Typography.Text>
                              }
                              description={
                                <Typography.Text className="settingsRightNav__description">
                                  {userSession.accountRole === "seller" &&
                                  item.title === "Switch Role"
                                    ? "You are currently a seller"
                                    : item.description}
                                </Typography.Text>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })
              : null}

            {/* <div className="settingsRightNav__listItem" onClick={deleteAccount}>
            <UserDeleteOutlined className="settingsRightNav__listItemIcon" />
            <div>
              <Typography.Text className="settingsRightNav__headerTitle">
                Delete Account
              </Typography.Text>
              <Typography.Text className="settingsRightNav__description">
                Erase your account permanently
              </Typography.Text>
            </div>
          </div> */}

            <div className="settingsRightNav__listItem" onClick={logoutUser}>
              <Image
                className="settingsRightNav__listItemIcon"
                preview={false}
                src={IconLogout}
              ></Image>
              <div>
                <Typography.Text className="settingsRightNav__headerTitle">
                  Logout
                </Typography.Text>
                <Typography.Text className="settingsRightNav__description">
                  Logout from the application
                </Typography.Text>
              </div>
            </div>
          </div>
          {/* <div className="settingsRightNav__switchRoleDiv">
          <Typography.Text
            className={classnames(
              "settingsRightNav__buyerText",
              userSession.accountRole === "seller" &&
                "settingsRightNav__buyerTextEnabled"
            )}
          >
            Buyer
          </Typography.Text>
          <Switch
            defaultChecked={userSession.accountRole === "seller"}
            onChange={onSwitchChange}
            disabled={userSession.isDefaultWorkspace ? false : true}
          ></Switch>
          <Typography.Text
            className={classnames(
              "settingsRightNav__sellerText",
              userSession.accountRole === "seller" &&
                "settingsRightNav__sellerTextEnabled"
            )}
          >
            Seller
          </Typography.Text>
        </div> */}
        </div>
      )}
    </div>
  );
}
