import React, { useState } from "react";
import { Input, Button, Switch, message, Tabs, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { deleteSource, editSource } from "api/Connectors";
import "./index.scss";

function ConnectorSettings() {
  const navigate = useNavigate();
  const { sourceId, sourceName, connectionName, connectorId, active } =
    useParams();
  const [loading, setLoading] = useState(false);
  const [isSwitchOn, setSwitchOn] = useState(true);
  const [connectName, setConnectName] = useState(connectionName);
  const [initialConnectionName, setInitialConnectionName] =
    useState(connectionName);

  const handleDeleteSource = () => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this source?",
      okText: "Yes",
      cancelText: "No",
      okButtonProps: {
        // Customize the props of the "Yes" button
        type: "primary", // Set the color of the button
        danger: true, // Indicate that this is a dangerous action
      },
      cancelButtonProps: {
        // Customize the props of the "No" button
        type: "default", // Set the color of the button
      },
      onOk() {
        deleteSource(parseInt(sourceId), sourceName, false)
          .then((res) => {
            navigate(`/dashboard/datasets/sources`);
            message.success(res?.data?.message);
          })
          .catch((err) => {
            message.error(
              err?.response?.data?.message || "Error deleting source"
            );
          });
      },
      onCancel() {
        // Do nothing if user cancels deletion
      },
    });
  };

  const handleEditSource = () => {
    if (!connectName.trim()) {
      message.error("Source name is empty. Cannot save changes.");
      return;
    }

    setLoading(true);

    const payload = {
      connectionId: parseInt(sourceId),
      connectionName: connectName,
      sourceName: sourceName,
      enabled: isSwitchOn,
      updateName: initialConnectionName !== connectName,
      connector_id: parseInt(connectorId),
    };

    editSource(payload)
      .then((res) => {
        message.success(res?.data?.message);
        navigate(`/dashboard/datasets/sources`);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNameChange = (event) => {
    setConnectName(event.target.value);
  };

  const handleSwitchChange = () => {
    setSwitchOn(!isSwitchOn);
  };

  const items = [
    connectorId == 10
      ? { key: "1", label: "Model" }
      : { key: "1", label: "Overview" },
    connectorId == 8 ? { key: "2", label: "Mapping" } : null,
    connectorId == 8 ? { key: "3", label: "Tasks" } : null,
    { key: "4", label: "Settings" },
  ];

  const onChange = (activeKey) => {
    const pathPrefix =
      sourceName === "Revcloud API Events"
        ? "/dashboard/datasets/apiconnector/overview"
        : "/dashboard/datasets/connector/overview";
    navigate(
      `${pathPrefix}/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
    );

    if (activeKey === "3") {
      navigate(
        `/dashboard/datasets/sources/tasks/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
    if (activeKey === "4") {
      navigate(
        `/dashboard/datasets/settings/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
  };

  return (
    <div className="ConnectorSettings">
      <div className="ConnectorSettings__headerText">
        <Tabs defaultActiveKey="4" items={items} onChange={onChange} />
      </div>
      <div className="ConnectorSettings__form">
        <h1 className="ConnectorSettings__topHeading">Basic Settings</h1>
        {/* <div className="ConnectorSettings__field">
          <h6 className="ConnectorSettings__heading">Source Id</h6>
          <p className="ConnectorSettings__para">
            You can differentiate by the source id.
          </p>
          <Input
            className="ConnectorSettings__input"
            name="Source Id"
            placeholder="dsfkjasKJSHKJHS1213"
            value={sourceId}
            disabled
          />
        </div> */}
        <div className="ConnectorSettings__field">
          <h6 className="ConnectorSettings__heading">Source Name *</h6>
          <p className="ConnectorSettings__para">
            Pick a name to help you identify this source in knowledge graph.
          </p>
          <Input
            className={`ConnectorSettings__input ${
              connectName.trim() === "" ? "empty-input" : ""
            }`}
            value={connectName}
            onChange={handleNameChange}
          />
        </div>
        {connectorId != 10 && (
          <div className="ConnectorSettings__field">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <h6 className="ConnectorSettings__heading">Enable Source</h6>
                <p className="ConnectorSettings__para">
                  Enabling this source will allow it to send data into knowledge
                  graph and any connected enabled destinations.
                </p>
                <Switch
                  defaultChecked={JSON.parse(isSwitchOn)}
                  onChange={handleSwitchChange}
                  style={{
                    backgroundColor: JSON.parse(isSwitchOn)
                      ? "#1c74d4"
                      : "#ccc",
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="ConnectorSettings__buttonsDiv">
        <Button
          className="ConnectorSettings__saveButton"
          type="primary"
          onClick={handleEditSource}
          loading={loading}
        >
          Save Changes
        </Button>
        <Button
          danger
          className="ConnectorSettings__deleteButton"
          onClick={handleDeleteSource}
          icon={
            <DeleteOutlined style={{ fontSize: "20px", marginLeft: "-5px" }} />
          }
        >
          Delete Source
        </Button>
      </div>
    </div>
  );
}

export default ConnectorSettings;
