import { trackEventCall, trackPageCall } from "analytics";
import {
  Button,
  Divider,
  Form,
  Image,
  message,
  Space,
  Spin,
  Typography,
} from "antd";
import { checkIfEmailExists } from "api/userManagement";
import { UserOnBoardingFooter } from "components/UserOnBoardingFooter";
import { ANALYTICS_EVENT_NAMES } from "../../constants";
import { Close, IconGoogle } from "images";
import { useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import { Link, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { getIPAddress, getSocialSigninRedirectURI } from "utils";
import AppLogo from "../../components/AppLogo";
import { AuthFieldEmail } from "../../components/AuthForm";
import {
  COGNITO_CLIENT_ID,
  COGNITO_DOMAIN,
  GOOGLE_CLIENT_ID,
} from "../../environments";
import "./index.scss";

function SignupOptions() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);
  const [externalPopup, setExternalPopup] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // trackEventCall(ANALYTICS_EVENT_NAMES.ONBOARDING_START);
    trackPageCall("Sign Up");
    //getIPAddress();
  }, []);

  const googleOnSuccess = (res) => {
    setSocialLoading(true);
    setShowErrorMessage(false);
    // const eventData = {
    //   via: "Google",
    // };
    // trackEventCall(ANALYTICS_EVENT_NAMES.ONBOARDING_START, eventData);
    const payloadForCheckEmailStatus = {
      email: res.profileObj.email,
    };
    checkIfEmailExists(payloadForCheckEmailStatus)
      .then((response) => {
        if (
          response?.data?.data?.no_user_found === true &&
          response?.data?.message === "Email does not exist"
        ) {
          navigate(APP_ROUTES.SIGNUP_ADD_PHONE.path, {
            state: {
              email: res.profileObj.email,
              firstName: res.profileObj.givenName
                ? res.profileObj.givenName
                : "",
              lastName: res.profileObj.familyName
                ? res.profileObj.familyName
                : "",
              password: "",
              isSocial: true,
              google_token: res.profileObj.googleId,
              imageUri: res.profileObj.imageUrl,
              tokenId: res.tokenId,
              isSocialSignin: true,
            },
          });
          setSocialLoading(false);
        } else if (response?.data?.error === false) {
          setLoading(false);
          setErrorMessage(response.data.message);
          setShowErrorMessage(true);
          setSocialLoading(false);
        }

        // setLoading(false);
        // setErrorMessage(response.data.message);
        // setShowErrorMessage(true);
        // setSocialLoading(false);
      })
      .catch((err) => {
        // navigate(APP_ROUTES.SIGNUP_ADD_PHONE.path, {
        //   state: {
        //     email: res.profileObj.email,
        //     firstName: res.profileObj.givenName ? res.profileObj.givenName : "",
        //     lastName: res.profileObj.familyName
        //       ? res.profileObj.familyName
        //       : "",
        //     password: "",
        //     isSocial: true,
        //     google_token: res.profileObj.googleId,
        //     imageUri: res.profileObj.imageUrl,
        //     tokenId: res.tokenId,
        //     isSocialSignin: true,
        //   },
        // });
        setSocialLoading(false);
      });
  };

  const googleOnFailure = (res) => {
    console.log("Google login failed", res);
  };

  const handleSignupWithEmail = (values) => {
    setShowErrorMessage(false);
    const { username } = values;
    // const eventData = {
    //   email: username,
    // };
    // trackEventCall(ANALYTICS_EVENT_NAMES.ONBOARDING_EMAIL_ENTERED, eventData);
    setLoading(true);
    const payloadForCheckEmailStatus = {
      email: username,
    };
    checkIfEmailExists(payloadForCheckEmailStatus)
      .then((res) => {
        if (
          res?.data?.data?.no_user_found === true &&
          res?.data?.message === "Email does not exist"
        ) {
          navigate(APP_ROUTES.SIGNUP_INFO.path, {
            state: { email: username, google_token: "" },
          });
        } else if (res?.data?.error === false) {
          setErrorMessage(res.data.message);
          setShowErrorMessage(true);
        }

        setLoading(false);
      })
      .catch((err) => {
        // trackEventCall(
        //   ANALYTICS_EVENT_NAMES.ONBOARDING_EMAIL_VERIFIED,
        //   eventData
        // );
        // navigate(APP_ROUTES.SIGNUP_INFO.path, {
        //   state: { email: username, google_token: "" },
        // });
        setLoading(false);
      });
  };
  const handleContinueWithGoogle = () => {
    const width = 500;
    const height = 400;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = `WINDOW TITLE`;
    const url = `${COGNITO_DOMAIN}oauth2/authorize?identity_provider=Google&client_id=${COGNITO_CLIENT_ID}&response_type=code&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=${getSocialSigninRedirectURI()}`;
    const popup = window.open(
      url,
      title,
      `width=${width},height=${height},left=${left},top=${top}`
    );
    setExternalPopup(popup);
  };

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      const currentUrl = externalPopup.location.href;
      if (!currentUrl) {
        return;
      }
      const searchParams = new URL(currentUrl).searchParams;
      const code = searchParams.get("code");
      if (code) {
        externalPopup.close();
        // console.log(`The popup URL has URL code param = ${code}`);
        // //YourApi.endpoint(code)
        //   .then(() => {
        //     // change UI to show after the code was stored
        //   })
        //   .catch(() => {
        //     // API error
        //   })
        //   .finally(() => {
        //     // clear timer at the end
        //     setExternalPopup(null);
        //     timer && clearInterval(timer);
        //   });
      }
    }, 500);
  }, [externalPopup]);

  const onErrorCloseClick = () => {
    setShowErrorMessage(false);
  };
  return (
    <>
      <div className="signupPage__container">
        <div className="signupPage__header">
          <div>
            <Typography.Text className="signupPage__textAlreadyhaveAccount">
              Already have an account?
            </Typography.Text>
            <Link to={APP_ROUTES.SIGNIN.path}>
              <Button type="primary" className="signupPage__button">
                Sign In
              </Button>
            </Link>
          </div>
        </div>
        <div className="signupPage">
          <Space
            direction="vertical"
            size={0}
            className="signupPage__signinFields"
          >
            <div className="signupPage__headerLogo">
              <AppLogo className="signupPage__logo" />
            </div>
            <Typography.Text className="signupPage__headerTitle">
              Create Your Workspace
            </Typography.Text>
            {showErrorMessage && (
              <div className="signinPage__errorMessageContainer">
                <Image
                  className="signinPage__errorContainerCrossImage"
                  src={Close}
                  onClick={onErrorCloseClick}
                  preview={false}
                ></Image>
                <Typography.Text
                  className="signinPage__textErrorMessage"
                  type="danger"
                >
                  {errorMessage}
                  <Link to={APP_ROUTES.SIGNIN.path}>
                    <span className="signupPage__spanSignin">Log in</span>
                  </Link>{" "}
                  Instead
                </Typography.Text>
              </div>
            )}
            <Form layout="vertical" onFinish={handleSignupWithEmail}>
              <Form.Item>
                {/* <div
                  className="signupPage__googleButtonDiv"
                  onClick={handleContinueWithGoogle}
                >
                  <div className="signupPage__googleIconBg">
                    <Image src={IconGoogle} preview={false}></Image>
                  </div>
                  <div className="signupPage__continueWithGoogleDiv">
                    <Typography.Text className="signupPage__textContinueWithGoogle">
                      Continue With Google
                    </Typography.Text>
                  </div>
                </div> */}
                <Spin spinning={socialLoading}>
                  <GoogleLogin
                    clientId={GOOGLE_CLIENT_ID}
                    render={(renderProps) => (
                      <div
                        className="signupPage__googleButtonDiv"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <div className="signupPage__googleIconBg">
                          <Image src={IconGoogle} preview={false}></Image>
                        </div>
                        <div className="signupPage__continueWithGoogleDiv">
                          <Typography.Text className="signupPage__textContinueWithGoogle">
                            Continue With Google
                          </Typography.Text>
                        </div>
                      </div>
                    )}
                    buttonText="Login"
                    onSuccess={googleOnSuccess}
                    onFailure={googleOnFailure}
                    redirectUri={window.location.href}
                  />
                </Spin>
              </Form.Item>

              <Divider className="signupPage__divider">Or</Divider>
              <AuthFieldEmail />
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="signupPage__continueButton"
              >
                Continue With Email
              </Button>
            </Form>
          </Space>
        </div>

        <UserOnBoardingFooter />
      </div>
    </>
  );
}
export default SignupOptions;
