import {
  Button,
  Checkbox,
  Image,
  Input,
  message,
  Spin,
  Typography,
  Upload,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import {
  createTwilioConversation,
  getPresignedUrlForGroupImage,
  updateConversationAttributes,
} from "api/TwilioChat";
import { getWorkspaceUsersList } from "api/userManagement";
import axios from "axios";
import { Buffer } from "buffer";
import CustomAvatar from "commons/CustomAvatar";
import { TWILIO_PASSWORD, TWILIO_USER_NAME } from "environments";
import { IconCamera, IconSearchChatCross } from "images";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  checkNameCharactersLimit,
  isMobile,
  randomize,
  uploadFileUsingPreSignedURL,
} from "utils";
import ChevronLeftIcon from "../../../images/chevron-left.svg";
import SearchIcon from "../../../images/search.svg";
import Flex from "../Flex";
import "./index.scss";
const username = TWILIO_USER_NAME;
const password = TWILIO_PASSWORD;

const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
const arr = [
  "+14153670390",
  "+14153670730",
  "+12056795242",
  "+18448256830",
  "+14157693779",
  "+12342353278",
  "+18776690251",
  "+14153670590",
  "+14156196266",
  "+16572166732",
  "+19497760931",
  "+19497761046",
  "+19497761115",
  "+19497761208",
  "+19497761239",
  "+19497760809",
  "+19497761118",
  "+19497761003",
  "+19497761165",
  "+19497761204",
  "+19497761126",
  "+19497760975",
  "+19497761193",
  "+19497760937",
  "+19497761053",
];
const randomizeData = randomize(arr);

const CreateChatPopup = (props) => {
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [conversationName, setConversationName] = useState("");
  const [randomUserImageUri, setRandomUserImageUri] = useState("");
  const [isMobileView, setIsMobileView] = useState(isMobile());
  // const [step, setStep] = useState(
  //   props.selectedOption === "New group chat" ? "name" : "selectusers"
  // );
  const [step, setStep] = useState("name");
  const [creatingConversation, setCreatingConversation] = useState(false);
  const [isSearchStarted, setIsSearchStarted] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  var groupChatImage = "";
  const [filePath, setFilePath] = useState(null);
  const onProgress = () => {};
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;

  useEffect(() => {
    setLoadingUsers(true);
    const payloadForUsersList = {
      account_id: "1", //userSession.userId,
    };
    getWorkspaceUsersList(payloadForUsersList)
      .then((res) => {
        // console.log("users", res);
        setUsers(res?.data?.data?.response);
        setSearchedUsers(res?.data?.data?.response);
        setSelectedUsers([...selectedUsers, userSession.userId]);
      })
      .catch((error) => {
        message.error(error?.message);
      })
      .finally(() => {
        setLoadingUsers(false);
      });
  }, []);

  const creatChatConversation = async () => {
    if (selectedUsers.length < 1) {
      message.error(
        "To create a conversation, please select at least one user"
      );
      return;
    }

    if (conversationName.length < 4) {
      message.error("Conversation name should be more than 4 characters");
      return;
    }

    if (conversationName.length > 256) {
      message.error("Conversation name should be less than 256 characters");
      return;
    }
    setCreatingConversation(true);
    // if (selectedUsers.length === 2) {
    //   for (var i = 0; i < selectedUsers.length; i++) {
    //     if (selectedUsers[i] != userSession.userId) {
    //       let otherUserID = selectedUsers[i];
    //       for (let j = 0; j < users.length; j++) {
    //         if (users[j].id === otherUserID) {
    //           groupChatImage = users[j].image_uri;
    //         }
    //       }
    //     }
    //   }
    // }

    let uniqueName = conversationName + "_" + userSession.userId;

    axios
      .post(
        `https://conversations.twilio.com/v1/Conversations`,
        new URLSearchParams({
          FriendlyName: conversationName,
          UniqueName: uniqueName,
        }),
        {
          headers: {
            Authorization: `Basic ${token}`,
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        let channelSid = res?.data?.sid;
        addChatParticipants(channelSid);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        } else {
          message.error(error?.message);
        }
        setCreatingConversation(false);
      });
  };

  const addChatParticipants = (sid) => {
    let resultUsersArray = [];
    searchedUsers?.forEach((user) => {
      for (let i = 0; i < selectedUsers.length; i++) {
        if (user.id === selectedUsers[i]) {
          resultUsersArray.push(user);
        }
      }
    });

    resultUsersArray?.forEach((resultUser) => {
      axios
        .post(
          `https://conversations.twilio.com/v1/Conversations/${sid}/Participants`,
          new URLSearchParams({
            Identity:
              resultUser.phone_number +
              "_" +
              userSession.selectedWorkspaceId +
              "-" +
              resultUser.id,
            Attributes: JSON.stringify(resultUser),
          }),
          {
            headers: {
              Authorization: `Basic ${token}`,
              "Content-type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          const obj = JSON.parse(res?.data?.attributes);
          // console.log("AddChatParticipant JSON Object", obj);

          // setCreatingConversation(false);
          setRandomUserImageUri(obj?.image_uri);
        })
        .catch((err) => {
          console.log("AddChatParticipant error", err);
          // setCreatingConversation(false);
        });
    });

    addSMSParticipants(sid, resultUsersArray);
    uploadChatImage(sid);
  };

  const addSMSParticipants = (sid, resultUsersArray) => {
    let twilioPhoneNumber = randomizeData.randomItem();
    resultUsersArray?.forEach((resultUser, i) => {
      axios
        .post(
          `https://conversations.twilio.com/v1/Conversations/${sid}/Participants`,
          new URLSearchParams({
            "MessagingBinding.Address": resultUser.phone_number,
            "MessagingBinding.ProxyAddress": twilioPhoneNumber,
            Attributes: JSON.stringify(resultUser),
          }),
          {
            headers: {
              Authorization: `Basic ${token}`,
              "Content-type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          // console.log("AddSMSParticipant success", res);
          // setCreatingConversation(false);
        })
        .catch((error) => {
          // console.log("AddSMSParticipant error", error);
          if (error?.response?.data?.code === 50416) {
            // Twilio error code 50416 means A binding for this participant and proxy address already exists in some Conversation
            addSMSParticipantsWithDifferentNumber(sid, resultUser);
          } else if (error?.response?.data?.message) {
            message.error(error.response.data.message);
          } else {
            message.error(error?.message);
          }
        });
    });
  };

  const addSMSParticipantsWithDifferentNumber = (sid, resultUser) => {
    let twilioPhoneNumber = randomizeData.randomItem();
    axios
      .post(
        `https://conversations.twilio.com/v1/Conversations/${sid}/Participants`,
        new URLSearchParams({
          "MessagingBinding.Address": resultUser.phone_number,
          "MessagingBinding.ProxyAddress": twilioPhoneNumber,
          Attributes: JSON.stringify(resultUser),
        }),
        {
          headers: {
            Authorization: `Basic ${token}`,
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        // console.log("AddSMSParticipant success", res);
        // setCreatingConversation(false);
      })
      .catch((error) => {
        // console.log("AddSMSParticipant error", error);
        if (error?.response?.data?.code === 50416) {
          // Twilio error code 50416 means A binding for this participant and proxy address already exists in some Conversation
          addSMSParticipants(sid, resultUser);
        } else if (error?.response?.data?.message) {
          message.error(error.response.data.message);
        } else {
          message.error(error?.message);
        }
      });
  };

  const uploadChatImage = (cId) => {
    if (filePath != null && selectedUsers.length >= 2) {
      const payloadForChatGroupUri = {
        conversationId: cId,
      };

      getPresignedUrlForGroupImage(payloadForChatGroupUri)
        .then((res) => {
          const uploadUrl = res?.data?.data?.url;
          const bucket = res?.data?.data?.bucket;
          const key = res?.data?.data?.key;
          groupChatImage =
            "https://" +
            bucket +
            ".s3.us-west-2.amazonaws.com" +
            "/" +
            key +
            `?abc=${Math.random()}`;

          uploadFileUsingPreSignedURL(uploadUrl, filePath, (progress) => {
            onProgress({
              percent: Math.ceil(progress),
            });
          })
            .then((uploadRes) => {
              const payloadForConvAttributes = {
                conversationId: cId,
                attributes: {
                  group_image_url: groupChatImage,
                },
              };
              updateConversationAttributes(payloadForConvAttributes)
                .then((attrRes) => {
                  props.onCancel();
                  setCreatingConversation(false);
                })
                .catch((attrErr) => {
                  console.log("Conversation Image Update error", attrErr);
                  props.onCancel();
                  setCreatingConversation(false);
                });
            })
            .catch((uploadErr) => {
              props.onCancel();
              setCreatingConversation(false);
            });
        })
        .catch((err) => {
          message.error(err.message);
          props.onCancel();
          setCreatingConversation(false);
        });
    }
    // else if (filePath === null && selectedUsers.length > 2) {
    //   // console.log("InUploadImage randomUserImageUri", randomUserImageUri);

    //   const payloadForConvAttributes = {
    //     conversationId: cId,
    //     attributes: {
    //       group_image_url: randomUserImageUri,
    //     },
    //   };
    //   updateConversationAttributes(payloadForConvAttributes)
    //     .then((attrRes) => {
    //       props.onCancel();
    //       setCreatingConversation(false);
    //     })
    //     .catch((attrErr) => {
    //       console.log("Conversation Image Update error", attrErr);
    //       props.onCancel();
    //       setCreatingConversation(false);
    //     });
    // }
    // else if (filePath === null && selectedUsers.length === 2) {
    //   const payloadForConvAttributes = {
    //     conversationId: cId,
    //     attributes: {
    //       group_image_url: groupChatImage,
    //     },
    //   };
    //   updateConversationAttributes(payloadForConvAttributes)
    //     .then((attrRes) => {
    //       props.onCancel();
    //       setCreatingConversation(false);
    //     })
    //     .catch((attrErr) => {
    //       props.onCancel();
    //       setCreatingConversation(false);
    //     });
    // }
    else {
      props.onCancel();
      setCreatingConversation(false);
    }
  };

  const onChange = ({ file }) => {
    setFilePath(file);
  };

  const handleOnNextClick = () => {
    if (conversationName.length === 0) {
      message.error("Conversation name can not be empty.");
      return;
    }
    if (conversationName.length < 4) {
      message.error("Conversation name should be more than 4 characters");
      return;
    }
    setStep("selectusers");
  };

  const handleSearchChange = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      setIsSearchStarted(true);
      const results = users.filter((user) => {
        const name = user.first_name + " " + user.last_name;
        return name.toLowerCase().includes(keyword.toLowerCase());
      });
      setUsers(results);
    } else {
      setIsSearchStarted(false);
      setUsers(searchedUsers);
    }
    setSearchedValue(keyword);
  };

  const onHeaderClicked = () => {
    if (step === "name") {
      props.onCancel();
    } else if (step === "selectusers") {
      setStep("name");
    }
  };

  const filteredUsers = users.filter((user) => selectedUsers.includes(user.id));

  return (
    <>
      {isMobileView === true ? (
        // <div className="createChatPopup__containerMobile">
        //   <div className="createChatPopup__createChatHeaderMobile"></div>
        // </div>
        <Modal
          visible={props.visible}
          title=""
          onCancel={props.onCancel}
          onOk={() => creatChatConversation()}
          footer={null}
          bodyStyle={{ margin: 0, padding: 0 }}
          centered
          closable={false}
          width={"100vw"}
          height={"100vh"}
          wrapClassName="createChatPopup__containerMobile"
        >
          <div className="createChatPopup__containerMobile">
            <div
              className="createChatPopup__headerMobile"
              onClick={onHeaderClicked}
            >
              <img
                src={ChevronLeftIcon}
                style={{ width: 31, height: 31, padding: 8 }}
              />
              <span className="createChatPopup__headerTitleMobile">
                New Chat
              </span>
            </div>
            {step === "name" && (
              <div className="createChatPopup__newGroupNameDivMobile">
                <Upload
                  accept=".png, .jpg, .jpeg"
                  showUploadList={false}
                  customRequest={onChange}
                >
                  <Image
                    className="createChatPopup__cameraIconMobile"
                    preview={false}
                    src={
                      filePath != null
                        ? URL.createObjectURL(filePath)
                        : IconCamera
                    }
                  ></Image>
                </Upload>

                <Input
                  showCount
                  maxLength={25}
                  placeholder={"Chat Name"}
                  onChange={(e) => setConversationName(e.target.value)}
                  value={conversationName}
                  className="createChatPopup__groupNameInputMobile"
                />
                <Button
                  shape="round"
                  type="primary"
                  className="createChatPopup__addGroupButtonMobile"
                  onClick={handleOnNextClick}
                >
                  Next
                </Button>
              </div>
            )}

            {step === "selectusers" && (
              <div className="createChatPopup__selectUserDivMobile">
                <div className="createChatPopup__searchMobile">
                  <Image
                    className="createChatPopup__searchIcon"
                    src={SearchIcon}
                    preview={false}
                  />
                  <input
                    placeholder="Search..."
                    className="createChatPopup__searchinput"
                    onChange={handleSearchChange}
                    value={searchedValue}
                  />
                </div>

                <div className="createChatPopup__searchUsersDivMobile">
                  {filteredUsers.map((user) => (
                    <div className="createChatPopup__searchedUsersParentDiv">
                      <div className="createChatPopup__searchAvatarDiv">
                        <CustomAvatar
                          className="createChatPopup__userProfileIcon"
                          imageUri={user.image_uri}
                          name={user?.first_name + " " + user?.last_name}
                        ></CustomAvatar>
                        {userSession.userId != user.id && (
                          <Image
                            wrapperClassName="createChatPopup__searchCrosIcon"
                            src={IconSearchChatCross}
                            preview={false}
                            onClick={() => {
                              setSelectedUsers(
                                selectedUsers.filter((uId) => uId !== user.id)
                              );
                            }}
                          ></Image>
                        )}
                      </div>
                      <Typography.Text className="createChatPopup__searchedUserNameTextMobile">
                        {checkNameCharactersLimit(user.first_name, 4)}
                      </Typography.Text>
                    </div>
                  ))}
                </div>

                <div className="createChatPopup__selectUsersContainerMobile">
                  {loadingUsers ? (
                    <Flex
                      flex={1}
                      justify="center"
                      align="center"
                      style={{ height: 100 }}
                    >
                      <Spin />
                    </Flex>
                  ) : (
                    users.map((user) => (
                      <div className="createChatPopup__userMobile">
                        <CustomAvatar
                          imageUri={user.image_uri}
                          name={user?.first_name + " " + user?.last_name}
                        ></CustomAvatar>
                        <span className="createChatPopup__fullName">
                          {user?.first_name + " " + user?.last_name}
                        </span>
                        <Checkbox
                          disabled={user.id === userSession.userId}
                          checked={selectedUsers.includes(user.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedUsers([...selectedUsers, user.id]);
                            } else {
                              setSelectedUsers(
                                selectedUsers.filter((uId) => uId !== user.id)
                              );
                            }
                          }}
                          style={{ marginLeft: "auto" }}
                        />
                      </div>
                    ))
                  )}
                </div>
                <div className="createChatPopup__createBtnContainer">
                  <Button
                    className="createChatPopup__addGroupButtonMobile"
                    shape="round"
                    type="primary"
                    onClick={creatChatConversation}
                    loading={creatingConversation}
                  >
                    Done
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Modal>
      ) : (
        <Modal
          visible={props.visible}
          title=""
          onCancel={props.onCancel}
          onOk={() => creatChatConversation()}
          footer={null}
          bodyStyle={{ padding: 0 }}
          centered
          width={400}
          height={467}
          wrapClassName="createChatPopup"
        >
          <div className="createChatPopup__container">
            <div className="createChatPopup__header" onClick={onHeaderClicked}>
              <img src={ChevronLeftIcon} style={{ marginRight: 16 }} />
              <span className="createChatPopup__headerTitle">New Chat</span>
            </div>
            {step === "name" && (
              <div className="createChatPopup__newGroupNameDiv">
                <Upload
                  accept=".png, .jpg, .jpeg"
                  showUploadList={false}
                  customRequest={onChange}
                >
                  <Image
                    className="createChatPopup__cameraIcon"
                    preview={false}
                    src={
                      filePath != null
                        ? URL.createObjectURL(filePath)
                        : IconCamera
                    }
                  ></Image>
                </Upload>

                <Input
                  showCount
                  maxLength={25}
                  placeholder={"Chat Name"}
                  onChange={(e) => setConversationName(e.target.value)}
                  value={conversationName}
                  className="createChatPopup__groupNameInput"
                />
                <Button
                  shape="round"
                  type="primary"
                  className="createChatPopup__addGroupButton"
                  onClick={handleOnNextClick}
                >
                  Next
                </Button>
              </div>
            )}

            {step === "selectusers" && (
              <div className="createChatPopup__selectUserDiv">
                <div className="createChatPopup__search">
                  <Image
                    className="createChatPopup__searchIcon"
                    src={SearchIcon}
                    preview={false}
                  />
                  <input
                    placeholder="Search..."
                    className="createChatPopup__searchinput"
                    onChange={handleSearchChange}
                    value={searchedValue}
                  />
                </div>

                <div className="createChatPopup__searchUsersDiv">
                  {filteredUsers.map((user) => (
                    <div className="createChatPopup__searchedUsersParentDiv">
                      <div className="createChatPopup__searchAvatarDiv">
                        <CustomAvatar
                          className="createChatPopup__userProfileIcon"
                          imageUri={user.image_uri}
                          name={user?.first_name + " " + user?.last_name}
                        ></CustomAvatar>
                        {userSession.userId != user.id && (
                          <Image
                            wrapperClassName="createChatPopup__searchCrosIcon"
                            src={IconSearchChatCross}
                            preview={false}
                            onClick={() => {
                              setSelectedUsers(
                                selectedUsers.filter((uId) => uId !== user.id)
                              );
                            }}
                          ></Image>
                        )}
                      </div>
                      <Typography.Text className="createChatPopup__searchedUserNameText">
                        {checkNameCharactersLimit(user.first_name, 4)}
                      </Typography.Text>
                    </div>
                  ))}
                </div>

                <div className="createChatPopup__selectUsersContainer">
                  {loadingUsers ? (
                    <Flex
                      flex={1}
                      justify="center"
                      align="center"
                      style={{ height: 100 }}
                    >
                      <Spin />
                    </Flex>
                  ) : (
                    users.map((user) => (
                      <div className="createChatPopup__user">
                        <CustomAvatar
                          imageUri={user.image_uri}
                          name={user?.first_name + " " + user?.last_name}
                        ></CustomAvatar>
                        <span className="createChatPopup__fullName">
                          {user?.first_name + " " + user?.last_name}
                        </span>
                        <Checkbox
                          disabled={user.id === userSession.userId}
                          checked={selectedUsers.includes(user.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedUsers([...selectedUsers, user.id]);
                            } else {
                              setSelectedUsers(
                                selectedUsers.filter((uId) => uId !== user.id)
                              );
                            }
                          }}
                          style={{ marginLeft: "auto" }}
                        />
                      </div>
                    ))
                  )}
                </div>
                <div className="createChatPopup__createBtnContainer">
                  <Button
                    className="createChatPopup__addGroupButton"
                    shape="round"
                    type="primary"
                    onClick={creatChatConversation}
                    loading={creatingConversation}
                  >
                    Done
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default CreateChatPopup;
