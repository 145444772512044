import "./index.scss";
import { createDatasetList, testQuery } from "api/Datasets";
import { useNavigate } from "react-router-dom";
import { MarkDoneEmpty, MarkedGreen } from "images";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { Collapse, Image, Card, Button, Input, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import {
  setActiveKey,
  setFormData,
  setStepCompletion,
} from "store/modules/datasets/action";

function AddExclusionsPasteQuery() {
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [queryValue, setQueryValue] = useState("");
  const steps = useSelector((state) => state.stepsReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const workspaceId = authReducer.userSession.selectedWorkspaceId;
  const [dataTempId, setDataTempId] = useState(null);
  const [dataListId, setDataListId] = useState(null);

  const handleTestQuery = async () => {
    setLoader(true);

    const sourceName = steps.steps[3.1].data.selectSource;
    if (sourceName) {
      try {
        const response = await testQuery({
          query: queryValue,
          entityType: sourceName,
        });
        handleInputChange(3.2, "pasteQuery", queryValue);
        setLoader(false);
        message.success("Query added successfully");
      } catch (error) {
        console.log(error);
        setLoader(false);
        message.error("Invalid SQL query. Please enter a valid SQL query.");
      }
    } else {
      message.error("Please select source from previous step");
      setLoader(false);
    }
  };

  useEffect(() => {
    const getQueryValue = steps && steps.steps[3.2].data.pasteQuery; // value selected in step 3.2
    setQueryValue(getQueryValue);
  }, []);

  const inputChange = (e) => {
    setQueryValue(e.target.value);
  };

  const handleCreateBuildList = (inputQuery) => {
    const entityType = steps && steps.steps[1].data.entityType; // value selected in step 1
    const inclusionSourceData = steps && steps.steps[2.1].data.selectSource; // value selected in step 2.1
    const inclusionQueryData = steps && steps.steps[2.2].data.pasteQuery; //value selected in step 2.2
    const exclusionSourceData = steps && steps.steps[3.1].data.selectSource; //value selected in step 3.1
    const dataTempId = steps && steps.steps[3.4].data.list.tempId; // value in response from create list API
    const dataExistingListId = steps && steps.steps[3.4].data.list.listId; // value in response from create list API
    const exclusionQueryData = inputQuery; //value selected in step 3.2

    const payload = {
      inclusionQuery: inclusionQueryData,
      exclusionQuery: exclusionQueryData,
      profileWorkspaceId: workspaceId,
      listDescription: "abc",
      entity_type: entityType,
      inclusion_entity_type_table: inclusionSourceData,
      exclusion_entity_type_table: exclusionSourceData,
      tmpId: dataTempId,
      existingListId: dataExistingListId,
    };
    console.log("create list api payload in 3.2:", payload);
    createDatasetList(payload)
      .then((res) => {
        console.log("Create datasets list API response", res.data?.data);
        const result = {
          tempId: res.data?.data?.tempId,
          listId: res.data?.data?.listId,
        };
        dispatch(setFormData(3.4, "list", result));
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  const handleInputChange = useCallback(
    (subStep, subStepKey, value) => {
      dispatch(setFormData(subStep, subStepKey, value));
      dispatch(setStepCompletion(subStep, true));
      dispatch(setStepCompletion(3, true));
      dispatch(setStepCompletion(4, true));
      handleCreateBuildList(value);
      dispatch(setActiveKey("5"));
      navigate(-2);
    },
    [dispatch, navigate]
  );

  const CustomPanelHeader = ({ mainHeading, subHeading }) => (
    <Card className="pasteQuery__customPanelHeader">
      <div className="pasteQuery__customPanelHeader-header">
        <Image
          src={
            steps && steps.steps[3.2]?.completed ? MarkedGreen : MarkDoneEmpty
          }
          preview={false}
          className="pasteQuery__customPanelHeader-image"
        />
        <div className="pasteQuery__customPanelHeader-headingContainer">
          <div className="pasteQuery__customPanelHeader-mainHeading">
            {mainHeading}
          </div>
          <div className="pasteQuery__customPanelHeader-subHeading">
            {subHeading}
          </div>
        </div>
      </div>
    </Card>
  );

  return (
    <div className="pasteQuery">
      <Collapse defaultActiveKey={["1"]} collapsible="disabled">
        <Panel
          header={
            <CustomPanelHeader
              mainHeading="Paste the query"
              subHeading="Add the SQL traits"
            />
          }
          key="1"
        >
          <Card className="pasteQuery__customCardContent">
            <div className="pasteQuery__customCardContent-header">
              <p className="pasteQuery__customCardContent-mainHeading">
                ...have a SQL Query
              </p>
            </div>
            <Input.TextArea
              className="desktopPasteQuery__customCardContent_textInput"
              placeholder="Write your query here"
              value={queryValue}
              onChange={inputChange} // Use the handler for onChange
            />
            <Button
              className="pasteQuery__customCardContent-saveButton"
              onClick={handleTestQuery}
              disabled={!queryValue}
            >
              {loader ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ color: "white", marginBottom: "2px" }}
                      spin
                    />
                  }
                />
              ) : (
                "Save"
              )}
            </Button>
          </Card>
        </Panel>
      </Collapse>
    </div>
  );
}

export default AddExclusionsPasteQuery;
