import { Image, Button, Form, Input, Space, Spin, Typography } from "antd";
import classNames from "classnames";
import AppLogo from "../AppLogo";
import "./index.scss";
import { IconContinueWithGoogle } from "images";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const AuthForm = ({
  loading = false,
  header = "",
  formFields = null,
  formButtons = null,
  footer = "",
  onFinish,
  hideLogo = false,
  disablePopupShadow = false,
}) => {
  return (
    <Spin spinning={loading}>
      <Space
        direction="vertical"
        size={0}
        className={classNames("authForm", {
          "authForm--withoutShadow": disablePopupShadow,
        })}
      >
        {!hideLogo && <AppLogo />}
        <Typography.Text className="authForm__header">{header}</Typography.Text>
        <Form layout="vertical" onFinish={onFinish}>
          {formFields}
          <Space className="authForm__buttonsContainer">{formButtons}</Space>
        </Form>
        <Typography.Text className="authForm__footer">{footer}</Typography.Text>
      </Space>
    </Spin>
  );
};
export const AuthFieldEmail = () => {
  return (
    <Form.Item
      name="username"
      label="Enter Your Email"
      required
      rules={[
        {
          required: true,
          message: "Please input your email.",
        },
        {
          type: "email",
          message: "Please enter a valid email.",
        },
      ]}
      hasFeedback
    >
      <Input
        autoComplete={false}
        placeholder="john.doe@xyz.com"
        className="authForm__inputField"
      />
    </Form.Item>
  );
};
export const AuthFieldPhoneNumber = () => {
  return (
    <Form.Item
      label="Phone Number"
      name="phonenumber"
      required
      rules={[
        {
          required: true,
          message: "Please input your phone number.",
        },
      ]}
      hasFeedback
    >
      <PhoneInput className="authForm__inputField" country={"us"} prefix="+ " />
    </Form.Item>
  );
};
export const AuthFieldPassword = () => {
  return (
    <Form.Item
      name="password"
      label="Password"
      required
      rules={[
        {
          required: true,
          message: "Please input your password.",
        },
        {
          min: 6,
          message: "Min 6 characters required.",
        },
      ]}
      hasFeedback
    >
      <Input.Password
        placeholder="Password"
        className="authForm__inputField"
        autoComplete="new-password"
      />
    </Form.Item>
  );
};
export const AuthFieldConfirmPassword = () => {
  return (
    <Form.Item
      name="confirm"
      label="Confirm Password"
      dependencies={["password"]}
      hasFeedback
      rules={[
        {
          required: true,
          message: "Please confirm your password.",
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error("Passwords do not match."));
          },
        }),
      ]}
    >
      <Input.Password
        placeholder="Confirm password"
        className="authForm__inputField"
      />
    </Form.Item>
  );
};
export const AuthFieldOldPassword = () => {
  return (
    <Form.Item
      name="oldPassword"
      label="Old Password"
      required
      rules={[
        {
          required: true,
          message: "Please input your old password.",
        },
        {
          min: 6,
          message: "Min 6 characters required.",
        },
      ]}
      hasFeedback
    >
      <Input.Password
        placeholder="Old Password"
        className="authForm__inputField"
        autoComplete="new-password"
      />
    </Form.Item>
  );
};
export const AuthFieldCodeConfirm = () => {
  return (
    <Form.Item
      name="confirmationCode"
      label="Confirmation Code"
      required
      rules={[{ required: true, message: "Please input confirmation code." }]}
    >
      <Input
        placeholder="Confirmation code"
        className="authForm__inputField"
      ></Input>
    </Form.Item>
  );
};
export const AuthFormNormalButton = ({ children, onClick }) => {
  return (
    <Button className="authForm__button" onClick={onClick}>
      {children}
    </Button>
  );
};
export const AuthFormSubmitButton = ({ children, onClick }) => {
  return (
    <Button
      className="authForm__button"
      htmlType="submit"
      type="primary"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export const AuthFormLinkButton = ({ children, onClick }) => {
  return (
    <Form.Item>
      <Button className="authForm__linkButton" onClick={onClick} type="link">
        {children}
      </Button>
    </Form.Item>
  );
};

export const AuthFormGoogleButton = ({ children, onClick }) => {
  return (
    <Form.Item>
      <Image
        src={IconContinueWithGoogle}
        className="authForm__googleButton"
        onClick={onClick}
        preview={false}
      >
        {children}
      </Image>
    </Form.Item>
  );
};
