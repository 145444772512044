import { SearchOutlined } from "@ant-design/icons";
import { Button, Image, Input } from "antd";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { useState } from "react";
import FilterIconNormal from "../../../../images/filter-deactive.svg";
import FilterIconSelected from "../../../../images/filters-selected.svg";
import "./index.scss";

function Searchbar({
  placeholder = "",
  filtersVisible,
  onFiltersVisibilityChange,
  onSubmitSearchText,
  filtersApplied = false,
  onCloseBtnClicked,
}) {
  const [searchedText, setSearchedText] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const shouldHighlightField = !isEmpty(searchedText) || isFocused;
  const shouldHighlightFilters = filtersApplied || filtersVisible;
  return (
    <div className="searchbar">
      <div
        className={classNames("searchbar__field", {
          "searchbar__field--focused": shouldHighlightField,
        })}
      >
        <Input
          placeholder={placeholder}
          value={searchedText}
          onChange={(e) => {
            setSearchedText(e.target.value);
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
          onPressEnter={() => {
            onSubmitSearchText(searchedText);
          }}
          onBlur={() => {
            setIsFocused(false);
            onSubmitSearchText(searchedText);
          }}
          addonBefore={
            <SearchOutlined className="searchbar__field__searchIcon" />
          }
          addonAfter={
            <div
              className="searchbar__field__addonAfter"
              onClick={() => {
                onFiltersVisibilityChange(!filtersVisible);
              }}
            >
              <span
                className={classNames(
                  "searchbar__field__addonAfter__allFilters",
                  {
                    "searchbar__field__addonAfter__allFilters--focused":
                      shouldHighlightFilters,
                  }
                )}
              >
                All Filters
              </span>
              <Image
                preview={false}
                src={
                  shouldHighlightFilters ? FilterIconSelected : FilterIconNormal
                }
                width={16}
                wrapperClassName="searchbar__field__addonAfter__filterIcon"
              />
            </div>
          }
        />
      </div>
    </div>
  );
}
export default Searchbar;
