import React, { useState, useEffect } from "react";
import "./index.scss";
import { Card, Collapse, Drawer, Image } from "antd";
import { MarkedGreen, MarkDoneEmpty } from "images";
import SelectEntityType from "../../SelectEntityType";
import AlysonGreetingCard from "../../Components/AlysonGreetingCard";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setDestinations,
  setFormData,
  setActiveKey,
  setStepCompletion,
} from "store/modules/datasets/action";

const { Panel } = Collapse;

function Steps({ entities }) {
  const navigate = useNavigate();

  const [activePanel, setActivePanel] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [showGreetingCard, setShowGreetingCard] = useState(false);
  const dispatch = useDispatch();
  const steps = useSelector((state) => state.stepsReducer);

  let collapseActiveKey = steps.activeKey.activeKey;

  // console.log("Steps : ", steps);

  const handleInputChange = (step, subStep, value, panelKey) => {
    dispatch(setFormData(step, subStep, value));
    const isStepCompleted = true;
    dispatch(setStepCompletion(step, isStepCompleted));
    dispatch(setActiveKey("2"));
  };

  useEffect(() => {
    const isGreetingDismissed = localStorage.getItem("greetingDismissed");
    if (!isGreetingDismissed) {
      setShowGreetingCard(true);
    }
  }, []);

  const handleDismiss = () => {
    localStorage.setItem("greetingDismissed", "true");
    setShowGreetingCard(false);
  };

  const CustomPanelHeader = ({
    number,
    mainHeading,
    subHeading,
    panelKey,
    isActive,
    onClick,
  }) => (
    <div
      className={`steps__panel-header ${
        steps && steps.steps[number]?.completed && !isActive
          ? "steps__panel-header--completed"
          : isActive
          ? "steps__panel-header--active"
          : "steps__panel-header--inactive"
      }`}
      onClick={onClick}
    >
      <div
        className={`steps__panel-header__number ${
          steps && steps.steps[number]?.completed && !isActive
            ? "steps__panel-header__number--completed"
            : isActive
            ? "steps__panel-header__number--active"
            : "steps__panel-header__number--inactive"
        }`}
      >
        {number}
      </div>
      <div className="steps__panel-header__text">
        <div
          className={`steps__panel-header__text__main-heading ${
            steps && steps.steps[number]?.completed && !isActive
              ? "steps__panel-header__text__main-heading--completed"
              : isActive
              ? "steps__panel-header__text__main-heading--active"
              : "steps__panel-header__text__main-heading--inactive"
          }`}
        >
          {mainHeading}
        </div>
        <div
          className={`steps__panel-header__text__sub-heading ${
            steps && steps.steps[number]?.completed
              ? "steps__panel-header__text__sub-heading--completed"
              : isActive
              ? "steps__panel-header__text__sub-heading--active"
              : "steps__panel-header__text__sub-heading--inactive"
          }`}
        >
          {subHeading}
        </div>
      </div>
    </div>
  );
  const CustomCardContent = ({
    mainHeading = "",
    subHeading = "",
    onClick,
    number = 1,
  }) => (
    <Card className="steps__card-content" onClick={onClick}>
      <div className="steps__card-content__header">
        <Image
          src={
            steps && steps.steps[number]?.completed && activePanel == number
              ? MarkedGreen
              : MarkDoneEmpty
          }
          preview={false}
          className="steps__card-content__header__image"
        />
        <p className="steps__card-content__header__main-heading">
          {mainHeading}
        </p>
      </div>
      <div className="steps__card-content__sub-heading">{subHeading}</div>
    </Card>
  );

  const handleCardClick = () => {
    setDrawerVisible(true);
  };

  // step 2
  const handleBuildListClicked = () => {
    navigate(`/dashboard/datasets/datasets/build-list`);
  };

  // step 3
  const handleAddExclusionsClicked = () => {
    navigate(`/dashboard/datasets/datasets/add-exclusions`);
  };

  // step 5
  const handleStep5Click = () => {
    // logic to check if user has entered data until step 3
    if (collapseActiveKey === "3") {
      collapseActiveKey = "5";
      dispatch(setActiveKey("5"));
    }
  };

  // step 5.1
  const handleTransformDataClicked = () => {
    navigate(`/dashboard/datasets/datasets/build-list/transform-your-data`);
  };

  // step 6
  const handlePreviewYourListClicked = () => {
    navigate(`/dashboard/datasets/datasets/preview-your-list`);
  };

  // step 7
  const handelSelectYourDestinationsClicked = () => {
    navigate(`/dashboard/datasets/datasets/select-your-destinations`);
  };

  const handlePanelChange = (panelKey) => {
    setActivePanel(activePanel === panelKey ? null : panelKey);
  };

  return (
    <div className="steps">
      <div className="steps__scrollable">
        <Collapse
          accordion
          expandIconPosition="end"
          onChange={handlePanelChange}
          activeKey={collapseActiveKey}
          className="steps"
        >
          <Panel
            header={
              <CustomPanelHeader
                number={1}
                mainHeading="Select Your Entity Type"
                subHeading="1 task"
                panelKey="1"
                isActive={activePanel === "1"}
              />
            }
            key="1"
          >
            <CustomCardContent
              mainHeading="Set your entity type"
              subHeading="Entities in dataset: People, Places or Companies"
              onClick={handleCardClick}
              number={1}
            />
          </Panel>
          <Panel
            header={
              <CustomPanelHeader
                number={2}
                mainHeading="Build Your List"
                subHeading="Multiple tasks"
                panelKey="2"
                isActive={activePanel === "2"}
              />
            }
            key="2"
          >
            <CustomCardContent
              mainHeading="Who should be included in the list"
              subHeading=""
              onClick={handleBuildListClicked}
              number={2}
            />
          </Panel>
          <Panel
            header={
              <CustomPanelHeader
                number={3}
                mainHeading="Add Exclusions"
                subHeading="Multiple tasks"
                panelKey="3"
                isActive={activePanel === "3"}
              />
            }
            key="3"
          >
            <CustomCardContent
              mainHeading="Build your list of People"
              subHeading="Define your exclusion segments"
              onClick={handleAddExclusionsClicked}
              number={3}
            />
          </Panel>
          <Panel
            header={
              <CustomPanelHeader
                number={4}
                mainHeading="Join Columns"
                subHeading="Optional"
                panelKey="4"
                isActive={activePanel === "4"}
              />
            }
            key="4"
          >
            <CustomCardContent
              mainHeading="Select the source"
              subHeading="Match source with list of fields to your dataset fields"
              number={4}
            />
          </Panel>
          <Panel
            header={
              <CustomPanelHeader
                number={5}
                mainHeading="Transform Your Data"
                subHeading="Multiple tasks"
                panelKey="5"
                isActive={activePanel === "5"}
                onClick={handleStep5Click}
              />
            }
            key="5"
          >
            <CustomCardContent
              mainHeading="Paste SQL to transform data"
              subHeading="Add the SQL traits"
              onClick={handleTransformDataClicked}
              number={5}
            />
          </Panel>
          <Panel
            header={
              <CustomPanelHeader
                number={6}
                mainHeading="Preview Your List"
                subHeading="1 task"
                panelKey="6"
                isActive={activePanel === "6"}
              />
            }
            key="6"
          >
            <CustomCardContent
              mainHeading="Preview your list in a Google sheet"
              subHeading="View and approve list"
              onClick={handlePreviewYourListClicked}
              number={6}
            />
          </Panel>
          <Panel
            header={
              <CustomPanelHeader
                number={7}
                mainHeading="Select Your Destinations"
                subHeading="Multiple tasks"
                panelKey="7"
                isActive={activePanel === "7"}
              />
            }
            key="7"
          >
            <CustomCardContent
              mainHeading="Choose destination & finalize dataset"
              onClick={handelSelectYourDestinationsClicked}
              number={7}
            />
          </Panel>
        </Collapse>
      </div>
      <Drawer
        placement="bottom"
        closable={true}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        height="50%"
        className="custom-drawer"
      >
        <SelectEntityType
          entities={entities}
          onItemSelection={(entityType) => {
            handleInputChange(1, "entityType", entityType);
            setDrawerVisible(false);
          }}
        />
      </Drawer>
    </div>
  );
}
export default Steps;
