import { Client as ConversationsClient } from "@twilio/conversations";
import { trackEventCall, trackPageCall } from "analytics";
import { Image, List, message, Popover, Spin, Tooltip, Typography } from "antd";
import { getTwilioToken, updateConversationAttributes } from "api/TwilioChat";
import { getWorkspaceUsersList } from "api/userManagement";
import { getMarketplaceMessages } from "api/Workspaces";
import axios from "axios";
import { Buffer } from "buffer";
import classnames from "classnames";
import { ANALYTICS_EVENT_NAMES } from "constants";
import { TWILIO_PASSWORD, TWILIO_USER_NAME } from "environments";
import { useEffect, useRef, useState } from "react";
import { emitCustomEvent } from "react-custom-events";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { checkNameCharactersLimit, isMobile, randomize } from "utils";
import { IconNewChat, IconNewChatBlue } from "../../images";
import Avatar from "./Avatar";
import ChatUI from "./ChatUI";
import CreateChatPopup from "./CreateChatPopup";
import Flex from "./Flex";
import "./index.scss";

const username = TWILIO_USER_NAME;
const password = TWILIO_PASSWORD;

const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
const arr = [
  "+14153670390",
  "+14153670730",
  "+12056795242",
  "+18448256830",
  "+14157693779",
  "+12342353278",
  "+18776690251",
  "+14153670590",
  "+14156196266",
  "+16572166732",
  "+19497760931",
  "+19497761046",
  "+19497761115",
  "+19497761208",
  "+19497761239",
  "+19497760809",
  "+19497761118",
  "+19497761003",
  "+19497761165",
  "+19497761204",
  "+19497761126",
  "+19497760975",
  "+19497761193",
  "+19497760937",
  "+19497761053",
];
const randomizeData = randomize(arr);

function UnReadMessageCount({ conversation, refresh }) {
  const [unReadCount, setUnReadCount] = useState("0");

  useEffect(() => {
    getUnreadMessageCount(conversation);
    return () => {
      setUnReadCount("0");
    };
  }, [refresh, conversation]);

  const getUnreadMessageCount = (conversation) => {
    // get unread messages count for the user, that is, count of all the messages after message
    conversation
      ?.getUnreadMessagesCount()
      ?.then((count) => {
        setUnReadCount(count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className="chatMainPage__unreadCountContainer"
      style={{
        visibility: unReadCount > 0 ? "visible" : "hidden",
      }}
    >
      <span className="chatMainPage__unreadCount">{unReadCount}</span>
    </div>
  );
}

function Chat() {
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const [createChatModalVisible, setCreateChatModalVisible] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState("connecting");
  const [conversations, setConversations] = useState([]);
  const [appUsers, setAppUsers] = useState({});
  var latestConversationName = "-1";
  const [selectedMessageOption, setSelectedMessageOption] = useState("");
  const [activeConversationSid, setActiveConversationSid] = useState("");
  const [loadingConversations, setLoadingConversations] = useState(true);
  const [twilioToken, setTwilioToken] = useState(null);
  const [creatingConversation, setCreatingConversation] = useState(false);
  var groupChatImage = "";
  const twilio = useRef(null);
  const { state } = useLocation();
  const [isChatStateUsed, setIsChatStateUsed] = useState(false);
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const navigate = useNavigate();
  const externalLinkSegmentParams = authReducer.externalLinkSegmentParams;
  const [proposalMessagesData, setProposalMessagesData] = useState({});

  useEffect(() => {
    const pageCallData = {
      marketplaceId: userSession.selectedWorkspaceId,
    };
    const mergedJson = {
      ...pageCallData,
      ...externalLinkSegmentParams,
    };
    trackPageCall("Conversations", mergedJson);
  }, []);

  useEffect(() => {
    getMarketplaceMessages(userSession.selectedWorkspaceId)
      .then((res) => {
        // console.log("messages api response1", res);
        setProposalMessagesData(res?.data?.data);
      })
      .catch((err) => {
        console.log("messages api error: ", err);
      });
  }, []);

  useEffect(() => {
    const payloadForUsersList = {
      account_id: "1", //userSession.userId,
    };
    getWorkspaceUsersList(payloadForUsersList)
      .then((res) => {
        const usersMap = {};
        res.data.data.response.forEach((user) => {
          usersMap[user.phone_number] = user;
        });
        setAppUsers(usersMap);
      })
      .catch((error) => {
        message.error(error.response.data);
      });
  }, []);

  useEffect(() => {
    if (userSession.twilioToken !== null) {
      // console.log("token A", userSession.twilioToken);
      setTwilioToken(userSession.twilioToken);
    } else {
      const payloadForTwilioToken = {
        userId: userSession.phoneNumber + "-" + userSession.userId,
      };

      getTwilioToken(payloadForTwilioToken)
        .then((res) => {
          // console.log("token B", res?.data?.data?.token);
          setTwilioToken(res?.data?.data?.token);
        })
        .catch((error) => {
          message.error(error?.response?.data);
        });
    }
  }, []);
  useEffect(() => {
    if (twilioToken) {
      initTwilio();
    }
    return () => {
      twilio?.current?.shutdown();
    };
  }, [twilioToken]);

  const initTwilio = async () => {
    if (twilio.current || !userSession?.userId) return;

    setLoadingConversations(true);
    if (!twilioToken) {
      // auth.signOut(); PeopleComponent should be turned into a functional component so we can use Firebase hooks
      // this.props.router.replace("/login");
      return;
    }

    try {
      twilio.current = new ConversationsClient(twilioToken);
    } catch (err) {
      if (err?.message?.includes("Access Token expired")) {
        // firebase.auth().signOut();TODO:ref
      }
      return;
    }
    const client = twilio.current;
    client?.on("tokenAboutToExpire", async () => {
      const payloadForTwilioToken = {
        userId: userSession.phoneNumber + "-" + userSession.userId,
      };

      getTwilioToken(payloadForTwilioToken)
        .then((res) => {
          client?.updateToken(res?.data?.data?.token);
        })
        .catch((error) => {
          message.error(error?.response?.data);
        });
    });

    client?.on("tokenExpired", async () => {
      const payloadForTwilioToken = {
        userId: userSession.phoneNumber + "-" + userSession.userId,
      };

      getTwilioToken(payloadForTwilioToken)
        .then((res) => {
          client?.updateToken(res?.data?.data?.token);
        })
        .catch((error) => {
          message.error(error?.response?.data);
        });
    });
    client?.on("connectionStateChanged", (state) => {
      if (state === "connecting") setConnectionStatus("connecting");

      if (state === "connected") {
        // console.log("connectionStatus", connectionStatus);
        setConnectionStatus("connected");
      }

      if (state === "disconnecting") setConnectionStatus("disconnecting");

      if (state === "disconnected") setConnectionStatus("disconnected");

      if (state === "denied") setConnectionStatus("denied");
    });
    client?.on("conversationJoined", (thisConversation) => {
      // console.log("ChatUI conversationJoined", thisConversation);
      if (!conversations.find((c) => c.sid === thisConversation.sid)) {
        checkIfGroupImageUpdated(thisConversation);
        setConversations((prevConversations) =>
          sortConversations([...prevConversations, thisConversation])
        );
      }
    });

    client?.on("conversationLeft", (thisConversation) => {
      // console.log("conversationLeft", thisConversation);
      setConversations((prevConversations) =>
        conversations.filter((c) => c.sid !== thisConversation.sid)
      );
    });

    client?.on("conversationUpdated", ({ conversation, updateReasons }) => {
      // Fired when the attributes or the metadata of a conversation have been updated
      // console.log("ChatUI conversationUpdated", updateReasons);
      // console.log("ChatUI conversationUpdated", conversation);
      if (updateReasons.includes("dateUpdated")) {
        checkIfGroupImageUpdated(conversation);
        setActiveConversationSid(conversation.sid);
      }
      if (
        updateReasons.includes("lastReadMessageIndex") ||
        updateReasons.includes("lastMessage")
      ) {
        // console.log("oldconversations", conversations);
        client?.getSubscribedConversations().then((res) => {
          const lastestConversations = res.items;

          //Find index of specific object using findIndex method.
          const objIndex = lastestConversations?.findIndex(
            (obj) => obj.sid == conversation.sid
          );
          if (objIndex >= 0) {
            lastestConversations[objIndex] = conversation;
            // setConversations(lastestConversations);
            setConversations(sortConversations(lastestConversations));
          }
        });
      }
    });

    client?.on("participantUpdated", ({ participant, updateReasons }) => {
      // Fired when the fields of the participant have been updated.
      // console.log("Participant updateReasons", updateReasons);
    });

    // listen to user reachability status updates
    client?.on("userUpdated", ({ user, updateReasons }) => {
      // console.log("ChatUI userUpdated", updateReasons);
      setLoadingConversations(false);
      if (updateReasons.includes("reachabilityOnline")) {
        // user reachability status was updated
        // console.log("==reachabilityOnline");

        client?.getSubscribedConversations().then((res) => {
          const lastestConversations = res.items;
          setConversations(sortConversations([...lastestConversations]));
        });

        if (user.identity != userSession.userId && user.isOnline) {
          // console.log("==user online");
          // setIsOnline(true);
        } else {
          // setIsOnline(false);
        }
      }

      if (updateReasons.includes("reachabilityNotifiable")) {
        // user notifications status was updated
      }
    });
    // Check the state variable and create chat
    if (
      state?.playbookData != null &&
      state?.participantsList != null &&
      state?.chatName != null &&
      state?.defaultMessage != null &&
      isChatStateUsed === false
    ) {
      setIsChatStateUsed(true);
      createChatConversation(
        state?.playbookData,
        state?.participantsList,
        state?.chatName,
        state?.defaultMessage
      );
    }
  };

  const sortConversations = (unsortedConversations) => {
    const sortedConversations = [...unsortedConversations];
    sortedConversations.sort((b, a) => {
      if (
        a?.channelState?.lastMessage?.dateCreated != null &&
        b?.channelState?.lastMessage?.dateCreated != null
      ) {
        return (
          a?.channelState?.lastMessage?.dateCreated -
          b?.channelState?.lastMessage?.dateCreated
        );
      } else {
        return a?.channelState?.dateUpdated - b?.channelState?.dateUpdated;
      }
    });
    return sortedConversations;
  };

  const checkIfGroupImageUpdated = (newConversation) => {
    if (newConversation?.channelState?.attributes?.group_image_url === "") {
      newConversation?.getParticipants()?.then((participants) => {
        participants?.forEach((p) => {
          if (p?.state?.attributes?.image_uri != null) {
            const payloadForConvAttributes = {
              conversationId: newConversation.sid,
              attributes: {
                group_image_url: p?.state?.attributes?.image_uri,
              },
            };
            updateConversationAttributes(payloadForConvAttributes)
              .then((attrRes) => {
                return;
              })
              .catch((attrErr) => {});
          }
        });
      });
    }
  };

  const getLastMessage = (index, messagesByIndex) => {
    let result = "New Chat";
    if (messagesByIndex?.size > 0) {
      result = checkNameCharactersLimit(
        messagesByIndex?.get(index)?.state?.body,
        25
      );
    }
    return result;
  };

  const handleCreateChatButtonClick = () => {
    if (createChatModalVisible === false) {
      setCreateChatModalVisible(true);
    }
  };

  const createChatConversation = async (
    details,
    participantsList,
    chatName,
    defaultMessage
  ) => {
    const playbookDetails = details;

    setCreatingConversation(true);
    groupChatImage = playbookDetails?.seller?.image_uri;
    let uniqueName =
      chatName + "_" + userSession.userId + "-" + playbookDetails?.id;
    axios
      .post(
        `https://conversations.twilio.com/v1/Conversations`,
        new URLSearchParams({
          FriendlyName: chatName,
          UniqueName: uniqueName,
        }),
        {
          headers: {
            Authorization: `Basic ${token}`,
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        let channelSid = res?.data?.sid;
        addChatParticipants(
          channelSid,
          playbookDetails,
          participantsList,
          defaultMessage
        );
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        } else {
          message.error(error?.message);
        }
        setCreatingConversation(false);
      });
  };

  const addChatParticipants = (
    sid,
    details,
    participantsList,
    defaultMessage
  ) => {
    const playbookDetails = details;
    participantsList?.forEach((resultUser) => {
      axios
        .post(
          `https://conversations.twilio.com/v1/Conversations/${sid}/Participants`,
          new URLSearchParams({
            Identity: resultUser.phone_number + "_" + resultUser.id,
            Attributes: JSON.stringify(resultUser),
          }),
          {
            headers: {
              Authorization: `Basic ${token}`,
              "Content-type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          const obj = JSON.parse(res?.data?.attributes);

          // setCreatingConversation(false);
          // setRandomUserImageUri(obj?.image_uri);
        })
        .catch((err) => {
          setCreatingConversation(false);
        });
    });

    addSMSParticipants(sid, participantsList, playbookDetails, defaultMessage);
    // updateConvAttributes(sid);
  };

  const updateConvAttributes = (cId, details, defaultMessage) => {
    const playbookDetails = details;
    const payloadForConvAttributes = {
      conversationId: cId,
      attributes: {
        group_image_url: groupChatImage,
      },
    };
    updateConversationAttributes(payloadForConvAttributes)
      .then((attrRes) => {
        setActiveConversationSid(cId);
        sendFirstMessageFromSeller(cId, details, defaultMessage);
        window.history.replaceState({}, document.title);
        let sName =
          playbookDetails?.seller?.first_name +
          " " +
          playbookDetails?.seller?.last_name;
        const eventData = {
          sellername: sName,
          selleremail: playbookDetails?.seller?.email,
          sellerphone: playbookDetails?.seller?.phone_number,
          buyername: userSession.firstName + " " + userSession.lastName,
          buyerphone: userSession.phoneNumber,
          buyeremail: userSession.email,
        };
        const segmentEventData = {
          sellername: sName,
          selleremail: playbookDetails?.seller?.email,
          sellerphone: playbookDetails?.seller?.phone_number,
          buyername: userSession.firstName + " " + userSession.lastName,
          buyerphone: userSession.phoneNumber,
          buyeremail: userSession.email,
          price: playbookDetails?.min_price,
        };
        const mergedJson1 = {
          ...eventData,
          ...externalLinkSegmentParams,
        };
        const mergedJson2 = {
          ...segmentEventData,
          ...externalLinkSegmentParams,
        };
        trackEventCall(ANALYTICS_EVENT_NAMES.MEETING_SCHEDULED, mergedJson1);
        trackEventCall(ANALYTICS_EVENT_NAMES.REVENUE_GENERATED, mergedJson2);
        setCreatingConversation(false);
      })
      .catch((attrErr) => {
        setCreatingConversation(false);
      });
  };

  const addSMSParticipants = (
    sid,
    resultUsersArray,
    details,
    defaultMessage
  ) => {
    const playbookDetails = details;
    let twilioPhoneNumber = randomizeData.randomItem();
    resultUsersArray?.forEach((resultUser, i) => {
      axios
        .post(
          `https://conversations.twilio.com/v1/Conversations/${sid}/Participants`,
          new URLSearchParams({
            "MessagingBinding.Address": resultUser.phone_number,
            "MessagingBinding.ProxyAddress": twilioPhoneNumber,
            Attributes: JSON.stringify(resultUser),
          }),
          {
            headers: {
              Authorization: `Basic ${token}`,
              "Content-type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          if (i === 1) {
            updateConvAttributes(sid, playbookDetails, defaultMessage);
          }
          // setCreatingConversation(false);
        })
        .catch((error) => {
          if (error?.response?.data?.code === 50416) {
            // Twilio error code 50416 means A binding for this participant and proxy address already exists in some Conversation
            addSMSParticipantsWithDifferentNumber(sid, resultUser);
          } else if (error?.response?.data?.message) {
            message.error(error.response.data.message);
          } else {
            message.error(error?.message);
          }
        });
    });
  };

  const addSMSParticipantsWithDifferentNumber = (sid, resultUser) => {
    let twilioPhoneNumber = randomizeData.randomItem();
    axios
      .post(
        `https://conversations.twilio.com/v1/Conversations/${sid}/Participants`,
        new URLSearchParams({
          "MessagingBinding.Address": resultUser.phone_number,
          "MessagingBinding.ProxyAddress": twilioPhoneNumber,
          Attributes: JSON.stringify(resultUser),
        }),
        {
          headers: {
            Authorization: `Basic ${token}`,
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        // setCreatingConversation(false);
      })
      .catch((error) => {
        if (error?.response?.data?.code === 50416) {
          // Twilio error code 50416 means A binding for this participant and proxy address already exists in some Conversation
          addSMSParticipants(sid, resultUser);
        } else if (error?.response?.data?.message) {
          message.error(error.response.data.message);
        } else {
          message.error(error?.message);
        }
      });
  };

  const sendFirstMessageFromSeller = (
    convSid,
    playbookData,
    defaultMessage
  ) => {
    const attributesObj = {
      id: playbookData?.seller?.seller_id,
      first_name: playbookData?.seller?.first_name,
      last_name: playbookData?.seller?.last_name,
      phone_number: playbookData?.seller?.phone_number,
      image_uri: playbookData?.seller?.image_uri,
    };

    axios
      .post(
        `https://conversations.twilio.com/v1/Conversations/${convSid}/Messages`,
        new URLSearchParams({
          Author: playbookData?.seller?.phone_number,
          Body: defaultMessage,
          Attributes: JSON.stringify(attributesObj),
        }),
        {
          headers: {
            Authorization: `Basic ${token}`,
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        const obj = JSON.parse(res?.data?.attributes);
      })
      .catch((err) => {});
  };

  const parseChatName = (chatName) => {
    let result = chatName;
    result = checkNameCharactersLimit(chatName, 60);
    return result;
  };
  const onMobileBackClicked = () => {
    setActiveConversationSid("");
    emitCustomEvent("hideHeader_footer_forMobileChat", false);
  };
  return (
    <>
      {isMobileView === true ? (
        <div className="chatMainPage">
          {activeConversationSid ? (
            <div className="chatMainPage__chatUIDivMobile">
              <ChatUI
                conversation={conversations.find(
                  (c) => c.sid === activeConversationSid
                )}
                ourAppUsers={appUsers}
                onBackClicked={onMobileBackClicked}
                chatQuestionOptions={
                  proposalMessagesData?.default_messages?.options
                }
              />
            </div>
          ) : (
            <div className="chatMainPage__mobileContainer">
              <div className="chatMainPage__conversationCellsListMobile">
                {loadingConversations ? (
                  <Flex
                    flex={1}
                    justify="center"
                    align="center"
                    style={{ width: "100%", height: 500 }}
                    direction="column"
                  >
                    <Spin size="default" />
                    <div> Loading Conversations...</div>
                  </Flex>
                ) : (
                  <List
                    dataSource={conversations}
                    itemLayout="vertical"
                    rowKey={"key"}
                    renderItem={(conversation, i) => {
                      const isActive =
                        activeConversationSid === conversation.sid;
                      return (
                        <div
                          className="chatMainPage__conversationCell"
                          id={conversation.sid}
                          key={conversation.sid + i}
                          onClick={() => {
                            setActiveConversationSid(conversation.sid);
                            // onChatItemClicked(conversation.sid);
                          }}
                        >
                          <Avatar
                            url={
                              conversation?.channelState?.attributes
                                ?.group_image_url
                            }
                            currentConversation={conversation}
                            refresh={Math.random()}
                          />
                          <div className="chatMainPage__nameAndLastMsgDiv">
                            <Typography.Text
                              className={classnames(
                                "chatMainPage__conversationName",
                                isActive &&
                                  "chatMainPage__conversationNameActive"
                              )}
                            >
                              {/* {conversation?.user?.fullName} */}

                              {parseChatName(conversation.friendlyName) ||
                                "No Name"}
                            </Typography.Text>
                            {!isActive && (
                              <Typography.Text className="chatMainPage__conversationLastMessage">
                                {/* {conversation?.channelState?.lastMessage?.index} */}
                                {getLastMessage(
                                  conversation?.channelState?.lastMessage
                                    ?.index,
                                  conversation?.messagesEntity?.conversation
                                    ?.messagesEntity?.messagesByIndex
                                )}
                                {/* New Group Chat */}
                              </Typography.Text>
                            )}
                          </div>
                          <UnReadMessageCount
                            conversation={conversation}
                            refresh={Math.random()}
                          ></UnReadMessageCount>
                        </div>
                      );
                    }}
                  ></List>
                )}
              </div>
              <div className="chatMainPage__iconCreateChatMobile">
                <Image
                  preview={false}
                  src={IconNewChatBlue}
                  onClick={handleCreateChatButtonClick}
                ></Image>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="chatMainPage">
          <div className="chatMainPage__leftdiv">
            <div className="chatMainPage__headerDiv">
              <Typography.Text className="chatMainPage__headerText">
                Conversations
              </Typography.Text>
              {/* <div className="chatMainPage__searchDotsDiv">
                <Image
                  className="chatMainPage__imgSearch"
                  preview={false}
                  src={IconSearch}
                ></Image>
                <Image
                  className="chatMainPage__imgMenu"
                  src={IconThreeDots}
                  preview={false}
                ></Image>
              </div> */}
            </div>
            <div className="chatMainPage__chatUIDiv">
              {activeConversationSid ? (
                <ChatUI
                  conversation={conversations.find(
                    (c) => c.sid === activeConversationSid
                  )}
                  ourAppUsers={appUsers}
                  chatQuestionOptions={
                    proposalMessagesData?.default_messages?.options
                  }
                />
              ) : (
                <Flex
                  flex={1}
                  justify="center"
                  align="center"
                  style={{ width: "100%" }}
                >
                  <span style={{ fontSize: 14, fontWeight: "bold" }}>
                    Open a conversation to start chat
                  </span>
                </Flex>
              )}
            </div>
          </div>
          <div className="chatMainPage__rightdiv">
            <div className="chatMainPage__rightDivCreateChat">
              {/* <Popover
                overlayInnerStyle={{ borderRadius: 4 }}
                content={createConversationMenu}
                trigger="click"
                placement="bottomLeft"
                visible={visible}
                onVisibleChange={handleVisibleChange}
              > */}
              <Tooltip
                placement="bottomLeft"
                title="Create a direct chat or group chat"
              >
                <div className="chatMainPage__newConversationButton">
                  <img
                    src={IconNewChat}
                    style={{ marginLeft: 18 }}
                    onClick={handleCreateChatButtonClick}
                  />
                </div>
              </Tooltip>

              {/* </Popover> */}

              {/* <SettingOutlined className="chatMainPage__chatSettingIcon" /> */}
            </div>
            <div className="chatMainPage__conversationCellsList">
              {loadingConversations ? (
                <Flex
                  flex={1}
                  justify="center"
                  align="center"
                  style={{ width: "100%", height: 500 }}
                  direction="column"
                >
                  <Spin size="default" />
                  <div> Loading Conversations...</div>
                </Flex>
              ) : (
                <List
                  dataSource={conversations}
                  itemLayout="vertical"
                  rowKey={"key"}
                  renderItem={(conversation, i) => {
                    const isActive = activeConversationSid === conversation.sid;
                    return (
                      <div
                        className={classnames(
                          "chatMainPage__conversationCell",
                          isActive && "chatMainPage__conversationCellActive"
                        )}
                        id={conversation.sid}
                        key={conversation.sid + i}
                        onClick={() => {
                          setActiveConversationSid(conversation.sid);
                        }}
                      >
                        <Avatar
                          url={
                            conversation?.channelState?.attributes
                              ?.group_image_url
                          }
                          currentConversation={conversation}
                          refresh={Math.random()}
                        />
                        <div className="chatMainPage__nameAndLastMsgDiv">
                          <Typography.Text
                            className={classnames(
                              "chatMainPage__conversationName",
                              isActive && "chatMainPage__conversationNameActive"
                            )}
                          >
                            {/* {conversation?.user?.fullName} */}

                            {parseChatName(conversation.friendlyName) ||
                              "No Name"}
                          </Typography.Text>
                          {!isActive && (
                            <Typography.Text className="chatMainPage__conversationLastMessage">
                              {/* {conversation?.channelState?.lastMessage?.index} */}
                              {getLastMessage(
                                conversation?.channelState?.lastMessage?.index,
                                conversation?.messagesEntity?.conversation
                                  ?.messagesEntity?.messagesByIndex
                              )}
                              {/* New Group Chat */}
                            </Typography.Text>
                          )}
                        </div>
                        <UnReadMessageCount
                          conversation={conversation}
                          refresh={Math.random()}
                        ></UnReadMessageCount>
                      </div>
                    );
                  }}
                ></List>
              )}
            </div>
          </div>
        </div>
      )}

      {createChatModalVisible && (
        <CreateChatPopup
          selectedOption={selectedMessageOption}
          visible={createChatModalVisible}
          onCancel={() => setCreateChatModalVisible(false)}
          setConversationName={(name) => {
            latestConversationName = name;
          }}
        />
      )}
    </>
  );
}

export default Chat;
