import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { Image } from "antd";
import { Sources, BackKey, Destination, Dataset } from "images";
import "./index.scss";

function NewHeader() {
  const location = useLocation();
  const navigate = useNavigate();

  const getHeaderConfig = () => {
    const { pathname } = location;
    const dashboardPath = APP_ROUTES.DASHBOARD.path;
    console.log(pathname, APP_ROUTES.DASHBOARD.path)

    switch (true) {
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/sources/catalogList`) ||
        pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/destination/catalogList`):
        return { text: "Catalog", icon: "📦", backNavigation: true };
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/connector/overview`) ||
        pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/destination/overview`):
        const overviewUrlParts = pathname.split('/');
        const overviewUrlName = decodeURIComponent(overviewUrlParts[7]); // "Final Message Media" is at index 7
        return { text: overviewUrlName, icon: "📊", backNavigation: true };
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/settings`):
        const settingUrlParts = pathname.split('/');
        const settingUrlName = decodeURIComponent(settingUrlParts[6]); // "Final Message Media" is at index 7
        return { text: settingUrlName, icon: "📊", backNavigation: true };
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/sources/tasks/`):
        const taskUrlParts = pathname.split('/');
        const taskUrlName = decodeURIComponent(taskUrlParts[7]); // "Final Message Media" is at index 7
        return { text: taskUrlName, icon: "📊", backNavigation: true };
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/model/creation`):
        const modelUrlParts = pathname.split('/');
        const modelUrlName = decodeURIComponent(modelUrlParts[7]); // "Final Message Media" is at index 7
        return { text: modelUrlName, icon: "📊", backNavigation: true }
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/sources/leadsapi/`) ||
        pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/destination/leadsapi/`):
        return { text: "Leads API", icon: "📊", backNavigation: true };
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/sources/messagemedia/`):
        return { text: "Message Media", icon: "📊", backNavigation: true };
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/sources/setup/`) ||
        pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/destination/setup/`):
        return { text: "Connection Setup", icon: "📊", backNavigation: true };
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/sources/googleads/`):
        return { text: "Google Ads", icon: "📊", backNavigation: true };
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/sources/postgres/`):
        return { text: "Postgres", icon: "📊", backNavigation: true };
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/sources/facebookads/`):
        return { text: "Facebook Ads", icon: "📊", backNavigation: true };
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/sources/csv`):
        return { text: "CSV", icon: "📊", backNavigation: true };
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/sources/zendesk/`):
        return { text: "Zendesk", icon: "📊", backNavigation: true };
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/sources/googledrive/`):
        return { text: "Google Drive", icon: "📊", backNavigation: true };
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/sources/salesforcemarketingcloud/`) ||
        pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/destination/salesforcemarketingcloud/`):
        return { text: "Salesforce Marketing Cloud", icon: "📊", backNavigation: true };

      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/sources`):
        return { text: "Sources", icon: <img src={Sources} />, backNavigation: false };
      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/destinations`):
        return { text: "Destinations", icon: <img src={Destination} />, backNavigation: false };

      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/datasets/steps`):
        return { text: "Dataset", icon: "🏠", backNavigation: true };

      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/datasets`) ||
        pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/companies`) ||
        pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/people`) ||
        pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/places`) ||
        pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/datasets/events`):
        return { text: "Datasets", icon: <img src={Dataset} />, backNavigation: false };

      case pathname.startsWith(`${APP_ROUTES.DASHBOARD.path}/settings`):
        return { text: "Settings", icon: "📊", backNavigation: true };

      default:
        return { text: "Dashboard", icon: "🏠", backNavigation: false };
    }
  };

  const { text, icon, backNavigation } = getHeaderConfig();

  return (
<div className="newHeader">
  {backNavigation ? (
    <div className="newHeader__icon clickable" onClick={() => navigate(-1)}>
      <Image src={BackKey} preview={false} />
    </div>
  ) : (
    <div className="newHeader__icon">{icon}</div>
  )}
  <div className="newHeader__text">{text}</div>
</div>
  );
}

export default NewHeader;