import { Image } from "antd";
import { useEffect, useState } from "react";
import {
  IconAmericanBestAgent,
  IconFedilityAmerican,
  IconHomeOfferCalculator,
  IconHomeValueEstimator,
  IconPalisade,
  IconPrivhomes,
  IconProgressiveAmerican,
  IconPrudentialHomes,
} from "../../images";

function AppLogo() {
  const [currentDomainIcon, setCurrentDomainIcon] = useState(IconPalisade);

  useEffect(() => {
    let currentDomain = window.location.host;
    if (currentDomain === "app.privhomes.com") {
      setCurrentDomainIcon(IconPrivhomes);
    } else if (currentDomain === "app.fidelityamericanhomes.com") {
      setCurrentDomainIcon(IconFedilityAmerican);
    } else if (currentDomain === "app.palisade.ai") {
      setCurrentDomainIcon(IconPalisade);
    } else if (currentDomain === "app.progressiveamericanproperties.com") {
      setCurrentDomainIcon(IconProgressiveAmerican);
    } else if (currentDomain === "app.prudentialhomesofamerica.com") {
      setCurrentDomainIcon(IconPrudentialHomes);
    } else if (currentDomain === "app.myaihomevalueestimator.com") {
      setCurrentDomainIcon(IconHomeValueEstimator);
    } else if (currentDomain === "app.homeoffercalculator.com") {
      setCurrentDomainIcon(IconHomeOfferCalculator);
    } else if (currentDomain === "app.americanbestagents.com") {
      setCurrentDomainIcon(IconAmericanBestAgent);
    } else if (currentDomain === "localhost:3000") {
      // For development purpose
      setCurrentDomainIcon(IconPalisade);
    }
  }, []);

  return <Image preview={false} src={currentDomainIcon} />;
}
export default AppLogo;
