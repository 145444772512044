import { Alert, Button, Image, List, message, Tooltip, Spin } from "antd";
import { deleteFile, getFiles, createCsvMapping } from "api/FileOnboard";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedWorkspaceProfileId } from "utils";
import IconProcessed from "../../../../../images/fon_processed.svg";
import IconValidationFailed from "../../../../../images/fon_validation_failed.svg";
import IconTooltip from "../../../../../images/tooltip.svg";
import {
  setCurrentProcessingFile,
  setCurrentProcessingGPTDetails,
} from "../../../../../store/modules/fileOnboard/action";
import "./index.scss";
import { useNavigate } from "react-router-dom";

export const FON_STAGE = {
  UNPROCESSED: "Unprocessed",
  CONTINUE_BUTTON: "Continue Button",
  PROCESSING: "Onboarding Started",
  READY_TO_PROCESS: "Ready to Process",
  PROCESSED: "ONBOARDING COMPLETED",
  VALIDATION_FAILED: "Validation Failed",
  UPLOAD_FAILED: "Upload Failed",
  UPLOAD_DUPLICATE: "Duplicate",
  ONBOARDING_INPROGRESS: "ONBOARDING IN-PROGRESS",
  IDENTITIES_GENERATED: "IDENTITIES GENERATED",
};
export const FON_STAGE_DESCRIPTION = {
  [FON_STAGE.UNPROCESSED]: "",
  [FON_STAGE.CONTINUE_BUTTON]: "",
  [FON_STAGE.PROCESSING]:
    "We are validating the data and adding to your knowledge graph.",
  [FON_STAGE.READY_TO_PROCESS]:
    "We are validating the data and adding to your knowledge graph.",
  [FON_STAGE.PROCESSED]:
    "We have validated the data and it is now searchable in the 'Datasets' section.",
  [FON_STAGE.VALIDATION_FAILED]:
    "The data has not passed the system checks. Kindly review the error message.",
  [FON_STAGE.UPLOAD_FAILED]:
    "The data has not passed the system checks. Kindly review the error message.",
  [FON_STAGE.UPLOAD_DUPLICATE]:
    "This is a duplicate file, we won't process this file.",
};
export const FON_STAGE_ICON = {
  [FON_STAGE.UNPROCESSED]: null,
  [FON_STAGE.CONTINUE_BUTTON]: null,
  [FON_STAGE.PROCESSING]: IconProcessed,
  [FON_STAGE.READY_TO_PROCESS]: IconProcessed,
  [FON_STAGE.PROCESSED]: IconProcessed,
  [FON_STAGE.ONBOARDING_INPROGRESS]: IconProcessed,
  [FON_STAGE.IDENTITIES_GENERATED]: IconProcessed,
  [FON_STAGE.VALIDATION_FAILED]: IconValidationFailed,
  [FON_STAGE.UPLOAD_FAILED]: IconValidationFailed,
  [FON_STAGE.UPLOAD_DUPLICATE]: IconProcessed,
};

/*
- File onboarding flow component used to render Files with their statuses
- onClickAddSource will be invoked when user selected a file for processing
- Redux dispatch for current processing file is hanlded in this component
*/
function UnprocessedFiles({ onClickAddSource }) {
  const [readyForStep2List, setReadyForStep2List] = useState([]);
  const [uploadCompleteList, setUploadCompleteList] = useState([]);
  const [validationFailedList, setValidationFailedList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(
    "Loading files, please wait..."
  );
  const [reFetchFileDetails, setReFetchFileDetails] = useState(true);
  const dispatch = useDispatch();
  const newFileUploaded = useSelector(
    (state) => state.fileOnboardReducer
  ).newFileUploaded;
  const userSession = useSelector((state) => state.authReducer).userSession;
  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      user_id: userSession.userId,
      profileworkspaceId: getSelectedWorkspaceProfileId(userSession),
    };
    if (newFileUploaded) {
      setLoadingText("Validating file(s), please wait...");
    } else {
      setLoadingText("Loading files, please wait...");
    }
    setLoading(true);
    const isFileUploadedRecently =
      new Date().getTime() - newFileUploaded < 3000; //Within 3 sec
    setTimeout(
      () => {
        getFiles(payload)
          .then((res) => {
            const files = res.data.data.response || [];
            const readyForStep2Files = [];
            const uploadCompleteFiles = [];
            const validationFailedFiles = [];

            files.forEach((file) => {
              switch (file.status) {
                case FON_STAGE.UNPROCESSED:
                  readyForStep2Files.push(file);
                  break;
                case FON_STAGE.READY_TO_PROCESS:
                case FON_STAGE.ONBOARDING_INPROGRESS:
                case FON_STAGE.PROCESSING:
                case FON_STAGE.UPLOAD_DUPLICATE:
                case FON_STAGE.IDENTITIES_GENERATED:
                case FON_STAGE.PROCESSED:
                  uploadCompleteFiles.push(file);
                  break;
                case FON_STAGE.VALIDATION_FAILED:
                case FON_STAGE.UPLOAD_FAILED:
                  validationFailedFiles.push(file);
                  break;
                default:
                  break;
              }
            });

            const continueButton = {
              file_id: 0,
              filename: "",
              status: FON_STAGE.CONTINUE_BUTTON,
              stage: "",
              s3_url: "",
              error: null,
              reason: null,
              created: "2024-02-01T09:32:44.278Z",
              last_modified: "2024-02-01T09:32:44.278Z",
            };

            if (readyForStep2Files.length > 0) {
              readyForStep2Files.push(continueButton);
            }
            setReadyForStep2List(readyForStep2Files);
            setUploadCompleteList(uploadCompleteFiles);
            setValidationFailedList(validationFailedFiles);
          })
          .catch((err) => {
            message.error(err.message);
          })
          .finally(() => {
            setLoading(false);
            setLoadingText("Loading files, please wait...");
          });
      },
      isFileUploadedRecently ? 3000 : 0 //Give time for recent file to be available in db before fetching the list
    );
  }, [newFileUploaded, reFetchFileDetails]);

  const onDeleteFile = (file, fileIndex) => {
    setLoadingText("Deleting file, please wait...");
    setLoading(true);
    const payload = { file_id: file.file_id };

    deleteFile(payload)
      .then(() => {
        const updatedFilesList = [...readyForStep2List];
        updatedFilesList.splice(fileIndex, 1);
        setReadyForStep2List(updatedFilesList);
        setReFetchFileDetails(!reFetchFileDetails);
        setLoadingText("Loading files, please wait...");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getSectionIndexes = () => {
    const firstSectionStartIndex = 0;
    const secondSectionStartIndex =
      firstSectionStartIndex + readyForStep2List.length;
    const thirdSectionStartIndex =
      secondSectionStartIndex + uploadCompleteList.length;
    const firstSectionLastIndex = readyForStep2List.length - 1;
    const secondSectionLastIndex =
      readyForStep2List.length + uploadCompleteList.length - 1;
    const thirdSectionLastIndex =
      readyForStep2List.length +
      uploadCompleteList.length +
      validationFailedList.length -
      1;
    return {
      firstSectionStartIndex,
      firstSectionLastIndex,
      secondSectionStartIndex,
      secondSectionLastIndex,
      thirdSectionStartIndex,
      thirdSectionLastIndex,
    };
  };

  const getListHeader = (index) => {
    let headerTitle = "";
    let filesCount = 0;
    const sectionIndexes = getSectionIndexes();
    if (
      index === sectionIndexes.firstSectionStartIndex &&
      readyForStep2List.length > 0
    ) {
      headerTitle = "Un Processed Files";
      filesCount = readyForStep2List.length - 1;
    } else if (
      index === sectionIndexes.secondSectionStartIndex &&
      uploadCompleteList.length > 0
    ) {
      headerTitle = "Upload Complete";
      filesCount = uploadCompleteList.length;
    } else if (
      index === sectionIndexes.thirdSectionStartIndex &&
      validationFailedList.length > 0
    ) {
      headerTitle = "Validation Failed";
      filesCount = validationFailedList.length;
    }
    if (isEmpty(headerTitle)) {
      return null;
    }
    return (
      <div
        className={classNames("unprocessedFiles__header", {
          "unprocessedFiles__header--first": index === 0,
        })}
      >
        <span className="unprocessedFiles__header__title">
          {headerTitle}
          <span className="unprocessedFiles__header__count">
            ({filesCount})
          </span>
        </span>
        <span className="unprocessedFiles__header__status">Status</span>
      </div>
    );
  };
  const getListRowIcon = (item) => {
    switch (item.status) {
      case FON_STAGE.UNPROCESSED:
        return <div className="unprocessedFiles__list__row__icon__step">1</div>;
      case FON_STAGE.READY_TO_PROCESS:
      case FON_STAGE.PROCESSING:
      case FON_STAGE.PROCESSED:
      case FON_STAGE.ONBOARDING_INPROGRESS:
      case FON_STAGE.IDENTITIES_GENERATED:
      case FON_STAGE.UPLOAD_DUPLICATE:
      case FON_STAGE.VALIDATION_FAILED:
      case FON_STAGE.UPLOAD_FAILED:
        return (
          <div className="unprocessedFiles__list__row__icon">
            <Image src={FON_STAGE_ICON[item.status]} preview={false} />
          </div>
        );
      default:
        return null;
    }
  };
  const getListRowFileName = (item) => {
    return (
      <p className="unprocessedFiles__list__row__leftContent__fileName">
        {/* added this check for Continue button beneath list 1 */}
        {item.status !== FON_STAGE.CONTINUE_BUTTON ? item.display_name : ""}
      </p>
    );
  };

  const continueButtonClicked = (readyForStep2List) => {
    setLoadingText("Validating file(s) schema, please wait...");
    setLoading(true);

    let fileIds = readyForStep2List
      .filter((fileInfo) => fileInfo.file_id !== 0)
      .map((fileInfo) => fileInfo.file_id);

    const payload = { files: fileIds };

    createCsvMapping(payload)
      .then((res) => {
        if (res.data.error) {
          // message.error(res.data.message);
        } else {
          message.success(res.data.message);
          if (res.data.message) {
            dispatch(setCurrentProcessingGPTDetails(res.data.data));
            setReFetchFileDetails(!reFetchFileDetails);
            // navigate(`/dashboard/datasets/sources`);
            // onClickAddSource();
          }
        }
      })
      .catch((err) => {
        message.error(err.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getListRowFileDescription = (item, index) => {
    switch (item.status) {
      case FON_STAGE.UNPROCESSED:
        return (
          <p className="unprocessedFiles__list__row__leftContent__description">
            {/* Please click
            <span
              onClick={() => {
                dispatch(setCurrentProcessingFile(item));
                //onClickAddSource();
              }}
              className="unprocessedFiles__list__row__leftContent__description__blueLink"
            >
              here
            </span> */}
            Please click
            <span
              onClick={() => {
                onDeleteFile(item, index);
              }}
              className="unprocessedFiles__list__row__leftContent__description__orangeLink"
            >
              here
            </span>
            to cancel upload.
          </p>
        );
      case FON_STAGE.READY_TO_PROCESS:
      case FON_STAGE.PROCESSING:
      case FON_STAGE.PROCESSED:
      case FON_STAGE.ONBOARDING_INPROGRESS:
      case FON_STAGE.IDENTITIES_GENERATED:
      case FON_STAGE.UPLOAD_DUPLICATE:
      case FON_STAGE.VALIDATION_FAILED:
      case FON_STAGE.UPLOAD_FAILED:
        return (
          <p className="unprocessedFiles__list__row__leftContent__description">
            {FON_STAGE_DESCRIPTION[item.status]}
          </p>
        );
      default:
        return null;
    }
  };
  const getListRowStatus = (item) => {
    switch (item.status) {
      case FON_STAGE.UNPROCESSED:
        return <div className="unprocessedFiles__list__row__status"></div>;
      case FON_STAGE.CONTINUE_BUTTON:
        return (
          <div className="unprocessedFiles__list__continueRow">
            <Button
              className="unprocessedFiles__list__continueRow__button"
              onClick={() => {
                continueButtonClicked(readyForStep2List);
              }}
            >
              Continue
            </Button>
          </div>
        );
      case FON_STAGE.READY_TO_PROCESS:
      case FON_STAGE.PROCESSING:
        return (
          <div className="unprocessedFiles__list__row__status">
            <span className="unprocessedFiles__list__row__status__text">
              <Tooltip title="This process takes ~40 minutes">
                Validation initiated
                <Image
                  wrapperClassName="unprocessedFiles__list__row__status__text__tooltip"
                  src={IconTooltip}
                  preview={false}
                />
              </Tooltip>
            </span>
          </div>
        );
      case FON_STAGE.IDENTITIES_GENERATED:
      case FON_STAGE.PROCESSED:
        return (
          <div className="unprocessedFiles__list__row__status">
            <span className="unprocessedFiles__list__row__status__text">
              Onboarding complete
            </span>
          </div>
        );
      case FON_STAGE.ONBOARDING_INPROGRESS:
        return (
          <div className="unprocessedFiles__list__row__status">
            <span className="unprocessedFiles__list__row__status__text">
              Onboarding In Progress
            </span>
          </div>
        );
      case FON_STAGE.UPLOAD_DUPLICATE:
        return (
          <div className="unprocessedFiles__list__row__status">
            <span className="unprocessedFiles__list__row__status__text">
              Duplicate file
            </span>
          </div>
        );
      case FON_STAGE.VALIDATION_FAILED:
      case FON_STAGE.UPLOAD_FAILED:
        return (
          <div className="unprocessedFiles__list__row__status">
            <span className="unprocessedFiles__list__row__status__text">
              <span className="unprocessedFiles__list__row__status__text__error">
                {item.error}
              </span>
              <Tooltip title={item.reason}>
                <Image
                  wrapperClassName="unprocessedFiles__list__row__status__text__tooltip"
                  src={IconTooltip}
                  preview={false}
                />
              </Tooltip>
            </span>
          </div>
        );
      default:
        return null;
    }
  };
  const getListRow = (item, index) => {
    const sectionIndexes = getSectionIndexes();
    const isSectionStartIndex = [
      sectionIndexes.firstSectionStartIndex,
      sectionIndexes.secondSectionStartIndex,
      sectionIndexes.thirdSectionStartIndex,
    ].includes(index);
    const isSectionLastIndex = [
      sectionIndexes.firstSectionLastIndex,
      sectionIndexes.secondSectionLastIndex,
      sectionIndexes.thirdSectionLastIndex,
    ].includes(index);

    if (item.status === FON_STAGE.CONTINUE_BUTTON) {
      return (
        <div
          className={classNames(
            "unprocessedFiles__list__continueRow",
            {
              "unprocessedFiles__list__continueRow--roundedTop":
                isSectionStartIndex,
            },
            {
              "unprocessedFiles__list__continueRow--roundedBottom":
                isSectionLastIndex,
            }
          )}
        >
          {getListRowIcon(item)}
          <div className="unprocessedFiles__list__row__leftContent">
            {getListRowFileName(item)}
            {getListRowFileDescription(item, index)}
          </div>
          {getListRowStatus(item)}
        </div>
      );
    } else {
      return (
        <div
          className={classNames(
            "unprocessedFiles__list__row",
            {
              "unprocessedFiles__list__row--roundedTop": isSectionStartIndex,
            },
            {
              "unprocessedFiles__list__row--roundedBottom": isSectionLastIndex,
            }
          )}
        >
          {getListRowIcon(item)}
          <div className="unprocessedFiles__list__row__leftContent">
            {getListRowFileName(item)}
            {getListRowFileDescription(item, index)}
          </div>
          {getListRowStatus(item)}
        </div>
      );
    }
  };

  const dataSource = [
    ...readyForStep2List,
    ...uploadCompleteList,
    ...validationFailedList,
  ];
  return (
    <div>
      <Spin tip={loadingText} spinning={loading}>
        <div className="unprocessedFiles">
          <List
            dataSource={dataSource}
            itemLayout="vertical"
            rowKey={"file_id"}
            className="unprocessedFiles__list"
            renderItem={(item, index) => {
              return (
                <>
                  {getListHeader(index)}
                  {getListRow(item, index)}
                </>
              );
            }}
          />
        </div>
      </Spin>
    </div>
  );
}
export default UnprocessedFiles;
