import { Tabs } from "antd";
import CompaniesSearch from "components/Datasets/CompaniesSearch";
import PeopleSearch from "components/Datasets/PeopleSearch";
import PlacesSearch from "components/Datasets/PlacesSearch";
import EventsSearch from "components/Datasets/EventsSearch";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import DataSetsList from "components/Datasets/NewUI/DataSetsList";

const { TabPane } = Tabs;

function Datasets() {
  const navigate = useNavigate();

  const onTabClick = (key) => {
    navigate(`/dashboard/datasets/${key}`);
  };

  return (
    <>
      <div className="tabs-container">
        <Tabs defaultActiveKey="1" onChange={onTabClick}>
          <TabPane tab="Datasets" key="datasets">
            <DataSetsList></DataSetsList>
          </TabPane>
          <TabPane tab="Companies" key="companies">
            <CompaniesSearch />
          </TabPane>
          <TabPane tab="People" key="people">
            <PeopleSearch />
          </TabPane>
          <TabPane tab="Places" key="places">
            <PlacesSearch />
          </TabPane>
          <TabPane tab="Events" key="events">
            <EventsSearch />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}

export default Datasets;
