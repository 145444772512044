import { trackEventCall, trackPageCall } from "analytics";
import { Button, Form, Image, Space, Typography } from "antd";
import { checkIfPhoneExists } from "api/userManagement";
import axios from "axios";
import { Buffer } from "buffer";
import { UserOnBoardingFooter } from "components/UserOnBoardingFooter";
import { ANALYTICS_EVENT_NAMES } from "../../constants";
import {
  TWILIO_PASSWORD,
  TWILIO_SMS_SID,
  TWILIO_USER_NAME,
} from "environments";
import { Close } from "images";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import AppLogo from "../../components/AppLogo";
import "./index.scss";

function SignupPhoneNumber() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    trackPageCall("OnBoarding Enter Phone");
  }, []);

  const sendVerificationCode = (values) => {
    setShowErrorMessage(false);
    setLoading(true);
    const { phonenumber } = values;
    let prefix = "+";
    state.phoneNumber = prefix.concat(phonenumber);

    const payloadForPhoneCheck = {
      phoneNumber: state.phoneNumber,
    };

    checkIfPhoneExists(payloadForPhoneCheck)
      .then((response) => {
        if (
          response?.data?.data?.no_phone_found === true &&
          response?.data?.message === "Phone number does not exist"
        ) {
          const username = TWILIO_USER_NAME;
          const password = TWILIO_PASSWORD;
          const sms_sid = TWILIO_SMS_SID;

          const token = Buffer.from(`${username}:${password}`, "utf8").toString(
            "base64"
          );
          axios
            .post(
              `https://verify.twilio.com/v2/Services/${sms_sid}/Verifications`,
              new URLSearchParams({
                To: state.phoneNumber,
                Channel: "sms",
              }),
              {
                headers: {
                  Authorization: `Basic ${token}`,
                  "Content-type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then((res) => {
              const eventData = {
                phonenumber: state.phoneNumber,
              };
              trackEventCall(
                ANALYTICS_EVENT_NAMES.ONBOARDING_PHONE_ENTERED,
                eventData
              );
              navigate(APP_ROUTES.SIGNUP_VERIFY_PHONE.path, {
                state,
              });
            })
            .catch((err) => {
              setLoading(false);
              setShowErrorMessage(true);
              setErrorMessage(
                "Please check if the phone number entered is correct."
              );
            });
        } else {
          setShowErrorMessage(true);
          setErrorMessage(response.data.message);
          setLoading(false);
        }
        // setShowErrorMessage(true);
        // setErrorMessage(response.data.message);
        // setLoading(false);
      })
      .catch((error) => {
        // const username = TWILIO_USER_NAME;
        // const password = TWILIO_PASSWORD;
        // const sms_sid = TWILIO_SMS_SID;
        // const token = Buffer.from(`${username}:${password}`, "utf8").toString(
        //   "base64"
        // );
        // axios
        //   .post(
        //     `https://verify.twilio.com/v2/Services/${sms_sid}/Verifications`,
        //     new URLSearchParams({
        //       To: state.phoneNumber,
        //       Channel: "sms",
        //     }),
        //     {
        //       headers: {
        //         Authorization: `Basic ${token}`,
        //         "Content-type": "application/x-www-form-urlencoded",
        //       },
        //     }
        //   )
        //   .then((res) => {
        //     const eventData = {
        //       phonenumber: state.phoneNumber,
        //     };
        //     trackEventCall(
        //       ANALYTICS_EVENT_NAMES.ONBOARDING_PHONE_ENTERED,
        //       eventData
        //     );
        //     navigate(APP_ROUTES.SIGNUP_VERIFY_PHONE.path, {
        //       state,
        //     });
        //   })
        //   .catch((err) => {
        //     setLoading(false);
        //     setShowErrorMessage(true);
        //     setErrorMessage(
        //       "Please check if the phone number entered is correct."
        //     );
        //   });
      });
  };

  const onErrorCloseClick = () => {
    setShowErrorMessage(false);
  };

  return (
    <>
      <div className="signupPhone__container">
        <div className="signupPhone__header">
          <div>
            <Typography.Text className="signupPhone__textDonthaveAccount">
              Already have an account?
            </Typography.Text>
            <Link to={APP_ROUTES.SIGNIN.path}>
              <Button type="primary" className="signupPhone__button">
                Sign In
              </Button>
            </Link>
          </div>
        </div>
        <div className="signupPhone">
          <Space
            direction="vertical"
            size={0}
            className="signupPhone__signinFields"
          >
            <div className="signupPhone__headerLogo">
              <AppLogo className="signupPhone__logo" />
            </div>
            <Typography.Text className="signupPhone__headerTitle">
              Secure Your Workplace
            </Typography.Text>
            {showErrorMessage && (
              <div className="signinPage__errorMessageContainer">
                <Image
                  className="signinPage__errorContainerCrossImage"
                  src={Close}
                  onClick={onErrorCloseClick}
                  preview={false}
                ></Image>
                <Typography.Text
                  className="signinPage__textErrorMessage"
                  type="danger"
                >
                  {errorMessage}
                </Typography.Text>
              </div>
            )}
            <Form layout="vertical" onFinish={sendVerificationCode}>
              <Form.Item
                label="Phone Number"
                name="phonenumber"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number.",
                  },
                ]}
                hasFeedback
              >
                <PhoneInput
                  className="signupPhone__inputField"
                  country={"us"}
                  prefix="+ "
                />
              </Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                type="primary"
                className="signupPhone__continueButton"
              >
                Continue
              </Button>
            </Form>
          </Space>
        </div>

        <UserOnBoardingFooter />
      </div>
    </>
  );
}
export default SignupPhoneNumber;
