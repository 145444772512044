import API from "..";

export const getPresignedUrl = (payload) => {
  return API.post("/file-onboarding/getPresignedUrl", payload);
};
export const uploadFile = (fileData, config) => {
  return API.uploadFile(fileData, config);
};
export const createCsvMapping = (payload) => {
  return API.post("/file-onboarding/createCsvMapping", payload);
};
export const getFiles = (payload) => {
  return API.get(
    "/file-onboarding/getFilesByUserIdAndProfileWorkspaceId",
    payload
  );
};
export const deleteFile = (payload) => {
  return API.delete("/file-onboarding/deleteUploadById", payload);
};
export const createSource = (payload) => {
  return API.post("/file-onboarding/createSource", payload);
};
export const getAccountSources = (payload) => {
  return API.get("/file-onboarding/getAccountSources", payload);
};
export const updateFileMetadata = (payload) => {
  return API.post("/file-onboarding/updateFileMetadata", payload);
};
export const getAllSourceNames = (payload) => {
  return API.get("/file-onboarding/getAllSourceNames", payload);
};
export const saveDataDictionary = (payload) => {
  return API.post("/file-onboarding/saveUserDictionary", payload);
};
export const getDataDictionary = (payload) => {
  return API.get("/file-onboarding/getMappingFromDictionary", payload);
};
export const getMappingForAdditonalFields = (payload) => {
  return API.get("/file-onboarding/getMappingForAdditonalFields", payload);
};
export const getEventActions = (payload) => {
  return API.get("/file-onboarding/getEventActions", payload);
};
export const getUserCampaigns = (payload) => {
  return API.get("/file-onboarding/getUserCampaigns", payload);
};
export const getEventRawMetaData = (payload) => {
  return API.get("/file-onboarding/getEventRawMetaData", payload);
};
export const getEventMetaData = (payload) => {
  return API.get("/file-onboarding/getEventsMetadata", payload);
};
export const getMetaDataGoogleSheet = (payload) => {
  return API.get("/file-onboarding/metadataGoogleSheet", payload);
};
export const getUploadedCSVOverview = (payload) => {
  return API.get("/connector/csv-overview", payload);
};
