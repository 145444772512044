import { PlusOutlined } from "@ant-design/icons";
import { Button, Image, List, message, Progress, Tag } from "antd";
import { getAccountSources, getDataDictionary } from "api/FileOnboard";
import {
  CompaniesAddSource,
  PeopleAddSource,
  PlacesAddSource,
  EventsAddSource,
} from "images";
import { useEffect, useState } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentProcessingFile,
  setCurrentSourceInfo,
} from "../../../../../store/modules/fileOnboard/action";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { matchingColumnInColumnsOrDataDictionary } from "utils";
import CreateSourceSteps from "./CreateNewSource/CreateSourceSteps";
import { ENTITY_TYPES } from "constants";

/*
- File onboarding flow component used to render add new source or existing sources list
- onClickAddMetadata will be invoked when user choses to add metadata step
- onClickCreateSource will be invoked when user choses to create new source
- onCancelCreateSource will be invoked when user cancel create source steps
*/
function AddSource({
  onClickAddMetadata,
  onClickCreateSource,
  onCancelCreateSource,
}) {
  const [loading, setLoading] = useState(false);
  const [showCreateNewSource, setShowCreateNewSource] = useState(false);
  const [matchedSourcesList, setMatchedSourcesList] = useState([]);
  const currentProcessingFile = useSelector(
    (state) => state.fileOnboardReducer
  ).currentProcessingFile;
  const dispatch = useDispatch();
  const [sourcesIcons] = useState({
    1: CompaniesAddSource,
    2: PeopleAddSource,
    3: PlacesAddSource,
    4: EventsAddSource,
  });
  const { accountId, userId } = useSelector(
    (state) => state.authReducer
  ).userSession;

  useEffect(() => {
    const payloadForAccountSources = { account_id: accountId };
    const payloadColumns = currentProcessingFile.schema.columns.map(
      (payloadColumn) => {
        return payloadColumn.replace(/'/g, "''");
      }
    );
    const payloadForDataDictionary = {
      columns: `'${payloadColumns.join("','")}'`,
    };
    setLoading(true);
    Promise.all([
      getAccountSources(payloadForAccountSources),
      getDataDictionary(payloadForDataDictionary),
    ])
      .then((res) => {
        const sources = res[0].data.data.response;
        const dataDictionary = res[1].data.data;
        dispatch(
          setCurrentProcessingFile({
            ...currentProcessingFile,
            dataDictionary,
          })
        );
        updatedMatchedSources(sources, dataDictionary);
      })
      .catch((err) => {
        dispatch(
          setCurrentProcessingFile({
            ...currentProcessingFile,
            dataDictionary: [],
          })
        );
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const updatedMatchedSources = (sources, dataDictionary) => {
    const fileColumns = currentProcessingFile.schema.columns;
    const matchedSources = sources.map((source) => {
      const sourceColumns = source.schema.columns;
      let matchedColumns = 0;
      sourceColumns.forEach((column) => {
        const isColumnMatched = !isEmpty(
          matchingColumnInColumnsOrDataDictionary(
            column,
            fileColumns,
            dataDictionary
          )
        );
        matchedColumns += isColumnMatched ? 1 : 0;
      });
      source.percentMatch = parseInt(
        (100 * matchedColumns) / sourceColumns.length
      );
      return source;
    });
    const sortedMatchedSources = matchedSources.sort(function (a, b) {
      return b.percentMatch - a.percentMatch;
    });

    setMatchedSourcesList(sortedMatchedSources);
  };

  return (
    <div className="addSourceMainContainer">
      {!showCreateNewSource && (
        <>
          <div className="sourceCreateOrAdd">
            <Button
              className="sourceCreateOrAdd__button"
              type="primary"
              icon={<PlusOutlined />}
              size={"large"}
              onClick={() => {
                dispatch(setCurrentSourceInfo({}));
                setShowCreateNewSource(true);
                onClickCreateSource();
              }}
            >
              Create New Source
            </Button>
            {matchedSourcesList.length > 0 && (
              <p className="sourceCreateOrAdd__para">
                or select one from the list below
              </p>
            )}
          </div>
          <div className="addSource">
            {!isEmpty(matchedSourcesList) && (
              <div className="addSource__header">
                <span className="addSource__header__source">Source</span>
                <span className="addSource__header__status">Status</span>
              </div>
            )}
            <List
              loading={loading}
              dataSource={matchedSourcesList}
              itemLayout="vertical"
              rowKey={"id"}
              className="addSource__list"
              locale={{
                emptyText:
                  "No match found! Create a new source to compare uploads",
              }}
              renderItem={(item, index) => {
                const isFullMatch = item.percentMatch === 100;
                return (
                  <div
                    key={index}
                    className={classNames(
                      "addSource__list__row",
                      {
                        "addSource__list__row--roundedTop": [0].includes(index),
                      },
                      {
                        "addSource__list__row--roundedBottom": [
                          matchedSourcesList.length - 1,
                        ].includes(index),
                      }
                    )}
                  >
                    <Image
                      preview={false}
                      className="addSource__list__row__image"
                      src={sourcesIcons[item.entity_type_id]}
                    />
                    <div className="addSource__list__row__leftContent">
                      <p className="addSource__list__row__leftContent__sourceName">
                        {item.name}
                      </p>
                      <p className="addSource__list__row__leftContent__instructions">
                        To continue with this source, click{" "}
                        <span
                          onClick={() => {
                            dispatch(
                              setCurrentProcessingFile({
                                ...currentProcessingFile,
                                matchedSource: item,
                              })
                            );
                            onClickAddMetadata();
                          }}
                          className="addSource__list__row__leftContent__instructions__link"
                        >
                          here
                        </span>{" "}
                        to add metadata.
                      </p>
                      {String(item.entity_type_id) ===
                        String(ENTITY_TYPES.EVENTS) && (
                        <div className="addSource__list__row__leftContent__tags">
                          <span className="addSource__list__row__leftContent__tags--title">
                            Campaign ID:
                          </span>
                          <span className="addSource__list__row__leftContent__tags--value">
                            {item.campaign_id}
                          </span>
                          <span className="addSource__list__row__leftContent__tags--title">
                            Event Type:
                          </span>
                          <span className="addSource__list__row__leftContent__tags--value">
                            {item.event_action_id}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="addSource__list__row__rightContent">
                      <Progress
                        type="circle"
                        percent={item.percentMatch}
                        width={36}
                        strokeColor={isFullMatch ? null : "#0F0F60"}
                        trailColor={isFullMatch ? null : "#a7b3d9"}
                      />
                      <span
                        className={
                          isFullMatch
                            ? "addSource__list__row__rightContent__matched"
                            : "addSource__list__row__rightContent__match"
                        }
                      >
                        {isFullMatch ? "Matched" : "Match"}
                      </span>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </>
      )}
      {showCreateNewSource && (
        <CreateSourceSteps
          onCancel={() => {
            setShowCreateNewSource(false);
            onCancelCreateSource();
          }}
          onCreate={() => {
            onClickAddMetadata();
          }}
        />
      )}
    </div>
  );
}

export default AddSource;
