import ProcessingSteps from "./ProcessingSteps";
import ProcessingFields from "components/Datasets/ProcessingFields";
import "./index.scss";
import UploadTable from "components/Datasets/UploadTable";
import CatalogList from "components/Datasets/CatalogList";
import ProfileExplorer from "components/Datasets/ProfleExplorer";

/*
- File onboarding flow component used to render Uploads steps
- It's a wrapper over the child component ProcessingSteps
-
- Error handling and redux dispatch is hanlded within the scope of child components
*/
function Uploads() {
  return (
    <div className="uploads">
      {/* <ProcessingSteps /> */}
      <UploadTable />
      {/* <ProfileExplorer /> */}
    </div>
  );
}
export default Uploads;
