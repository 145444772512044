import axios from "axios";
import {
  requestInterceptor,
  responseInterceptor,
  errorInterceptor,
} from "./Interceptors";
import { API_BASE_URL } from "../environments";

const apiInstance = axios.create({
  baseURL: API_BASE_URL,
});
apiInstance.interceptors.request.use(requestInterceptor);
apiInstance.interceptors.response.use(responseInterceptor, errorInterceptor);
const fileRequest = axios.create({
  headers: { "Content-Type": "text/csv" },
});
const API = {
  get: function (url, params = {}) {
    return apiInstance.get(url, { params });
  },
  post: function (url, data = null, params = {}) {
    return apiInstance.post(url, data, { params });
  },
  put: function (url, data = null) {
    return apiInstance.put(url, data);
  },
  delete: function (url, params = {}) {
    return apiInstance.delete(url, { params });
  },
  patch: function (url, data = null) {
    return apiInstance.patch(url, data);
  },
  uploadFile: function (data = null, config) {
    return fileRequest.put(data.url, data.file, config);
  },
};
export default API;
