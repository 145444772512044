import { Button, Form, message } from "antd";
import {
  generateReport,
  getEventsFilterValues,
  getSources,
  searchEvents,
} from "api/search";
import DataTable from "components/DataTable";
import { eventsColumns, getIdColumn } from "components/DataTable/Columns";
import {
  DEFAULT_DATE_FORMAT,
  ENTITY_TYPES,
  SHOULD_SEARCH_ON_PAGE_LOAD,
} from "constants";
import exportFromJSON from "export-from-json";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Searchbar from "../Commons/Searchbar";
import SideFilters from "../Commons/SideFilters";
import FilterDropdown, {
  FILTER_DROPDOWN_SUFFIX,
} from "../Commons/SideFilters/Fields/FilterDropdown";
import FilterFieldsContainer from "../Commons/SideFilters/Fields/FilterFieldsContainer";
import "./index.scss";

function EventsSearch() {
  const [isBeginingSearch, setIsBeginingSearch] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? false : true
  );
  const [searchedText, setSearchedText] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? "" : null
  );
  const [searchedFilters, setSearchedFilters] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [resizingResults, setResizingResults] = useState(false);
  const { accountId, userId } = useSelector(
    (state) => state.authReducer
  ).userSession;
  const shouldFetchResults = useRef(SHOULD_SEARCH_ON_PAGE_LOAD ? true : false);
  const [form] = Form.useForm();
  let cols = eventsColumns;
  const [generatingReport, setGeneratingReport] = useState(false);

  useEffect(() => {
    getFilters();
    cols[0] = getIdColumn(eventsColumns[0], idClicked);
  }, []);

  const idClicked = (id) => {
    form.setFieldsValue({ revcloudId: [id] });
    setSearchedFilters({ revcloudId: [id] });
    setShowFilters(true);
  };

  useEffect(() => {
    if (shouldFetchResults.current) {
      getSearchResults();
    }
  }, [searchedText, searchedFilters]);

  useEffect(() => {
    setResizingResults(true);
    setTimeout(() => {
      setResizingResults(false);
    }, 100);
  }, [showFilters]);

  const getFilters = () => {
    const payload = {
      userId,
      accountId,
      entityTypeId: ENTITY_TYPES.EVENTS,
    };

    Promise.all([getSources(payload), getEventsFilterValues(payload)])
      .then((res) => {
        const sources = res[0].data.data;
        const filters = res[1].data.data;
        setFilterOptions({
          sources,
          ...filters,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const getSearchResults = () => {
    const filters = {};
    Object.keys(searchedFilters).forEach((filter) => {
      let filterValue = searchedFilters[filter];
      if (moment.isMoment(filterValue)) {
        filterValue = [moment(filterValue).format(DEFAULT_DATE_FORMAT)];
      } else if (["string", "number"].includes(typeof filterValue)) {
        filterValue = [filterValue];
      } else {
        filterValue = filterValue || [];
      }
      filters[filter] = filterValue.join(",");
    });

    const payload = {
      body: {
        ...filters,
      },
      params: {
        query: searchedText,
        userId,
        accountId,
        entityTypeId: ENTITY_TYPES.EVENTS,
      },
    };
    setLoading(true);
    searchEvents(payload)
      .then((res) => {
        setSearchResults(
          res?.data?.data?.map((el) => {
            el.event_timestamp = moment(el.event_timestamp)
              .utc()
              .format(DEFAULT_DATE_FORMAT);
            return el;
          })
        );
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onApplyFilters = () => {
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    setSearchedFilters(form.getFieldsValue());
  };
  const onResetFields = (fields) => {
    form.resetFields(fields);
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    const fieldValues = form.getFieldsValue();
    fields.forEach((field) => {
      fieldValues[field] = "";
    });
    setSearchedFilters(fieldValues);
  };

  const onGenerateReport = () => {
    setGeneratingReport(true);
    generateReport()
      .then((res) => {
        const data = res.data.data;
        const fileName = `${moment()
          .utc()
          .format(DEFAULT_DATE_FORMAT)}_Salesforce_Events_Report`; //TODO
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setGeneratingReport(false);
      });
  };

  const filterFields = [
    <FilterFieldsContainer
      label="Source"
      onClear={() => {
        onResetFields(["source"]);
      }}
    >
      <FilterDropdown
        name="source"
        placeholder="Enter source name here…"
        options={filterOptions.sources}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
    <FilterFieldsContainer
      label="Event Type"
      onClear={() => {
        onResetFields(["eventType"]);
      }}
    >
      <FilterDropdown
        name="eventType"
        selectMode="tags"
        placeholder="Enter event type"
        options={filterOptions.eventType}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
    <FilterFieldsContainer
      label="People Id"
      onClear={() => {
        onResetFields(["revcloudId"]);
      }}
    >
      <FilterDropdown
        name="revcloudId"
        selectMode="tags"
        placeholder="Enter People ID "
        options={filterOptions.revcloudId}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.ID}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="General information"
      onClear={() => {
        onResetFields(["email"]);
      }}
    >
      <FilterDropdown
        name="email"
        selectMode="tags"
        placeholder="Email"
        options={filterOptions.email}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.EMAIL}
        onChange={onApplyFilters}
      />
      {/* <FilterDropdown
        name="campaignName"
        selectMode="tags"
        placeholder="Campaign Name "
        options={filterOptions.campaignName}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      /> */}
    </FilterFieldsContainer>,
  ];

  return (
    <div className="eventsSearch">
      {/* <div className="eventsSearch__generateReportContainer">
        <Button
          className="eventsSearch__generateReportContainer__button"
          onClick={onGenerateReport}
          loading={generatingReport}
        >
          Generate Report
        </Button>
      </div> */}
      <Searchbar
        placeholder="Search events"
        filtersVisible={showFilters}
        onFiltersVisibilityChange={setShowFilters}
        onSubmitSearchText={(searchedText) => {
          shouldFetchResults.current = true;
          setIsBeginingSearch(false);
          setSearchedText(searchedText);
        }}
        filtersApplied={
          !isEmpty(
            Object.values(searchedFilters).filter((value) => {
              return !isEmpty(value);
            })
          )
        }
      />
      <div className="eventsSearch__filterAndResults">
        <>
          {showFilters && (
            <div className="eventsSearch__filterAndResults__filters">
              <Form form={form}>
                <SideFilters
                  filterFields={filterFields}
                  onClose={() => {
                    setShowFilters(false);
                  }}
                  onReset={() => {
                    form.resetFields();
                    shouldFetchResults.current = true;
                    setIsBeginingSearch(false);
                    setSearchedFilters({});
                  }}
                />
              </Form>
            </div>
          )}
          {!isBeginingSearch && !resizingResults && (
            <div className="eventsSearch__filterAndResults__results">
              <DataTable
                entityTypeId={ENTITY_TYPES.EVENTS}
                loading={loading}
                columns={eventsColumns}
                dataSource={searchResults}
                detailsKey="provider_name"
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
}
export default EventsSearch;
