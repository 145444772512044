import React from "react";
import { Table, Avatar, Input } from "antd";
import "./index.scss";
import { AvatarDon } from "images";
import { SearchOutlined } from "@ant-design/icons";

const { Search } = Input;

const data = [
  {
    key: "1",
    user: "Andrew Hill",
    universalId: "rev_user_36",
    firstActivity: "5 hrs ago",
    lastActivity: "10 days ago",
    avatar: "https://path.to/avatar1.png",
  },
  {
    key: "2",
    user: "Cassie Mathews",
    universalId: "rev_user_10",
    firstActivity: "6 days ago",
    lastActivity: "6 days ago",
    avatar: "https://path.to/avatar2.png",
  },
  {
    key: "3",
    user: "Kristy Jordan",
    universalId: "rev_user_99",
    firstActivity: "7 days ago",
    lastActivity: "8 days ago",
    avatar: "https://path.to/avatar3.png",
  },
  {
    key: "4",
    user: "Andrew Hill",
    universalId: "rev_user_87",
    firstActivity: "9 days ago",
    lastActivity: "a month ago",
    avatar: "https://path.to/avatar1.png",
  },
  {
    key: "5",
    user: "Anonymous User",
    universalId: "rev_user_13",
    firstActivity: "10 days ago",
    lastActivity: "a month ago",
    avatar: "https://path.to/avatar4.png",
  },
  {
    key: "6",
    user: "Kimball Belmont",
    universalId: "rev_user_61",
    firstActivity: "5 hrs ago",
    lastActivity: "10 days ago",
    avatar: "https://path.to/avatar5.png",
  },
];

const columns = [
  {
    title: "USER",
    dataIndex: "user",
    key: "user",
    render: (text, record) => (
      <div className="profile-explorer__user">
        <Avatar src={AvatarDon} className="profile-explorer__user__avatar" />
        <span>{text}</span>
      </div>
    ),
  },
  {
    title: "UNIVERSAL ID",
    dataIndex: "universalId",
    key: "universalId",
  },
  {
    title: "FIRST ACTIVITY",
    dataIndex: "firstActivity",
    key: "firstActivity",
  },
  {
    title: "LAST ACTIVITY",
    dataIndex: "lastActivity",
    key: "lastActivity",
  },
];

const ProfileExplorer = () => {
  return (
    <div className="profile-explorer">
      <div className="profile-explorer__header">
        <h2>Profile</h2>
        <Input
          className="profile-explorer__header__input"
          type="search"
          placeholder="Search Profiles"
          prefix={<SearchOutlined style={{ color: "grey" }} />}
        />
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={true}
        className="profile-explorer__content"
      />
    </div>
  );
};

export default ProfileExplorer;
